<template>
    <div class="wrap-star">
        <div class="main-header homepage">
            <BackgroundStars></BackgroundStars>
            <slot></slot>
            <v-container>
                <div class="header-content">
                    <v-row>
                        <v-col cols="12" lg="7">
                            <div class="hex-header-content">
                                <div class="content-text">
                                    <h1>One miner <br />to mine them all</h1>
                                    <p class="text-sm-left">Powerful Hexageo Crypto Miner with fast speed to mine up to 6 different coins at the same time.</p>
                                </div>
                                <div class="content-action mt-16">
                                    <Transition name="fade" mode="out-in">
                                        <div v-if="!whitelisted" key="1" class="hex-header-action">
                                            <v-form ref="form" @submit.prevent="getAccess" class="d-flex flex-wrap ">
                                                <v-text-field v-model="email" validate-on-blur :rules="emailRules" class="main-input" hide-details="auto" solo label="Enter your email"></v-text-field>
                                                <v-btn class="btn-mining" @click="getAccess">Join Waitlist</v-btn>
                                            </v-form>
                                            <p>Be among first of 1000 and secure 1.5 multiplier on earnings</p>
                                        </div>
                                        <div v-else key="2" class="hex-header-whitlisted d-flex align-center" >
                                            <v-icon class="mr-4">mdi-check</v-icon>
                                            <p>Successfully joined the waitlist. Check your inbox</p>
                                        </div>
                                    </Transition>
                                    <div class="whitelist">
                                        <div class="whitelist-avatars d-flex">
                                            <img src="@/assets/whitelist-user1.png" class="whitelist-avatar" alt="whitelist-user">
                                            <img src="@/assets/whitelist-user5.png" class="whitelist-avatar" alt="whitelist-user">
                                            <img src="@/assets/whitelist-user4.png" class="whitelist-avatar" alt="whitelist-user">
                                            <img src="@/assets/whitelist-user3.png" class="whitelist-avatar" alt="whitelist-user">
                                            <img src="@/assets/whitelist-user2.png" class="whitelist-avatar" alt="whitelist-user">
                                        </div>
                                        <p>Join a waitlist of 1530+</p>
                                    </div>
                                </div>  
                            </div>
                        </v-col>
                        <v-col cols="12" lg="5" class="hexa-header-miner">
                            <img width="870px" src="@/assets/R2-Trasparent.png">
                        </v-col>
                    </v-row>
                </div>

            </v-container>

        </div>
    </div>
</template>
<script>
export default{
    emits:['access'],
    data(){
        return{
            email: null
        }
    },
    methods:{
        getAccess(){
            if(this.$refs.form.validate()){
                this.$emit('access', this.email)
            }
        }
    },
    computed:{
        whitelisted(){
            return this.$store.getters['isWhitelisted']
        },
        emailRules(){
            return [ ...this.$store.getters['rules'].emailRules, ...this.$store.getters['rules'].requiredRules];
            
        }
    },
    

    
}
</script>
<style scoped>
.hex-header-whitlisted i{
    color: #5FF2FB !important;
    font-size: 32px;
}
.hex-header-whitlisted p{
    color: #D9D9D9;
    font-size: 16px;
}
.header-content{
    margin-top: 87px
}
.content-text h1{
    min-height: 50px;
    overflow: hidden;
}
@media (min-width: 1264px){
    .content-text{
        max-width: 92% !important;
    }
    .header-content{
        margin-top: 165px
    }

}
.content-text p{
    font-family: 'Gilroy-medium',sans-serif;
    font-size: 20px    
}
.hex-header-action .btn-mining{
    width: 180px !important;
    height: 56px !important;
    margin: 0px !important;
}

.hex-header-action .main-input{
    max-width: 320px;
}
.hex-header-action > p{
    /* color: #999AA9; */
    font-size: 14px;
    margin-top: 8px;
}
.hex-header-action form{
    row-gap: 20px;
    column-gap: 16px;
}
.whitelist{
    display: flex;
    /* gap: 16px; */
    align-items: center;
    margin-top: 64px;
}
.whitelist p{
    /* color: #999AA9; */
    position: relative;
    left: -32px;
}
.whitelist-avatars img{
    position: relative;
}
.whitelist-avatars img:nth-child(2){
    left: -12px;
}
.whitelist-avatars img:nth-child(3){
    left: -24px;
}
.whitelist-avatars img:nth-child(4){
    left: -36px;
}
.whitelist-avatars img:nth-child(5){
    left: -48px;
}
@media screen and (max-width: 471px) {
    .hex-header-action .main-input{
        max-width: 100%;
    }
    .hex-header-action .btn-mining{
        max-width: 100% !important;
        width: 100% !important;
    }
    .whitelist{
        flex-direction: column;
    }
    .whitelist .whitelist-avatars{
        position: relative;
        left: 22px;
    }
    .whitelist > p{
        left: 0px !important;
    }
}


</style>
<style scoped>
.main-header.homepage{
    background-image: radial-gradient(106.68% 106.68% at 50% 0%, rgba(13, 17, 22, 0) 46.2%, #300E5B 85.08%, #7371FF 100%), url('@/assets/planet-earth-from-space 1 .png') !important;
    min-height: 900px;
    background-position: 80%;
    margin-top: 0px !important;
}
@media (min-width: 1264px){
    .main-header{
        width: 98%;
        border: 1px solid #3F3E8C;
        border-radius: 24px;
        margin-top: 32px;
    }
}
.hex-header-action .main-input > .v-input__control{
    min-height: 56px !important;
}
.content-text h1{
    font-family: 'Gilroy', sans-serif;
    font-size: 42px;
    background: radial-gradient(51.88% 5897.7% at 50.06% 66.25%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    line-height: 1.1;
    text-align: center;
    margin: 0px auto;
    max-width: 652px;
}
.content-text p{
    font-size: 28px;
    color: #D9D9D9;
    margin-top: 16px;
    max-width: 600px !important;
    text-align: center;
    /* margin-left: auto;
    margin-right: auto; */
}

.btn-touch{
    text-transform: none;
    font-family: 'Inter',sans-serif;
    text-decoration: none;
    color: #40EFFA !important;
    text-align: center;
}
.earth-img{
    position: relative;
}
.hex-header-action{
    display: flex;
    flex-direction: column;
}
.hex-header-action button{
    max-width: 209px;
    margin: 0px auto;
}
.hexa-header-miner img{
    width: 100% !important;
}
@media (min-width: 600px){
    .content-text h1{
        font-size: 72px !important;
        margin: 0px !important;
        text-align: left !important;
    }
}
@media (min-width: 1264px){

    .header-content .content-text h2{
        font-size: 72px;
        max-width: 522px;
    }
    .content-text p{
        margin-left: 0px;
        margin-right: 0px;
        text-align: left;
        max-width: 458.47px !important;
    }
    .hex-header-action {
        display: block;
    }
    .hex-header-action button{
        max-width: auto !important;
        margin: auto !important;
    }
    .hexa-header-miner{
        position: relative !important;
    }
    .hexa-header-miner img{
        position: absolute;
        left: -170px;
        top: 10px;
        width: 870px !important;
    }
}   
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>