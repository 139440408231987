<template>
  <div class="signup-content">
    
    <h2 class="text-center">Get started</h2>
    <div class="text-center signup-suggest">Already have an account? <router-link class="sign-links" to="/signin">Sign in</router-link></div>
      
    <v-form @submit.prevent class="social-auth" v-if="!showCredentials">
        <!-- <button class="social-btn social-btn-google d-flex justify-center align-center">
          <div class="btn-icon d-flex mr-2"><img src="@/assets/google-soical.svg" alt="Google"></div>
          <div class="btn-text">
            <h5>Sign In with Google</h5>
          </div>
        </button> -->
        <!-- <button class="social-btn social-btn-facebook d-flex justify-center align-center">
          <div class="btn-icon d-flex mr-2"><img src="@/assets/face-social.svg" alt="Facebook"></div>
          <div class="btn-text">
            <h5>Sign In with Facebook</h5>
          </div>
        </button>
        <button class="social-btn social-btn-apple d-flex justify-center align-center">
          <div class="btn-icon d-flex mr-2">
            <img src="@/assets/apple-social.svg" alt="Apple">
          </div>
          <div class="btn-text">
            <h5>Sign In with Apple</h5>
          </div>
        </button> -->
      </v-form>
        <v-form ref="form">
          
        <transition name="slide-fade">
          <div class="info-form" v-if="!showCredentials">
            <label for="firstname" class="lbl-input mb-1">First Name</label>
            <v-text-field :error="errFirstName" class="form-input" ref="firstname" v-model="user.first_name" validate-on-blur :rules="[...rules.requiredRules, ...rules.minRules, ...rules.nameRules]" id="firstname" outlined></v-text-field>
            <label for="lastname" class="lbl-input mb-1">Last Name</label>
            <v-text-field :error="errLastName" class="form-input" @keyup.enter="continueForm" ref="lastname" v-model="user.last_name" validate-on-blur :rules="[...rules.requiredRules, ...rules.minRules, ...rules.nameRules]" id="lastname" outlined></v-text-field>
            <v-btn block class="main-btn mb-6" @click="continueForm">Continue</v-btn>
          </div>
        </transition>
        
        <transition name="slide-fade">
          <div v-if="showCredentials" class="credentials-form">
            <div class="back-info mb-4">
              <v-btn text class="pa-0" @click="showCredentials = !showCredentials">
                <v-icon color="grey" class="mr-2">mdi-arrow-left</v-icon>
                <span class="grey--text">Back</span>
              </v-btn>
            </div>
            <!-- <label for="username" class="lbl-input mb-1">Username</label> -->
            <!-- <v-text-field class="form-input" v-model="user.username" validate-on-blur :rules="[...rules.requiredRules, ...rules.minRules, ...rules.stringRule]" id="username" outlined></v-text-field>   -->
            <label for="email" class="lbl-input mb-1">Email</label>
            <v-text-field validate-on-blur v-model="user.email" :rules="[...rules.requiredRules, ...rules.emailRules]" class="form-input" id="email" outlined></v-text-field>
            <label class="lbl-input mb-1" for="password">Password</label>
            <v-text-field class="form-input" name="password" v-model="user.password" autocomplete="password"  :rules="[...rules.requiredRules, ...rules.passwordRules]" type="password" outlined id="password"></v-text-field>
            <label class="lbl-input mb-1" for="confirmation">Confirm Password</label>
            <v-text-field class="form-input" name="password" autocomplete="password"  @keyup.enter="signUp" :rules="[...rules.requiredRules, ...reTypeRule]" type="password" outlined id="confirmation"></v-text-field>
            <v-btn block class="main-btn mb-6" @keyup.enter="signUp" @click="signUp">Register</v-btn>
          </div>
        </transition>
        </v-form>
 
        
      </div>
</template>
<script>
export default{
  emits:['validForm'],
  data(){
    return{
      showCredentials: false,
      // showPassword: false,
      errFirstName: false,
      errLastName: false,
      user:{
        // username: null,
        first_name: null,
        last_name: null,
        email: null,
        password: null
      },
      reTypeRule: [(v) => (v && this.user.password && v == this.user.password || 'The password confirmation does not match'),]
    }
  },
  computed:{
      rules(){
        return this.$store.getters['rules'];
      }
  },
  methods:{
    signUp(){
        if(this.$refs.form.validate()){
          this.$emit('validForm', this.user);
        }
    },
    continueForm(){
      
      if(!this.$refs.firstname.validate())
        this.errFirstName = true;
      if(!this.$refs.lastname.validate())
        this.errLastName = true;
      

      if(this.$refs.firstname.valid && this.$refs.lastname.valid){
        this.showCredentials = true;
      }


    }
  },
}
</script>
<style scoped>
.main-btn{
  min-height:48px !important;
  height: 48px !important;
}
</style>
<style>
.signup-suggest{
    color: #485158;
    font-family: 'Inter',sans-serif;
    margin: 8px 0px 32px 0px;
}
.signup-content h2{
    font-family: 'Inter',sans-serif;
    font-size: 28px;
    color: #212445;
    font-weight: normal;
}
.signup-content .form-input fieldset{
    border: 2px solid #C7D5E1;
    border-radius: 12px;
}
.signup-content .form-input .v-text-field__slot input{
    color: #344054 !important;
}
.signup-content .lbl-input{
    color: #344054;
    font-family: 'Inter',sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: block;
}
.signup-content .social-btn{
  display: block;
  height: 48px !important;
  width: 100%;
  margin: 15px auto;
  /* padding: 10px 0px; */
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
}
.signup-content .social-btn .btn-text{
  font-family: 'Inter',sans-serif;
  font-size: 18px;
}
.signup-content .social-btn{
  background-color: #FFF;
  color: #344054;
  border-color: #D0D5DD !important;
}
.signup-content .sign-links{
    text-decoration: none;
    font-family: 'Inter',sans-serif;
    color: #0FD6E3;
    font-size: 15px !important;
}
.social-auth{
  margin-bottom: 24px;
}



.slide-fade-enter-active {
    transition: all .5s ease;
}
.slide-fade-leave-active {
    display: none;
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}



@media (min-width: 1264px){

    .signup-content h2{
        font-weight: 600;
        font-size: 32px !important;
    }
}
</style>