import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

import HomeView from '../views/LandingPage/HomeView.vue'
import LiteCoin from '@/views/LandingPage/LiteCoin.vue'
import Products from '@/views/LandingPage/Products.vue'
// import Blogs from '@/views/LandingPage/Blogs.vue'
// import DarkBlog from '@/views/LandingPage/Blogs/DarkBlog.vue'
import SignIn from '@/views/LandingPage/SignIn.vue'
import SignUp from '@/views/LandingPage/SignUp.vue'
import VerifyEmail from '@/views/LandingPage/VerifyEmail.vue'
import ForgotPassword from '@/views/LandingPage/ForgotPassword.vue'
import ChangePassword from '@/views/LandingPage/ChangePassword.vue'
import Overview from '@/views/Dashboard/Overview.vue'
import Wallet from '@/views/Dashboard/Wallet.vue'
import HelpAccount from '@/views/Dashboard/HelpCenter.vue'
// import HelpCenter from '@/views/LandingPage/HelpCenter/HelpCenter.vue'
// import HelpBlog from '@/views/LandingPage/HelpCenter/Blog/HelpBlog.vue'
import Shop from '@/views/Dashboard/Shop/Main.vue'
import ShopItem from '@/views/Dashboard/Shop/Item.vue'

import Orders from '@/views/Dashboard/Shop/Orders.vue'
import NotFound from '@/views/NotFound.vue'

import PrivacyPage from '@/views/LandingPage/PrivacyPage.vue'
import CookiesPage from '@/views/LandingPage/CookiesPage.vue'
// import ComparisonTable from '@/components/LandingPage/LiteCoin/ComparisonTable.vue'

Vue.use(VueRouter)


let isAuth = store.getters['isAuth'];



const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: HomeView
  },
  {
    path: '/litecoin',
    name: 'Miners',
    component: LiteCoin
  },
  {
    path:'/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/signin',
    name: 'Signin',
    component: SignIn,
    meta:{
      title: 'Sign In'
    }
  },
  {
    path: '/user/email/verify/:token',
    name: 'VerifyEmail',
    component: VerifyEmail,
    meta:{
      title: 'Email Verification'
    }
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: ForgotPassword,
    meta:{
      title: 'Forgot Password'
    }
  },
  {
    path: '/password/reset/:uid/:token',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      title: 'Change Password'
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignUp,
    meta:{
      title: 'Sign Up'
    }
  },
  {
    path: '/overview',
    name: 'Account',
    component: Overview,
    meta:{
      requiresAuth: true,
      title: 'Overview'
    
    }
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet,
    meta:{
      requiresAuth: true,
      title: 'Wallet'
    }
  },
  {
    name: 'Shop',
    path: '/shop',
    component: Shop,
    meta: {requiresAuth: true},
  },
  {
    name: 'ShopItem',
    path: '/shop/:id',
    component: ShopItem,
    meta:{requiresAuth : true}
  },
  {
    name: 'Orders',
    path: '/shop/orders/:id',
    component: Orders,
    meta: {requiresAuth: true}
  },  
  {
    path: '/help',
    name: 'Help',
    component: HelpAccount,
    meta: {
      requiresAuth: true,
      title: 'Help Center'
    
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPage
  },
  {
    path:'/cookies',
    name: 'Cookies',
    component: CookiesPage
  },
  // {
  //   path:'/blog',
  //   name: 'Blogs',
  //   component:Blogs,
  //   meta:{
  //     title: 'Blog Keeping you connected to everything',
  //     backgroundColor: '#0E0F1D'
  //   }
  // },
  // {
  //   path: '/blog/bulding-javascript-packages',
  //   name: 'DarkBlog',
  //   component: DarkBlog,
  //   meta:{
  //     title: 'Bulding javascript packages',
  //     backgroundColor: '#0E0F1D'
  //   }
  // },
  // {
  //   path: '/help-center',
  //   name: 'HelpCenter',
  //   component: HelpCenter,
  //   meta:{
  //     title: 'Help Center'
  //   }
  // },
  // Change to articles 
  
  // {
  //     path: '/help-center/blogs/',
  //     name: 'HelpBlogs',
  //     component: HelpBlog,
  //     meta:{
  //       backgroundColor: '#FFF'
  //     }
  // },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to,from,next) => {   


  if(document.documentElement.style.overflowY)
    document.documentElement.style.overflowY = 'scroll'

  store.dispatch('resetPage');

  let title = to.meta && to.meta.title ? to.meta.title : 'Revolutionize Cryptocurrency Mining' 
  

  window.document.title = 'Hexageo - ' + title

  let user = store.getters['user'];
  
  isAuth = store.getters['isAuth']


  // console.log(user);
  if(!user && !!isAuth)
    store.dispatch('setUser')

  if(['Signin','Signup','Forgot'].includes(to.name) && !!isAuth){
   
    return next('/overview')
   
  }

  if(to.meta.requiresAuth && !isAuth){
    return next('/signin')
  }
  return next();

});

export default router
