<template>
    <div class="user-welcome text-center">
        <p>Welcome back, {{ name }} 👏🏻</p>
    </div>
</template>
<script>
export default{
    props:{
        name: {
            type: String,
            required: true
        }
    }
}
</script>
<style>
.user-welcome p{
    font-family: 'Manrope', sans-serif;
    font-size: 24px;
}
.user-welcome{
    margin-top: 48px;
    margin-bottom: 17px;
}
@media (min-width: 1264px){
    .user-welcome{
        margin-top: 66px !important;
        margin-bottom: 34px !important;
    }
}
</style>