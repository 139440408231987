<template>
    <div class="signup">
        <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
          {{ alert.message }}
          <template v-slot:action="{ attrs }">
              <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
          </template>
        </v-snackbar>

        <v-dialog           
            :opacity="0.6"
            v-model="loading"
            width="100%"
            max-width="768px"
            persistent
            content-class="loading-dialog">
            <v-progress-circular
                :size="50"
                color="white"
                indeterminate
            ></v-progress-circular>
        </v-dialog>
        <BackgroundStars></BackgroundStars>
        <v-container>
            <div class="sign-logo d-flex justify-center">
                <hexa-logo></hexa-logo>
            </div>
            <div class="signup-form">
                <SignForm @validForm="signUp"></SignForm>
            </div>

        </v-container>
    </div>
</template>
<script>
import BackgroundStars from '@/components/Basics/BackgroundStars.vue'
import SignForm from '@/components/LandingPage/Signup/SignUpForm.vue'
import axios from 'axios'
export default{
    components:{BackgroundStars,SignForm},
    data(){
        return{
            loading: false,
            alert:{
                show: null,
                message: null,
                type: null
            }
        }
    },
    methods:{
        async signUp(user){
            this.loading = true;

            axios;
            
            // this.$store.dispatch('signIn', user);
            let server = this.$store.getters['server'];

            let success = false;
            
        
            await this.$http.post(server + '/user/signup', user).then((response) => {
                
                this.alert.message = 'Your account has been created successfully!';
                this.alert.type = 'success';
                
                success = response.data;

            }).catch(err => {
            
                
                this.alert.message = err.response.data.message;
                this.alert.type = 'error';

            }).then(() => {
                
                this.loading = false;
                this.alert.show = true;
            })


            await new Promise(resolve => setTimeout(resolve, 2000))


            if(success && success.access){
                this.trackSignUp();
                this.$store.dispatch('attempt', success)
            }

        },
        trackSignUp() {
            if (process.env.NODE_ENV === 'production'){
                this.$gtag.event('sign_up', {'send_to': 'AW-705764791/hy4CCJjH4dsYELe7xNAC'})
            } else {
                console.log("skipping google tag");
            }
        },
    }
}
</script>
<style>
    .sign-logo{
        margin-top: 30px;
        margin-bottom: 70px;
    }
    .signup{
        min-height: 100vh;
        overflow: hidden;
        background:  radial-gradient(93.85% 93.85% at 50% 11.72%, #0D1116 46.2%, #300E5B 85.08%, #7371FF 100%), #0D1116;;
    }
    .signup-form{
        background-color: #FFF;
        box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
        border-radius: 8px;
        width: 100%;
        max-width: 456px;
        padding: 32px 24px;
        margin: 0px auto;
        position: relative;
    }
    @media (min-width: 1264px){
        .signup-form{
            padding: 48px;
        }
    }
</style>