<template>
    <v-container>
        <div class="comparison-components">
            <div class="miner-comparison-detail d-flex flex-column flex-lg-row justify-center align-center">
                <div class="miners-comparison-img">
                    <img max-width="420px" width="100%" src="@/assets/R2-Trasparent.png" alt="Miner">
                </div>
                <div class="miner-comparison-details flex-wrap flex-md-nowrap">
                        <v-btn-toggle v-model="toggle" class="comparison-toggle">
                            <v-btn>
                                <span>Big Miner</span>
                            </v-btn>

                            <v-btn>
                                <span>Small Miner</span>
                            </v-btn>
                        </v-btn-toggle>
                        <v-list class="miner-list-com" v-if="!toggle">
                            <v-list-item >
                                <v-list-item-title>Name</v-list-item-title>
                                <v-list-item-subtitle>Big Miner</v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>SIM CAPACITY</v-list-item-title>
                                <v-list-item-subtitle>6</v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>Release</v-list-item-title>
                                <v-list-item-subtitle>12-10-2023</v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>Price</v-list-item-title>
                                <v-list-item-subtitle>$ 3000</v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                        <v-list class="miner-list-com" v-else>
                            <v-list-item >
                                <v-list-item-title>Name</v-list-item-title>
                                <v-list-item-subtitle>Small Miner</v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>SIM CAPACITY</v-list-item-title>
                                <v-list-item-subtitle>1</v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>Release</v-list-item-title>
                                <v-list-item-subtitle>12-14-2023</v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>Price</v-list-item-title>
                                <v-list-item-subtitle>$ 600</v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </div>
            </div>
        </div>
    </v-container>
</template>

<script>
export default{
    data(){
        return{
            toggle: 0
        }
    }
}
</script>
<style scoped>
.v-btn-toggle{
    border-radius: 6px;
}
.miner-comparison-details{
    min-width: 62% !important;
}
.comparison-miners{
    margin-top: 64px;
}
.comparison-miners .v-item-group .v-btn{
    min-height: 48px !important;
    
}
.miner-comparison-details{
    width: 100%;
    /* background: linear-gradient(45deg, #1D2939 0%, #475467 100%), linear-gradient(0deg, #212445, #212445), linear-gradient(270deg, rgba(115, 113, 255, 0.3) 0%, rgba(33, 36, 69, 0.3) 52.26%); */
    background: linear-gradient(270deg, rgba(115, 113, 255, 0.3) 0%, rgba(33, 36, 69, 0.3) 52.26%), #212445;
    border-radius: 20px;
    padding-bottom: 20px;
}
.miner-list-com{
    background-color: transparent !important;
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    row-gap: 20px;
}
.miner-list-com > div{
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-bottom: 1px;
    flex-basis: 25%;
}
.miner-list-com > div .v-list-item__title{
    color: rgba(255, 255, 255, 1);
    opacity: 50%;
    font-size: 14px;
    margin-bottom: 6px;
}
.miner-list-com > div .v-list-item__subtitle{
    color: #FFF !important;
    font-size: 18px;
    display: block;
    flex: 150%;
}
.miner-list-com > div .v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle{
    line-height: 1.5;
}
.miner-list-com .v-list-item{
    min-height: auto !important;
}
.miner-list-com > div::before{
    content: none;
}

.miner-comparison-detail{
    gap: 35px;
    /* margin-top: 164px; */
    margin-bottom: 80px;
}
.comparison-toggle{
    width: 100%;
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    height: 46px;
    gap: 30px;
    /* background-color: #F1F1EF !important; */
}
.comparison-toggle .v-btn{
    /* color: #333; */
    /* background-color: #FFF; */
    width: 171.5px;
    height: 46px;
    max-width: 120px !important;
}
.comparison-toggle .v-btn::before{
    background-color: transparent !important;
}

.comparison-toggle .v-btn{
    border-radius: 6px;
    background-color: transparent;
    border: 0px;
    color: #91F6FC;
    text-transform: none;
    letter-spacing: 0px !important;
    font-size: 18px;
    font-weight: 600;
}
.comparison-toggle .v-btn.v-item--active{
    background-color: #10EBF9 !important;
    color: #0E0F1D;
}
@media screen and (min-width: 1264px){
    .miner-list-com{
        flex-wrap: nowrap;
    }
    .miner-list-com > div{
        flex-basis: 50% !important;
    }
}
</style>