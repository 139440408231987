<template>
  <v-container class="ticker-section-container">
    <div class="ticker-section">
      <h2>100 + coins to mine</h2>
      <div class="coin-ticker">
        <vue-marquee-slider id="marquee-slider" :space="64" :speed="50000">
          <div v-for="(item, index) in coins" :key="index" class="ticker-coin-details d-flex align-center">
              <div class="ticker-coin-img d-flex align-center mr-3" style="width:48px">
                <img width="38px" :src="getImg(item.img)" :alt="item.img">
              </div>
              <div class="ticker-coin-name">
                {{ item.name }}
              </div>
            </div>
        </vue-marquee-slider>
      </div>
    </div>
  </v-container>
</template>
<script>
import {VueMarqueeSlider} from 'vue-marquee-slider';
export default{
  components: {
    VueMarqueeSlider
  },
  data(){
    return{
      coins:[
        {id: 1, name: 'BTC', img: 'bitcoin.png'},
        {id: 2, name: 'ETH', img: 'ethereum.png'},
        {id: 3, name: 'LTC', img: 'litecoin.png'},
        {id: 4, name: 'DOGE', img: 'dogecoin.png'},
        {id: 5, name: 'LINK', img: 'chainlink.png'},
        {id: 6, name: 'USDT', img: 'tether.png'},
        {id: 7, name: 'BUSD', img: 'binance-usd.png'},
        {id: 8, name: 'BNB', img: 'bnb.png'},
        {id: 9, name: 'APE', img: 'apecoin-ape.png'},
        {id: 10, name: 'FIL', img: 'filecoin.png'},
      ]
    }
  },
  methods:{
    getImg(img){
      return require(`@/assets/coins/${img}`)
    }
  }
}
</script>

<style scoped>
.ticker-section-container{
    background-image: url('@/assets/Hexagon\ pattern.png');
}
.ticker-section{
  min-height: 300px;
}
.ticker-section > h2{
  font-family: 'Gilroy', sans-serif;
  font-size: 36px;
  background: radial-gradient(51.88% 5897.7% at 50.06% 66.25%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 80px;
}
.coin-ticker{
    display: flex;
    width: 100%;
    margin: 0px;
    padding: 10px;
    opacity: 1;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%);
    overflow: hidden;
    /* container-type: size; */
    
}
ul.coin-ticker-list{
    display: flex;
    margin: 0;
    padding: 0;
    gap: 64px;
    transform: translateX(0px);
    position: relative;
    gap: 64px;
    list-style: none !important;
  }
  ul.coin-ticker-list:last-child{
    animation: swap 30s linear infinite;
    position: relative;
  left: 0%;
  display: none;
  }
  ul.coin-ticker-list:first-child{
    animation: marquee 15s linear infinite;
  }
.coin-ticker .ticker-coin-name{
  font-size: 20px;
  font-family: 'Gilroy-medium', sans-serif !important;
  /* font-weight: bold; */
}
@media(min-width: 600px){
  .ticker-section > h2{
    font-size: 54px;
  }
}


</style>