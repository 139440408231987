<template>
<div class="user-wallet">
    <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" bottom right timeout="2000" transition="scroll-x-transition">
          {{ alert.message }}
          <template v-slot:action="{ attrs }">
              <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
          </template>
    </v-snackbar>
    <div class="text-center">
        <v-dialog :opacity="0.6" v-model="addDialog" width="100%" max-width="768px" persistent content-class="payment-dialog">
            <div class="main-form add-wallet-form">
                <v-btn v-if="!loadRequest" color="transparent" class="d-flex ml-auto close-btn" rounded small @click="addDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                <h5>Add New Wallet</h5>
                <PaymentMethods @load="loadRequest = true" @finish="finishRequest"></PaymentMethods>            
            </div>
        </v-dialog>
        <v-dialog :opacity="0.6" v-model="deleteDialog" width="100%" max-width="768px" persistent content-class="payment-dialog">
            <div class="main-form add-wallet-form">
                <v-btn v-if="!loadRequest" color="transparent" class="d-flex ml-auto close-btn" rounded small @click="deleteDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                <h5>Are you sure you want to delete your wallet</h5>
                <div class="add-copy" v-if="deletedWallet">
                    <span class="d-block mb-3 wallet-address">{{ deletedWallet.public_key }}</span>
                    <v-btn class="main-btn" @click="deleteWallet">Yes</v-btn>
                </div>
            </div>
        </v-dialog>
    </div>
    <div class="content-container account-wallet">
        <div class="account-wallet-header d-flex align-center justify-space-between mb-6">
            <h2>Wallet</h2>
            <v-btn class="outline-btn py-2" @click="addDialog = true"><v-icon class="mr-1" >mdi-plus</v-icon>Add new wallet</v-btn>
        </div>
        <div class="account-wallets-content" v-if="wallets && wallets.length">
            <div class="wallet-content d-flex align-center justify-space-between" v-for="(item,index) in wallets" :key="index">
                <div class="wallet-content-name d-flex align-center">
                    <!-- Replace Tether.png with item.img for Dynamic -->
                    <img width="42px" :src="getImg('tether.png')" class="mr-3" alt="">
                    <div class="wallet-name">
                        <span>{{ item.currency }}</span>
                        <div v-if="item.public_key" class="add-copy d-none d-lg-flex align-center mt-1">
                            <span class="d-block mr-2">{{ item.public_key }}</span>
                            <v-icon small  @click="copyAddress(item.public_key)" class="d-block copy-icon">mdi-content-copy</v-icon>
                        </div>
                    </div>
                </div>
            <div class="withdraw-btn" :class="[$route.name == 'Wallet' ? 'd-block' : ['d-block','d-lg-none']]">
                <v-btn color="#40EFFA" v-if="item.id" @click="checkDelete(item)" small text>Delete</v-btn>
            </div>
            </div>
        </div>
        <div v-else>
            No wallets found
        </div>
    </div>
</div>
</template>
<script>
import PaymentMethods from './PaymentMethod.vue'
export default{
    components:{PaymentMethods},
    data(){
        return{
            wallets:[],
            loadRequest: false,
            addDialog: false,
            
            alert:{
                show: null,
                type: null,
                message: null  
            },
            deletedWallet: null,
            deleteDialog: false,
        }
    },
    methods:{
        getImg(img){
            return require(`@/assets/coins/${img}`)
        },
        async finishRequest(response){
            
            if(response.status == 201)
                await this.getWallets(true);
            
            this.alert.type = response.status == 201 ? 'success' : 'error';
            this.alert.message = response.data.message ?? response.statusText;
            this.alert.show = true;
            this.addDialog = false;
            this.loadRequest = false;
        },
        copyAddress(address){
            navigator.clipboard.writeText(address);
        },
        async getWallets(forceLoad = false){
            
            if(forceLoad || this.$store.getters['wallets'] == null)
                await this.$store.dispatch('setWallets');
    
            this.wallets = this.$store.getters['wallets'];
        },
        checkDelete(wallet){
            
            this.deletedWallet = wallet;
            this.deleteDialog = true;
            
        },
        async deleteWallet(){
            
            if(!this.deletedWallet.id){
                this.deletedWallet = null
                this.deleteDialog = false;
            
                return ;
            }


            
            this.loadRequest = true;

            let server = this.$store.getters['server'];
            
            await this.$http.delete(server + '/user/wallet/'+this.deletedWallet.id).then(response => {
                    
                this.alert.type = 'success';
                this.alert.message = response.data.message ?? 'Wallet deleted successfully';

                this.$store.dispatch('deleteWallet',this.deletedWallet.id);

                this.wallets = this.$store.getters['wallets'];

            }).catch((err) => {
                this.alert.type = 'error';
                this.alert.message = err.response.data.message ?? 'Error occurred during while deleting wallet'
            })
            
            this.alert.show = true;
            
            this.loadRequest = false;
            this.deletedWallet = null;
            this.deleteDialog = false;    
        
        }
    },
    async mounted(){
        await this.getWallets();

    }
}
</script>
<style scoped>
.account-wallets-content .wallet-name{
    max-width: 100%;
}
</style>
<style>
.account-wallets-content .add-copy .copy-icon::after{
    content:  '' !important;
    display: none !important;
}
.withdraw-btn button{
    text-transform: none !important;
}
.wallet-content{
    background-color: #2B2E54;
    border-radius: 12px;
    padding: 16px 24px;
}
.wallet-content:not(:last-child){
    margin-bottom: 8px !important;
}
.wallet-content-name{
    width: 70%;
}
.wallet-content-name span{
    font-family: 'Inter',sans-serif;
    font-size: 14px !important;
}
.account-wallets-content .wallet-content-copy{
    color: #40EFFA;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
}
.account-wallets-content .wallet-name .add-copy{
    padding: 3px 6px;
    background-color: #212445 !important;
}
.account-wallets-content .wallet-name .add-copy span{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.account-wallets-content .wallet-name .add-copy .copy-icon:active{
    color: #35c3cd !important;
}
.account-wallets-content .wallet-content-copy i{
    color: #40EFFA;
}
.account-wallets-content .wallet-content-copy:active{
    color: #35c3cd;
}
.account-wallets-content .wallet-content-copy:active .copy-icon{
    color: #35c3cd !important;
}
.account-wallets-content .wallet-content-copy:active .copy-icon::after{
    content: '' !important;
    display: none !important;
}
.copy-icon{
    color: #40EFFA !important;
    cursor: pointer;
}
.add-wallet-form{
    background-color: #1E213F;
}
.add-wallet-form{
    max-width: 500px;
    margin: 0px auto;
}
.payment-dialog .main-form h5{
    font-size: 18px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    margin: 24px 0px;
}
span.wallet-address{
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>