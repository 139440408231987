
let storage = window.localStorage;
let userToken = storage.getItem('token');
let userRefresh = storage.getItem('refresh');

let user = null;
try{
    user = JSON.parse(storage.getItem('user'));

}catch(err){
    

    err;
}

import router from '@/router'

import axios from 'axios'
export default{
    namespace: true,
    state(){
        return{
            isAuth:  userToken && userToken != null ? true : false,
            token: userToken,
            refresh: userRefresh,
            user: user,
            wallets: null
        }
    },  
    getters:{
        user(state){
            return state.user;
        },
        token(state){
            return state.token;
        },
        refresh(state){
            return state.refresh;  
        },
        isAuth(state){
            return state.isAuth;
        },
        test(state){
            return state.test;
        },
        wallets(state){
            return state.wallets
        }
    },
    mutations:{
        setToken(state,token){
            state.token = token;
            storage.setItem('token', token)
            // window.location.reload();
        },
        setRefresh(state,refresh){
            state.refresh = refresh;
            storage.setItem('refresh', refresh)
        },
        setAuth(state,data){
            state.isAuth = data;
        },
        setUser(state, data){
            storage.setItem('user',JSON.stringify(data));
            state.user = data
        }
        ,
        logout(state){
            state.isAuth = false;
            state.token = null,
            state.refresh = null;
            storage.removeItem('token');
            storage.removeItem('refresh');
            storage.removeItem('user');
            window.location.href = '/signin'
        },
        setWallets(state, data){
            state.wallets = data 
        },
    },
    actions: {
        async attempt(context, data){

            context.commit('setToken', data.access);
            context.commit('setRefresh', data.refresh);
            context.commit('setAuth', true);
            context.commit('resetPage')
            router.push({name: 'Account'});
            
        },

        async setUser(context){

            let server = context.getters.server;
            let token = context.getters.token;

            
            axios.defaults.headers['Authorization'] = 'Bearer ' + token;
            
            
            await axios.get(server + '/user/info').then(response => {
                // console.log(response);
                context.commit('setUser', response.data)
            }).catch(() => {
                context.commit('logout')
            })
        },
        
        logout(context){
            context.commit('logout');
        },
        async refresh(context){
            let server = context.getters.server;
            let refresh = context.getters.refresh;
            let token = context.getters.token
            let data = {
                refresh: refresh
            }
            if(token){
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                await axios.post(server + '/user/token/refresh',data).then(response => {
                    context.commit('setToken', response.data.access)
                    window.location.reload()
                }).catch(() => {
                    context.commit('logout')
                })
            }
        },
        async setWallets(context){
            
            if(!context.getters.token)
                return context.commit('setWallets', [])

            let server = context.getters.server;
            let token = context.getters.token;
            
            axios.defaults.headers['Authorization'] = 'Bearer '+ token;
            await axios.get(server + '/user/wallets').then(response => {

                context.commit('setWallets', response.data.wallets)

            }).catch(() => {
                context.commit('serWallets', [])
            })
            
        },
        deleteWallet(context, id){

          let wallets = context.getters.wallets.filter(element => {
            return element.id != id;
          })

          return context.commit('setWallets', wallets);

        },

    }

}