<template>
    <div class="shop-content">
        <v-container>
                <CardItem></CardItem>
        </v-container>
    </div>
</template>
<script>
import CardItem from './CardItem.vue'
export default{
    components:{CardItem},
    data(){
        return{ 
            selectedComponent: 'CardItem'
        }
    },
}
</script>

<style scoped>
</style>
<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .4s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

    @media (min-width: 1904px){
        .shop-content .container{
            max-width: 1340px !important;
        }
    }
</style>