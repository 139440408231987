import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from './auth/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    server: process.env.VUE_APP_BACKEND_SERVER, // Python Django Server (Docker)
    rules:{
      emailRules : [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
      passwordRules: [
      (v) => (v && /\d/.test(v)) || 'At least one digit',
        (v) => (v && /[A-Z]{1}/.test(v)) || 'At least one capital latter',
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || 'At least one special character',
        (v) => (v && v.length > 8 ) || 'Minimum 8 characters',
      ],
      nameRules:[
        (v) => (v && /^[A-Za-z]+$/.test(v) || 'Name can only be letters'),
        (v) => (v && v.length <= 50 || 'Maximum character limit for this input is 50')
      ],  
      stringRule:[
        (v) => (v && /^[^0-9][A-Za-z0-9]+$/.test(v) || 'Input wasn\'t in a correct format')
      ],
      phoneRules:[
        (v) => (v && /\+?\d+\s?/.test(v) || "Phone number is not valid"),
        (v) => (v && v.length <= 12 || 'Maximum character limit for this input is 12')
      ],
      zipRules:[
        (v) => (v && /^[\w\s\d-]+$/.test(v) || "Zipcode is not valid"),
        (v) => (v && v.length <= 20 || "Maximum character limit for this input is 20")
      ],
      addressRules:[
        (v) => (v && v.length <= 50 || 'Maximum character limit for this input is 50')
      ],
      requiredRules: [v => !!v || 'This field is required.' ],
      minRules: [v => !v || v.length >= 3 || 'Minimum character limit not met']
    },
    status:{
      CR: 'CREATED',
      PD: 'PAID',
      DP: 'DISPATCHED',
      DL: 'DELIVERED',
      PN: 'PENDING',
      UP: 'UNDERPAID',
      OP: 'OVERPAID',
      FD: 'FAILED',
      BD: 'BILLING_DISABLED',
      MD: 'PAYMENT_METHOD_DISABLED',
    },
    coins: [
      {id: 1, coin: 'BTC', name: 'Bitcoin',   display: 'BTC' , img: 'bitcoin.png'},
      {id: 2, coin: 'USDT', name: 'Tether',   display: 'USDT - ERC20' ,img: 'tether.png'},
      {id: 3, coin: 'USDT2', name: 'Tether',   display: 'USDT - TRC20', img: 'tether.png'},
      {id: 4, coin: 'ETH', name: 'Etherceum', display: 'ETH'  ,img: 'ethereum.png'},
      {id: 5, coin: 'LTC', name: 'Litecoin',  display: 'LTC'  ,img: 'litecoin.png'},
      {id: 6, coin: 'USDC', name: 'USD Coin', display:'USDC'  ,img: 'usd-coin.png'},
      {id: 7, coin: 'DOGE', name: 'Dogecoin', display: 'DOGE' ,img: 'dogecoin.png'},
      {id: 8, coin: 'DAI', name: 'DAI',       display:'DAI'   ,img: 'multi-collateral-dai.png'},
      {id: 9, coin: 'BCH', name: 'Bitcoin Cash',display:'BCH' ,img: 'bitcoin-cash.png'},
      {id: 10, coin: 'APE', name: 'ApeCoin',  display:'APE'   ,img: 'apecoin-ape.png'},
      {id: 11, coin: 'SHIB', name: 'Shiba Inu',display: 'SHIB',img: 'shiba-inu.png'},
      // {id: 12, coin: 'MATIC', name: 'Polygon',display:'MATIC' ,img: 'polygon.png'},
    ],
    isWhitelisted: false,
  },
  getters: {
    server(state){
      return state.server;
    },
    rules(state){
      return state.rules
    },
    status(state){
      return state.status
    },
    coins(state){
      return state.coins
    },
    isWhitelisted(state){
      return state.isWhitelisted
    }
  },
  mutations: {
    resetPage(){
      let overlays = document.getElementsByClassName('v-overlay')
        
      Array.from(overlays).forEach(el => el.remove()) 
    },
    defaultImg(){
      return require('@/assets/default-placeholder.png')
    },
    getCoin(_,coin){
      try{
        return require(`@/assets/coins/${coin}.png`)
      }catch{
        return require('@/assets/coins/default.png')
      }
    },
    setWhitelisted(state, data){
      state.isWhitelisted = data
    }    
  },
  actions: {
    setUser(context,data){
      context.commit('setUser', data)
    },
    resetPage(context){
      context.commit('resetPage')
    },
    actions(context){
      context.commit('defaultImg')
    },
    getCoin(context, data){
      context.commit('getCoin', data)
    },
    setWhitelisted(context,data){
      context.commit('setWhitelisted', data);
    }
  },
  modules: {
    auth: AuthModule
  }
})
