<template>
    <div class="change-content">
        <v-form  lazy-validation ref="form" @submit.prevent="change">
          <h2 class="text-center mb-4">Change password</h2>
          <label for="password" class="lbl-input mb-1">New password</label>
          <v-text-field validate-on-blur class="form-input" v-model="password" :rules="[...rules.passwordRules]" id="password" outlined></v-text-field>
          <v-btn block :loading="logging" @click="change"  class="main-btn mb-6" :disabled="logging" >Change</v-btn>       
      </v-form>
    </div>
</template>
<script>
  export default{
    data(){
      return{
        password: null,
        logging: false,
      }
    },
    computed:{
        rules(){
          return this.$store.getters['rules'];
        }
    },
    methods:{
      async change(){ 
        
        if(this.$refs.form.validate()){

          this.$emit('validForm', this.password);
      
        }

        
      }
    }

  }
</script>
<style scoped>
.main-btn{
  min-height: 48px !important;;
  height: 48px !important;
}
</style>
<style>
.main-alert .v-snack__wrapper{
  background-color: #FFF;
}
.change-content h2{
    font-family: 'Inter',sans-serif;
    font-size: 28px;
    color: #212445;
    font-weight: normal;
}
.change-content .form-input fieldset{
    border: 2px solid #C7D5E1;
    border-radius: 12px;

}
.change-content .form-input .v-text-field__slot input{
    color: #344054 !important;
}
.change-content .lbl-input{
    color: #344054;
    font-family: 'Inter',sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: block;
}
.change-content .sign-links{
    text-decoration: none;
    font-family: 'Inter',sans-serif;
    color: #0FD6E3;
    font-size: 15px !important;
}
@media (min-width: 1264px){

    .change-content h2{
        font-weight: 600;
        font-size: 32px !important;
    }
}
</style>