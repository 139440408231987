<template>
    <div class="main-faq">
        <v-container>
            <div class="faq-header">
                <h2>Frequently asked questions</h2>
                <p>Everything you need to know about the product and billing.</p>
            </div>
            <div class="faq-content">
                <FaqComponent></FaqComponent>
            </div>
        </v-container>
    </div>
</template>
<script>
import FaqComponent from '@/components/Basics/FaqComponent.vue'
    export default{
        components:{FaqComponent},
        data(){
            return{

            }
        }
    }
</script>
<style>
    .main-faq{
        margin-top: 100px;
        position: relative;
    }

    .main-faq .faq-header h2{
        font-family: 'Girloy', sans-serif;
        font-size: 36px;
        background: radial-gradient(51.88% 5897.7% at 50.06% 66.25%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-top: 12px !important;
        text-align: center;
    }
    .main-faq .faq-header p{
        font-family: 'Inter',sans-serif;
        text-align: center;
        margin-top: 20px;
    }
    .main-faq .faq-content{
        margin-top: 64px !important;
        position: relative;
        z-index: 5;
    }
    @media (min-width: 1264px){
        .main-faq .faq-header h2{
            font-size: 54px !important;
        }
    }
</style>