<template>
    <div class="dashboard-navbar">
    <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" bottom right timeout="2000" transition="scroll-x-transition">
          {{ alert.message }}
          <template v-slot:action="{ attrs }">
              <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
          </template>
    </v-snackbar>


        <v-container class="navbar-container">
            <div class="navbar" v-if="user" ref="navbar">
                <div class="d-flex justify-space-between align-center">
                    <div class="nav-logo">
                        <hexa-logo></hexa-logo>
                    </div>
                    
                    <div class="nav-links">
                        <ul class="d-none d-md-flex desk-menu">
                            <router-link v-for="(item, index) in menu" :key="index" tag="li" active-class="active" :to="item.link">{{ item.name }}</router-link>
                        </ul>
                        <div class="dialog-menu">
                            <template>
                                <div>
                                    <v-icon
                                    color="white"  
                                    @click="toggleDialog"
                                    class="btn-menu d-flex d-md-none"
                                    >
                                        {{ dialog ? 'mdi-close' : 'mdi-menu' }}
                                    </v-icon>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="d-none d-md-block nav-btns" id="nav-btns">
                        <div class="account-view-icons d-flex">
                        <v-menu v-model="showNotification" :close-on-content-click="false" offset-y transition="slide-y-transition" attach="#nav-btns" content-class="profile-menu menu-notifications">
                            <template v-slot:activator="{ on, attrs }">
                            <div class="my-account-alerts" v-on="on" v-bind="attrs">
                                <v-btn icon>
                                    <v-badge :content="user.verified_email ? 0 : 1" color="error">
                                        <v-icon >mdi-bell-outline</v-icon>
                                    </v-badge>
                                </v-btn>
                            </div>
                            </template>
                            <v-list three-line class="notification-list">
                                    <v-subheader>Notifications</v-subheader>

                                    <v-divider></v-divider>

                                    <v-list-item v-if="!user.verified_email">
                                    <v-list-item-avatar>
                                        <v-icon large :color="item[0].color">{{ item[0].icon }}</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold mb-3">
                                            <span v-html="item[0].title"></span>
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mb-2">
                                            <span v-html="item[0].subtitle"></span>
                                            <a to="/" class="ml-2" style="color:#10EBF9 !important" @click="sendVerify">Verify Now</a>
                                        </v-list-item-subtitle>
                                        <span class="list-date-custom">{{new Date()}}</span>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-else>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>No notifications found</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu v-model="profileMenu"  offset-y transition="slide-y-transition" attach="#nav-btns" content-class="profile-menu">
                            <template v-slot:activator="{ on, attrs }">
                                <div class="my-account d-flex align-center" v-on="on" v-bind="attrs">
                                    <div class="my-account-icon">{{ abbrv }}</div>
                                    <div class="my-account-details">
                                        <span class="my-details-name">{{name}}</span>
                                        <span class="my-details-email">{{email}}</span>
                                    </div>
                                </div>
                            </template>
                                <v-list class="list-out">
                                    <v-list-item @click="signOut">
                                        <v-list-item-title>Sign out</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                        </v-menu>
                        </div>
                    </div>
                </div>
                <Teleport to="#app">
                <div class='mob-list' :class="dialog && showMenu ? 'show-mob' : 'hide-mob'">
                        <div class="mob-list-menu">
                            <div class="menu-container flex-column">
                                <div class="my-account d-flex align-center justify-center">
                                    <div class="my-account-icon">{{ abbrv }}</div>
                                    <div class="my-account-details">
                                        <span class="my-details-name">{{ name }}</span>
                                        <span class="my-details-email">{{ email }}</span>
                                    </div>
                                </div>
                            
                                <ul class="d-block text-center">
                                    <li v-for="(item, index) in menu" :key="index"><router-link :to="item.link">{{ item.name }}</router-link></li>
                                    <li @click="signOut">Sign out</li>
                                    <li class="mt-10"><v-btn class="nav-mining main-color elevation-0 px-3" to="/shop" rounded>Add New Miner <img class="ml-2" src="@/assets/cube-outline.svg"></v-btn></li>
                                </ul>
                            </div>
                        </div>
                  </div>
                </Teleport>
            </div>
        </v-container>
    </div>
</template>

<script>
export default{
    data(){
        return{
            dialog: false,
            profileMenu: false,
            menu: [
                {name: 'Overview', link: '/overview'},
                {name: 'Wallet', link: '/wallet'},
                {name: 'Shop', link: '/shop'},
                {name: 'Help Center', link: '/help'},
                
            ],
            showNotification: false,
            notifications: [
                {
                    title:'Custom Action',
                    description: 'Action description',
                    time: new Date()
                }
            ],
            item: [
            {
                    icon: 'mdi-lightning-bolt-outline',
                    color: '#EBC05B',
                    title: 'Verify Email',
                    subtitle: `<span class="text--primary">Please verify your email address</span>`,
                }
            ],
            alert:{
                show: null,
                type: null,
                message: null  
            }
            
        }
    },
    
    computed:{
        showMenu(){
            let show = this.$vuetify.breakpoint.name != 'md' && 
            this.$vuetify.breakpoint.name != 'lg' &&
            this.$vuetify.breakpoint.name != 'xl';

            
            if(this.dialog && !show)
                this.closeDialog();
                
            return show; 
            
        },
        user(){
            return this.$store.getters['user'];
        },
        name(){
            return `${this.user.first_name} ${this.user.last_name}`
        },
        abbrv(){ 
            return `${this.user.first_name[0]}${this.user.last_name[0]}`
        },
        email(){
            return this.user.email
        },

    },  
    methods:{
        closeDialog(){
            this.dialog = false;
        },
        toggleDialog(){
            this.dialog = !this.dialog;
            document.documentElement.style.overflowY = this.dialog ? 'hidden' : 'scroll';
            // this.$refs.navbar.style.backgroundColor = this.dialog ?  'transparent' : 'rgba(30, 33, 63, 0.80)'
          

        },
        async signOut(){
            let refresh = this.$store.getters['refresh'];

            if(refresh)    
                refresh = refresh.replace(/^"+/, '').replace(/"+$/, '');
            
            let server = this.$store.getters['server'];

            let data = {
                refresh: refresh
            }
            await this.$http.post(server + '/user/logout', data).then(() => {
                this.$store.dispatch('logout');
            }).catch(() => {                
                this.$store.dispatch('logout');
                
            });
   
        },
        async sendVerify(){
            let server = this.$store.getters['server'];

            await this.$http.get(server + '/user/email/verify/remind').then(response => {
                this.alert.type = 'success';
                this.alert.message = response.data.message ?? response.statusText;

            }).catch(err => {
                this.alert.type = 'error';
                this.alert.message = err.response.data.message ?? err.response.statusText;
            })

            this.alert.show = true;
        },

    },

}
</script>
<style scoped>
.my-account-alerts .v-btn{
    width: 48px;
    height: 48px;
}
.notification-list{
    background-color:#1E213F !important ;
}
.notification-list > div:not(:first-child):hover{
    background-color: rgb(29 29 29 / 23%);
}
.list-date-custom{
    font-size: 10px !important;
}
.dashboard-navbar{
    /* background-color: #0D1116; */
    border-bottom: 1px solid #1E213F;
    position: relative;
    z-index: 1;
}
.mob-list-menu{
    margin-top: 60px;
}
.mob-list-menu .menu-container{
    background-color: #0D1116;
    padding: 46px 0px !important;
    border-bottom: 1px solid #1E213F;
}
.mob-list-menu .menu-container{
    margin-top: 0px !important /* TEST */;
}
    .mob-list-menu ul li{
        margin-top: 10px;
    }
    .nav-links ul{
        list-style: none;
    }
    .nav-links .desk-menu{
        list-style: none;
        padding: 0px !important;
        display: flex;
    }

    .nav-links .desk-menu {
        display: flex;
        align-items: center;
    }
    .nav-links .desk-menu li{
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        padding: 4px 10px 4px 10px !important;
    }
    .nav-links .desk-menu li.active{
        color: #40EFFA !important;
        padding: 4px 10px 4px 10px !important;
        /* border: 1px solid #0BA7B1 !important; */
        border-color: #0BA7B1;
        border-radius: 36px !important;
    }
    .nav-links .desk-menu li:not(:last-child){
        font-family: 'Inter', sans-serif;
    }
    .navbar-container .nav-links .desk-menu li:not(:last-child){
        margin-right: 0px;
    }

    .log-in{
        text-decoration: none;
        color: #CCC !important;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        margin-right: 20px;
    }
    .nav-mining{
        text-transform: none !important;
    }
    .navbar .v-list{
        background-color: transparent;
        box-shadow: 0px !important;
    }

    .btn-menu::after{
        background-color: transparent !important;
        position: relative;
        z-index: 33;
    }

    .mob-list.show-mob{
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.6);
        backdrop-filter: blur(5px);
        z-index: 0;
        animation-name: list;
        animation-duration: 0.5s; 
        animation-delay: 0s;
        animation-direction: normal;
}

    .mob-list.hide-mob{
        display: none;
    }
    .mob-list.show-mob{
        display: block;
    }
    .nav-logo{
        position: relative;
        z-index: 32;
    }
    @media (min-width: 960px){
        .mob-list{
            display: none !important;
        }
    }   
    @media (min-width: 1264px){
        .navbar-container .nav-links .desk-menu li:not(:last-child){
            margin-right: 36px;
        }
        .dashboard-navbar .container{
            padding-top: 16px;
            padding-bottom: 16px;
        }
        .dashboard-navbar{
            padding: 16px 54px;
        }

    }
    @keyframes list {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
   }
   @keyframes list2 {
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
   }
   .dashboard-navbar .list-out{
    background-color: #1E213F !important;
   }
</style>
<style>
.navbar a{
    color: inherit !important;
    text-decoration: none !important;
}
    .my-account:hover{
        cursor: default;
    }
    .profile-menu{
        margin-top: 10px !important;
        background-color: #333;
    }
    /* Dashboard Custom */
    .my-account-alerts,
    .my-account-icon{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .my-account-alerts{
        background: rgba(255, 255, 255, 0.07);
        margin-right: 14px;
    }
    .my-account-icon{
        background: #3E454E;
        margin-right: 10px;
    }
    .my-account-details span{
        display: block;
    }
    .my-account-details .my-details-name{
        font-family: 'Inter',sans-serif;
        font-size: 14px;
        font-weight: 600;
    }
    .my-account-details .my-details-email{
        font-family: 'Inter',sans-serif;
        font-size: 12px;
        color: #B6B7B9;
    }
    /* Dashboard Custom */
    .dialog-menu .mdi-menu,.dialog-menu .mdi-close{
        position: relative;
        z-index: 33;
    }
.navbar-container .navbar{
    margin-top: 0px !important;
    position: relative;
}
    @media (min-width: 960px){
        .my-account:hover{
            cursor: pointer !important;
        }
   
    } 
    @media (min-width: 1904px){
        .navbar-container{
            max-width: 1340px !important;
        }
    }
.dashboard-navbar .navbar{
    border-radius: 12px !important;
}
.v-list--three-line .v-list-item, .v-list-item--three-line{
    min-height: auto !important;
}
.profile-menu.menu-notifications{    
    left: 60% !important;
    width: 40% !important;
}
</style>