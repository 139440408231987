<template>
    <div class="wrap-star"  ref="network">
        <div class="main-network">
            <BackgroundStars></BackgroundStars>

                <v-container>
                    <div class="circle-blur"></div>
                    <div class="network-header text-center">
                        <v-btn class="btn-case" rounded>Use cases</v-btn>
                        <h2>Real World Use Cases</h2>
                        <p>The following multi-billion dollar industries rely on real time analytical data that our network can provide.</p>
                    </div>
                    <div class="network-cycle">
                        <div class="network-usecases d-block d-lg-flex flex-row flex-lg-column justify-space-between">
                            <div class="user-cases-list d-flex align-center flex-wrap justify-center">
                                <div class="usecase-item text-center" v-for="(item,index) in items" :key="index">
                                    <div class="usecase-item-icon">
                                        <img :src="getImg(item.icon)" alt="Img">
                                    </div>
                                    <div class="usecase-item-header my-2">
                                        <!-- <div class="circle"></div> -->
                                        <h4>{{ item.title }}</h4>
                                    </div>
                                    <div class="usecase-item-body">
                                    <p>{{ item.text }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="network-graph d-flex justify-center align-center flex-wrap">
                            <div class="circular-icons">
                                <div class="icons-container">
                                    <div class="circular-icon"></div>
                                    <div class="circular-icon"></div>
                                    <div class="circular-icon"></div>
                                    <div class="circular-icon"></div>
                                    <div class="circular-icon"></div>
                                    <div class="circular-icon"></div>
                                </div>
                            </div>
                            <NetworkGraph></NetworkGraph>
                        </div>
                    </div>
                </div>
                <div class="hexa-analysis d-flex">
                    <img src="@/assets/hexa-analysis.png" alt="Hexageo-Analysis">
                    <div class="hover-btn d-flex justify-center align-center">
                        <v-btn class="btn-mining py-3" @click="getAccess" :ripple="false">Get Early Access</v-btn>
                    </div>               
                </div>
                
            </v-container>
            
        </div>
    </div>
</template>
<script>
import NetworkGraph from './NetworkGraph.vue';
import BackgroundStars from '@/components/Basics/BackgroundStars.vue'
export default{
    components:{NetworkGraph,BackgroundStars},
    emits:['access'],
    data(){
        return{
            items:[
                {
                    icon: 'ecommerce-usecase.png',
                    title: 'E-Commerce',
                    text: 'E-commerce companies often need to track competitor prices across different regions. Hexageo enables them to collect accurate pricing data.'
                },
                {
                    icon: 'finance-usecase.png',
                    title: 'Finance',
                    text: 'Hexageo enables finance companies to access localized content, financial news, and market updates from around the world. This ensures that they have a comprehensive understanding of global financial markets.'
                },
                {
                    icon: 'travel-usecase.png',
                    title: 'Travel',
                    text: 'Hexageo enables travel companies to track prices of flights, accommodations, and other services from various sources, ensuring they provide accurate and up-to-date pricing to their customers.'
                },
                {
                    icon: 'HOUSE-usecase.png',
                    title: 'Real Estate',
                    text: 'Hexageo enables real estate companies to collect property listings, pricing data, and market trends from multiple sources and locations, aiding in comprehensive market analysis.'
                },
                {
                    icon: 'usecase-care.png',
                    title: 'Insurance',
                    text: "Insurance companies can use Hexageo to gather data from competitors' websites, including policy offerings, pricing details, and coverage options, aiding in refining their own offerings",
                },
                {
                    icon: 'heart-usecase.png',
                    title: 'Healthcare',
                    text: 'Healthcare companies often need to gather medical research data from various sources and Hexageo can assist in accessing clinical data from different regions for collaborative studies.'
                },
            ]
        }
    },
    methods:{
        getImg(img){
            return require('@/assets/usecases/'+img) 
        },
        getAccess(){
            return this.$emit('access')
        }
    }
}
</script>
<style scoped>
    .wrap-star::after{
        content: '';
        width: 80%;
        height: 500px;
        max-width: 1100px;
        background: rgba(48, 47, 107, 1);
        filter: blur(180px);
        border-radius: 50%;
        position: absolute;
        bottom: -22%;
        z-index: 2;
        left: 50%;
        transform: translate(-50%,-0%);
    }
</style>
<style>
    .main-network{
        /* overflow: hidden; */
        background: radial-gradient(103.09% 103.09% at 50% 7.14%, #231441 0.01%, #0D1116 54.17%, #300E5B 85.08%, #7371FF 100%), #0D1116;
        background-position: top center;
     
        margin: 0px auto;
        margin-top: 159px;
        border-top: 1px solid #3F3E8C;
        border-bottom: 1px solid #3F3E8C;

        /* border-radius: 24px; */
        position: relative;
        overflow: hidden !important;
    }
    .wrap-star{
        position: relative;
    }
    .main-network .container{
        padding-bottom: 0px !important;
    }


    .btn-case{
        text-transform: none;
        background-color: #0E3D43 !important;
        font-family: 'Inter', sans-serif;
        margin-top: 70px !important;
    }
    .network-header h2{
        font-family: 'Girloy', sans-serif;
        font-size: 36px !important;
        background: radial-gradient(51.88% 5897.7% at 50.06% 66.25%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-top: 12px !important;
    }
    .network-header p{
        max-width: 500px;
        text-align: center;
        margin: 0px auto;
        font-family: 'Inter',sans-serif;
        color: #E9E9EC !important;
        margin-top: 16px !important;
    }
    .network-usecases{
        margin-top: 40px !important;
        border-radius: 32px;
        position: relative;
        z-index: 4;
    }

    .usecase-item:not(:first-child){
        margin-top: 32px;
    }
    .usecase-item-header .circle{
        width: 13.33px;
        height: 13.33px;
        border: 1px solid #FFF;
        border-radius: 50%;
        margin-right: 9.33px;
    }
    .usecase-item-header h4{
        font-family: 'Inter',sans-serif;
        font-size: 18px !important;
        font-weight: 400;
    }
    .usecase-item-body p{
        font-family: 'Inter';
        font-style: normal;
        color: #868E95;
        max-width: 850px;
    }



    .hexa-analysis {
        margin-top: 90px !important;
        position: relative;
        z-index: 5;
    }
    .hexa-analysis img{
        width: 100%;
        transition: 0.2s filter linear;
        -webkit-transition: 0.2s -webkit-filter linear;
        -moz-transition: 0.2s -moz-filter linear;
        -ms-transition: 0.2s -ms-filter linear;
        -o-transition: 0.2s -o-filter linear;
    
    }

    .hover-btn button{
        opacity: 0;
        min-height: 56px !important;
        /* top: 50%; */
        /* width: ; */
        /* height: auto !important; */
    }
    .hover-btn button:focus{
        position: initial  !important;
    }
    .hexa-analysis:hover .hover-btn button{
        animation: hov 2000ms forwards;
        -webkit-animation: hov 2000ms forwards;

    }
    .hexa-analysis:hover img{
        filter: blur(3px)
    }
    .hexa-analysis:hover .hover-btn button{
        visibility: visible;
    }
.hover-btn{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.circular-icons{
    width: 80%;
    max-width: 380px;
    border-radius: 50%;
    aspect-ratio: 1;
    -webkit-aspect-ratio: 1;
    -moz-aspect-ratio: 1;
    -o-aspect-ration: 1;
    position: absolute;
    container-type: size;
    -webkit-container-type: size;
    -o-container-type: size;
    -moz-container-type: size;

}
.icons-container{
    position: relative;
    width: 100%;
    height: 100%;

}
.circular-icon{
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    height: 50px;
    width: 50px;
    background-color: #3F3E8C;
    border: 1px solid; 
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%, #FFFFFF 100%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circular-icon::before{
    content: '';
    /* position: absolute; */
    width: 9px;
    height: 9px;
    background-color: #40EFFA;
    border-radius: 50%;
}
.circular-icon:nth-child(1) {
    transform: translateX(0%);
}
.circular-icon:nth-child(1) {
    transform: translateX(50cqh);
}
.circular-icon:nth-child(2) {
    transform: rotate(60deg) translateX(50cqh);
}
.circular-icon:nth-child(3) {
    transform: rotate(120deg) translateX(50cqh);
}
.circular-icon:nth-child(4) {
    transform: rotate(180deg) translateX(50cqh);
}
.circular-icon:nth-child(5) {
    transform: rotate(240deg) translateX(50cqh);
}
.circular-icon:nth-child(6) {
    transform: rotate(300deg) translateX(50cqh);
}


/* Responsive */
.usecase-item-body{
    text-align: center;
}
.usecase-item-header{
    justify-content: center;
}
.hover-btn .btn-mining{
    padding: 10px !important;
}
.user-cases-list{
    margin-bottom: 64px;
}
@media(min-width: 600px){
    .network-header h2{
        font-size: 54px !important;
    }
}
    @media (min-width: 1264px){
        .usecase-item:not(:first-child){
            margin-top: 0px;
        }
        .user-cases-list .usecase-item{
            width: calc(33% - 32px);
        }
        .main-network{
            border-radius: 24px !important;
            width: 98%;
            border-top: 1px solid #3F3E8C;
            border-left: 1px solid #3F3E8C;
        }
        .user-cases-list{
            gap: 32px;
        }
        .network-usecases{
            background-color: #171A38!important;
            border: 1px solid #302F6B !important;
            padding: 64px 48px;
        }
        .usecase-item-header{
            justify-content: start;
        }
        .hover-btn button{
            min-width: 300px !important;
            position: initial !important;
        }
    }

</style>