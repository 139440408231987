<template>
    <div class="main-faq-temp">
        <v-expansion-panels accordion>
            <v-expansion-panel v-for="(item,index) in faq" :key="index" >
                <v-expansion-panel-header disable-icon-rotate hide-actions>
                    <template v-slot:default="{ open }">
                        <div>
                            {{ item.question }}
                            <v-icon>
                            <template v-if="!open">mdi-plus</template>
                            <template v-else>mdi-minus</template>
                            </v-icon>
                        </div>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ item.answer }}    
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
    export default{
        props:{
            faq:{
                type: Array,
                // required: true,
                default(){
                    return [
                            {
                                question: 'Can I transfer my hexageo miner to another person?', 
                                answer: 'Yes, all you need to do is remove the miner from your dashboard and then the person you transferred it to may activate it on their account.'
                            },
                            {
                                question: 'Is there a warranty?', 
                                answer: 'All Hexageo miners come with a lifetime replacement warranty.'
                            },
                            {
                                question: 'Can I refer a friend and earn % of his miners crypto earnings?', 
                                answer: 'Yes. We offer one of the best affiliate offers you will ever find. We give you a % of the sale of the miner, as well as a % of the lifetime crypto earnings that miner makes.'
                            },
                    ]
                    
                }
            }
        }
    }
</script>
<style>
.main-faq-temp{
    max-width: 768px;
    margin: 0px auto;
}
.main-faq-temp .v-expansion-panel{
        background-color: transparent !important;
        box-shadow: none !important;
        z-index: 0 !important;
}
.main-faq-temp .v-expansion-panel::before{
    box-shadow: none !important;
}
.main-faq-temp .v-expansion-panel h4{
    font-family: 'Inter',sans-serif;
    font-size: 18px;
    /* font-weight: normal; */
    font-weight: 600 !important;
}
.main-faq-temp .v-expansion-panels{
    z-index: 0 !important;
}
.main-faq-temp .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon){
    display: flex;
    justify-content: space-between;
    font-family: 'Inter', sans-serif !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
}
.main-faq-temp .v-expansion-panel-content__wrap{
    font-family: 'Inter',sans-serif;
    color: #BABBC5;
    font-size: 16px !important;
    margin-bottom: 25px;
}
.main-faq-temp .v-expansion-panel-header{
    padding: 25px 24px;
}
</style>