<template>
    <div class="miner-coin-container">
        <div class="miner-details-coin d-flex align-center">
            <img width="22px" class="mr-3" :src="getImg(coin.img)">
            <p>{{coin.name}}</p>
        </div>
    </div>
</template>
<script>
    export default{
        props:{
            coin: {
                type: Object,
                required: true,
                default(){
                    return {
                        name: 'Bitcoin',
                        img: 'bitcoin.png'
                    }
                },
            }
        },
        methods:{
            getImg(img){
                return require(`@/assets/coins/${img}`)
            }
        }

    }
</script>