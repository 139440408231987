<template>
  <div class="homepage" ref="homepage">
    <!-- Alert Notification -->
    <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
        {{ alert.message }}
        <template v-slot:action="{ attrs }">
            <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
        </template>
    </v-snackbar>
    
    <!-- Alert Notification -->

    <!-- Access Dialog -->
    <v-dialog
    :opacity="0.6"
    v-model="dialog"
    width="100%"
    max-width="768px"
    persistent
    content-class="contact-dialog">
        <v-form @submit.prevent="subscribe" ref="form">
            <div class="main-form dialog-access">
                <v-btn color="transparent" v-if="!loadAccess" class="d-flex ml-auto close-btn" rounded small @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                <h2 class="text-center">Secure your<span class="d-block access-headline-custom">1.5x earning multiplier</span></h2>
                <p class="text-center">We're excited to have you on board as we revolutionize the world of cryptocurrency mining. Kindly share your email with us, and you'll be at the forefront of this innovative journey. Stay connected for updates, insights, and the opportunity to be part of the future of mining. Thank you for joining us!</p>
                <v-text-field v-model="email" validate-on-blur :rules="[...rules.requiredRules, ...rules.emailRules]" class="main-input mt-10" label="Email" hide-details="auto" solo></v-text-field>
                <v-btn :loading="loadAccess" :disabled="loadAccess" @click="subscribe" class="main-btn py-4 mt-10" block>Join Waitlist</v-btn>
            </div>
        </v-form>
    </v-dialog>
    <!-- Access Dialog -->
    
    <Loading :loading="load"></Loading>
    <HexaNavbar @access="accessOpen" @slide="slideTo" :current="isTop"></HexaNavbar>
    <MainHeader @access="accessOpen" ref="hero"></MainHeader>
    <CoinTicker></CoinTicker>
    <MinersComparison></MinersComparison>
    <MainProfitability @access="accessOpen" v-if="coins.length" :coinsList="coins" ref="profitability"></MainProfitability> 
    <MainNetwork @access="accessOpen" ref="network"></MainNetwork>
    <v-container class="new-feature-container">
      <MinerDetails ref="features"></MinerDetails>
    </v-container>
    <MainFAQ></MainFAQ>
    <!-- <MainHow ref="howto"></MainHow> -->
    <MainFooter @access="accessOpen"></MainFooter>
  </div>
</template>


<script>
  import Loading from '@/components/Dashboard/Basics/Loading.vue'
  import HexaNavbar from '@/components/LandingPage/Homepage/Header/Navbar.vue'
  import MainHeader from '@/components/LandingPage/Homepage/Header/MainHeader.vue';
  import MainProfitability from '@/components/LandingPage/Homepage/Profitability/MainProfitability.vue'
  import MainNetwork from '@/components/LandingPage/Homepage/Network/MainNetwork.vue'
  // import MainMiner from '@/components/LandingPage/Homepage/Miner/MainMiner.vue'
  import MainFAQ from '@/components/LandingPage/Homepage/FAQ/MainFAQ.vue'
  // import MainHow from '@/components/LandingPage/Homepage/HowTo/MainHow.vue'
  import MainFooter from '@/components/Basics/MainFooter.vue'
  import MinerDetails from '@/components/LandingPage/Homepage/MinerDetails/MinerDetails.vue'
  import MinersComparison from '@/components/LandingPage/Homepage/MinersComparison/MinersComparison.vue'
  import CoinTicker from '@/components/LandingPage/Homepage/CoinTicker/CoinTicker.vue'
  
  export default {
    name: 'HomePage',
    data(){
      return{
        load: false,
        coins: [],
        isTop: true,
        loadAccess: false,
        dialog: false,
        email: null,
        alert:{
          type: null,
          message: null,
          show: false
        }
      }
    },
    components:{
      Loading,
      HexaNavbar,
      MainHeader,
      MainProfitability,
      MainNetwork,
      // MainMiner,
      MainFAQ,
      // MainHow,
      MainFooter,
      MinerDetails,
      MinersComparison,
      CoinTicker
    },
    computed:{
      rules(){
        return this.$store.getters['rules'];
      }
    },
    methods:{
      slideTo(data){
          if(this.$refs && this.$refs[data])
            this.$refs[data].$el.scrollIntoView({behavior: 'smooth'});
      },
      async coinsApi(){
        

        let server = this.$store.getters['server'];
        
        await this.$http.get(server + '/finance/crypto-listing').then(response => {
          if(response.data.status == "success")
             this.coins = Object.entries(response.data.listings).map(([coin, data]) => ({ cost:0,profit:0,income:0,coin, ...data }));
          
        })
        this.load = false;
      },
      accessOpen(dmail){
        if(dmail)
          return this.subscribe(dmail)
        

        this.slideTo('hero');

        if(this.$store.getters['isWhitelisted'])
          return;

        
        this.dialog = true;
      },
      async subscribe(dmail){
            if(dmail || this.$refs.form.validate()){
                
                let server = this.$store.getters['server'];

                this.email = typeof dmail === 'object' ? this.email : dmail;                  
                
                let data = {email: this.email}
                
                this.loadAccess = true;

                await this.$http.post(server + '/user/subscribe',data).then(() => {
                  
                    this.$store.dispatch('setWhitelisted', true)
                    this.trackSubscriber();
                }).catch(() => {
                    this.alert.type = 'error';
                    this.alert.show = true;
                    this.alert.message = 'Something went wrong during subscribing!'
                })

                this.loadAccess = false;
                this.dialog = false;
                
                
                this.email = null;

                // if(!data)
                //   this.$refs.form.reset();
               
            }
        },
        trackSubscriber() {
            if (process.env.NODE_ENV === 'production'){
                this.$gtag.event('early_subscriber', {'send_to': 'AW-705764791/EEQrCNWL99wYELe7xNAC'})
            } else {
                console.log("skipping google tag");
            }
        },
    },
      async mounted(){
        this.load = true;
        
        await this.coinsApi();

        this.load = false;


        if(this.$route.query.to)
          this.slideTo(this.$route.query.to)

        
    },


  }
</script>
<style scoped>

.main-form.dialog-access h2{
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 48px;
    margin-bottom: 24px;
    /* font-weight: normal; */
}
.main-form.dialog-access p{
    font-size: 18px;
}

  .access-headline-custom{
    color: #5FF2FB;
  }
  .new-feature-container{
    margin-top: 100px;
    position: relative;
    z-index: 6;
  }
</style>
<style>
.homepage .navbar{
  background-color: transparent;
}
</style>