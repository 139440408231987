<template>
    <div class="coin-profitability d-block d-lg-flex align-center">
        <div class="cancel-coin ml-5 order-2 text-right pr-2"><v-btn text small @click="removeItem(coin.name)"><v-icon>mdi-close</v-icon></v-btn></div>
        <div class="profit-card my-2 d-flex justify-space-between align-center flex-wrap" >
            <div class="coin-details d-flex align-center profit-inf ">
                <div class="coin-details-img">
                    <img width="100%" :src="getImg(coin.slug)" alt="">
                </div>
                <div class="coin-text custom-mining ml-3">
                    <h5>{{ coin.coin }}</h5>
                    <h6>{{  priceRefactor(coin.mining,4) }}</h6>
                </div>
            </div>
            <div class="coin-cost profit-inf text-left text-md-center">
                <div class="coin-text text-center">
                    <h5>Monthly Cost</h5>
                    <h6>{{ priceRefactor(coin.cost,2) }}</h6>
                </div>
            </div>
            <div class="coin-income profit-inf ">
                        <div class="coin-text text-left text-md-center">
                            <h5>Monthly Income</h5>
                            <h6>{{ priceRefactor(coin.incomeUSD,2) }}</h6>
                        </div>
                    </div>
            <div class="coin-profit profit-inf text-center">
                <div class="coin-text">
                    <h5>Monthly Profit</h5>
                    <h6>{{ priceRefactor(coin.profit,2) }}</h6>
                </div>
            </div> 
    
        </div>
        
    </div>
</template>
<script>
export default{
    emits:['remove'],
    props:{
        coin:{
            type: Object,
            required: true
        }
    },
    methods:{
        getImg(img){
            return require(`@/assets/coins/${img}.png`)
        },
        removeItem(name){
            this.$emit('remove', name)
        },
        priceRefactor(price,to){

            var m = -Math.floor( Math.log10(price) + 1);

            if(price < 1 && m > 3)
                return price;
            
            return parseFloat(price).toFixed(to);
        }
    }
}
</script>
<style scoped>
.coin-details-img{
    max-width: 53px;
}
</style>
<style>
.profit-card{
    box-sizing: border-box;
    background: linear-gradient(270deg, rgba(115, 113, 255, 0.3) 0%, rgba(33, 36, 69, 0.3) 52.26%), #212445;
    padding: 24px 32px;
    border-radius: 12px;
    flex-direction: row;
    flex-grow: 1;
    row-gap: 20px;
    column-gap: 35px;
    
}
.profit-card > div{
    
    width: calc(25% - 35px);
}
.coin-text h5{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #FFF;
    opacity: 0.5;
    font-weight: normal;
}
.coin-details .coin-text{
    width: calc(100% - 53px) !important;
}
.coin-details .coin-text h6{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
} 
.coin-text h6{
    font-family: 'Girloy', sans-serif;
    font-size: 20px;
    color: #FFF;
    font-weight: normal;
    /* width: 100%; */
    overflow: hidden;
    text-overflow: ellipsis;
}
.coin-text h6::before{
    content:'$';
    font-family: 'Girloy', sans-serif;;
    font-size: 20px;
    margin-right: 4px;
}
.custom-mining h6::before{
    content: '' !important;
}
.coin-profit h6{
    color: #10F9C1;
}
@media (max-width: 960px) {
    .profit-card > div{
        width: calc(50% - 35px)
    }
}
</style>