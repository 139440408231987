<template>
    <div class="loading-page" v-if="load">
        <v-dialog
            :opacity="0.8"
            width="100%"
            v-model="load"
            max-width="512px"
            persistent
            content-class="loading-dialog"
            class="loading-overlay"
        >
        <div class="d-flex justify-center">
            <v-progress-circular
            :size="50"
            color="white"
            indeterminate
            ></v-progress-circular>
        </div>
    </v-dialog>
    </div>
</template>
<script>
export default{
    props:{
        loading:{
            type: Boolean,
            default: false,
            required: true,
        }
    },
    watch:{
        load(val){
            if(!val)
               document.querySelectorAll('.v-overlay').forEach(el => el.remove())
        }
    },
    computed:{
        load(){
            return this.loading
        }
    }
}
</script>