<template>
  <v-app :style="{'background-color': bgColor}">
    
    <v-main>
      <router-view :key="$route.fullPath"/>
    
    </v-main>
  </v-app>
</template>


<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

  computed:{
    bgColor(){
      return this.$route.meta.backgroundColor || '#121426';
    }
  },
  mounted(){
    // console.log(this.$route.meta.backgroundColor)
  }
  
};
</script>
<style>
h1,h2,h3{
  line-height: 1.2 !important;
}
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap');
@import url('@/assets/fonts/satoshi/satoshi.css');
@font-face {
  font-family: Aeonik;
  src: url("assets/fonts/AeonikTRIAL-Regular.otf");
}
@font-face {
  font-family: Gilroy;
  src: url("assets/fonts/Gilroy-SemiBold.ttf");
}
@font-face {
  font-family: DINNextW01-Regular;
  src: url("assets/fonts/DINNextW01Regular.otf");
}
@font-face {
  font-family: Gilroy-medium;
  src: url("assets/fonts/Gilroy-Medium.ttf");
}
.main-color{
  background-color: #10EBF9 !important;
  color: #141934 !important;
}
.main-font-color{
  color: #40EFFA !important;
}
@media (min-width: 1904px){
  .container {
    max-width: 1185px !important;
  }
}
.v-application{
  font-family: 'Inter',sans-serif !important;
}
.v-main__wrap::-webkit-scrollbar{
  display: none !important;
}
p{
  margin-bottom: 0px !important;
}
.v-select.fit {
  width: min-content;
}
.v-select.fit  .v-select__selection--comma {
    text-overflow: unset;
}

.main-btn{
    background-color: #10EBF9 !important;
    box-shadow: none !important;
    border-radius: 36px !important;
    color: #141934 !important;
    text-transform: none !important;
    font-family: 'Inter',sans-serif;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
    font-size: 16px !important;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.main-btn{
  background-color: #CCCBC8 !important;
  color:#FFF !important; 
}
.btn-mining{
  padding: 20px 0px !important;
  height: 64px !important;
  width: 209px !important;
  max-height: 64px !important;
  color: rgba(20, 25, 52, 1) !important;
  background: linear-gradient(270deg, #40EFFA -0.24%, #0BA7B1 99.76%);
  letter-spacing: 0px !important;
  border-radius: 31px !important;
  -moz-border-radius: 31px !important;
  -o-border-radius: 31px !important;
  -ms-border-radius: 31px !important;
  -webkit-border-radius: 31px !important;
  text-transform: none !important;
  font-size: 20px !important;
  box-shadow: 0 0 0 7px rgba(60, 243, 255, 0.1) !important;
}
.outline-btn{
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 36px !important;
  border: 1px solid #098189;
  color: #40EFFA !important;
  text-transform: none !important;
  font-family: 'Inter',sans-serif !important;
  /* font-weight: 500 !important; */
  letter-spacing: 0px !important;
  font-size: 14px !important;
}
.main-form{
  width: 100%;
  padding: 24px 32px;
  background-color: #1E213F;
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  border-radius: 16px;
  position: relative;
  z-index: 35;
}
.main-input .v-input__slot{
  background-color: #0E0F1D !important;
  border-radius: 12px !important;
  border: 2px solid #7371FF !important;
    
}
.main-input label{

  font-size: 16px !important;
  font-family: 'Inter',sans-serif !important;
  color: #6A6C82 !important;

}
.v-btn:not(.v-btn--round).v-size--default {
  height: auto !important;
  min-height: 36px;
}
.v-overlay {
  backdrop-filter: blur(5px);
}
.v-overlay .v-overlay__scrim{
  backdrop-filter: blur(5px);
}
.v-dialog.v-dialog--active{
  box-shadow: none !important;
}

/* Important for both - Withdraws and Add Payment  */
.v-dialog.payment-dialog{
  max-width: 500px !important;
}

.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto; 
  }
.loading-dialog{
  overflow-y: hidden !important;
  display: flex;
  justify-content: center;
}
a.main-route{
    color: #5FF2FB !important;
    text-decoration: none !important;
}
.copy-icon{
  cursor: pointer !important;
}
.v-text-field input{
  padding: 0px !important;
}
.mob-list-menu a{
  text-decoration: none !important;
  color: #FFF !important;
}

/* BUY SHOP */

</style>
