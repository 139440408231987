<template>
    <div class="miner-tables">
        <div class="header-miner-tables d-flex align-center justify-space-between">
            <h2>Miners</h2>
            <v-btn class="main-btn py-3" to="/shop"><span class="mr-2">Add new miner</span> <img src="@/assets/cube-outline.svg"></v-btn>
        </div>
        <div class="body-miner-table" v-if="miners.length">
          <v-simple-table class="transparent">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Hardware Name
                  </th>
                  <th class="text-left">
                    Location
                  </th>
                  <th class="text-left">
                    Status
                  </th>
                  <th class="text-left">
                    Coin
                  </th>
                  <th class="text-left">
                    Earning
                  </th>
                  <th class="text-left">
                    
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="item in filMiners"
                  :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>
                    <div class="miner-location">
                        <p>{{ item.location.city }}</p>
                        <p class="miner-location-region">{{ item.location.region }}</p>
                    </div>
                  </td>
                  <td>
                    <MinerStatus :status="item.status"></MinerStatus>
                  </td>
                  <td><MinerCoin :coin="item.coin"></MinerCoin></td>
                  <td>
                    <p class="miner-earning">{{ item.earning }}</p>
                  </td>
                  <td><v-icon color="#868E95">mdi-cog-outline</v-icon></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center mt-3">
              <Pagination @getPage="setPage" :size="pageSize"></Pagination>
          </div>
        </div>
        <div v-else>
          No Miners Found !
        </div>
    </div>
</template>
<script>
import MinerStatus from './MinerStatus.vue'
import MinerCoin from './MinerCoin.vue'
import Pagination from './Pagination.vue'
  export default {
    components:{Pagination,MinerStatus,MinerCoin},
    data () {
      return {
        page: 1,
      
        miners: [
            // {
            //     name: 'Hexageo Router 1', 
            //     location: {
            //         city: 'Trenton',
            //         region: 'New Jersey, US'
            //     },
            //     status: 'good',
            //     coin:{
            //         name: 'Bitcoin',
            //         img: 'bitcoin.png'
            //     },
            //     earning: 35
            // },
            // {
            //     name: 'Red Hexageo 2', 
            //     location: {
            //         city: 'Trenton',
            //         region: 'New Jersey, US'
            //     },
            //     status: 'Reboot Needed',
            //     coin:{
            //         name: 'Litecoin',
            //         img: 'litecoin.png'
            //     },
            //     earning: 38
            // },
            // {
            //     name: 'Hexageo Router 2', 
            //     location: {
            //         city: 'Trenton',
            //         region: 'New Jersey, US'
            //     },
            //     status: 'Reboot Needed',
            //     coin:{
            //         name: 'Dash',
            //         img: 'dash.png'
            //     },
            //     earning: 44
            // },
            // {
            //     name: 'Red Hexageo 1', 
            //     location: {
            //         city: 'Trenton',
            //         region: 'New Jersey, US'
            //     },
            //     status: 'good',
            //     coin:{
            //         name: 'DogeCoin',
            //         img: 'dogecoin.png'
            //     },
            //     earning: 47
            // },
            // {
            //     name: 'Red Hexageo 1', 
            //     location: {
            //         city: 'Trenton',
            //         region: 'New Jersey, US'
            //     },
            //     status: 'good',
            //     coin:{
            //         name: 'DogeCoin',
            //         img: 'dogecoin.png'
            //     },
            //     earning: 47
            // },
            // {
            //     name: 'Red Hexageo 1', 
            //     location: {
            //         city: 'Trenton',
            //         region: 'New Jersey, US'
            //     },
            //     status: 'good',
            //     coin:{
            //         name: 'DogeCoin',
            //         img: 'dogecoin.png'
            //     },
            //     earning: 47
            // },
            // {
            //     name: 'Red Hexageo 1', 
            //     location: {
            //         city: 'Trenton',
            //         region: 'New Jersey, US'
            //     },
            //     status: 'good',
            //     coin:{
            //         name: 'DogeCoin',
            //         img: 'dogecoin.png'
            //     },
            //     earning: 47
            // },
            
         
        ],
        perPage: 2
      }
    },
    computed:{
      pageSize(){
        if(!this.miners.length)
          return 0;

        return Math.ceil(this.miners.length / this.perPage) ?? 1;
      },
      filMiners(){
        let start = (this.page-1) * this.perPage;
        let end = start + this.perPage;
        return this.miners.slice(start, end );
      }
      
    },  
    methods:{
      setPage(data){
        this.page = data
      }
    }
  }
</script>
<style>
.v-pagination > li:first-child,
.v-pagination > li:last-child {
  display: none;
}
.miner-tables .v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}
.miner-tables .body-miner-table{
  background-color: #2B2E54;
  padding-bottom: 18px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
/* Hide scrollbar for IE, Edge and Firefox */
.miner-tables .v-data-table__wrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.miner-tables .v-data-table table{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #2B2E54;
    font-family: 'Inter',sans-serif !important; 
    border-bottom: 1px solid #323665;
}
.miner-tables .v-data-table .v-data-table__wrapper, 
.miner-tables .v-data-table table thead{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.miner-tables .v-data-table table thead tr th{
    font-size: 10px;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    color: #999AA9 !important;
}
.miner-tables .v-data-table table tbody tr td{
    font-size: 13px !important;
}
.v-data-table{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.miner-tables .v-data-table table thead{
    background-color: #171A31;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.miner-tables .v-data-table table tbody tr td{
    white-space: nowrap !important;
}
.miner-tables .v-data-table table tbody tr td .miner-location-region{
    color: #999AA9;
}
.miner-tables .v-data-table table tbody tr td .miner-earning{
    font-size: 14px !important;
}
.miner-tables .v-data-table table tbody tr td .miner-earning::before{
    content: '$';
    margin-right: 3px;
}
.header-miner-tables{
  margin-top: 45px;
  margin-bottom: 25px;
}

.miner-tables .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #383b68;
}

</style>