<template>  
    <v-main class="dashboard">
        <Loading :loading="load"></Loading>
        <dash-navbar></dash-navbar>
        <div class="bg-stars">
            <BackgroundStars></BackgroundStars>
            <Checkout v-if="item" :item="item"></Checkout>
        </div>
        
    </v-main>    
</template>
<script>
import BackgroundStars from '@/components/Basics/BackgroundStars.vue'
import DashNavbar from '@/components/Dashboard/Basics/DashNavbar.vue'
import Checkout from '@/components/Dashboard/Shop/Checkout.vue';
import Loading from '@/components/Dashboard/Basics/Loading.vue';
export default{
        components:{DashNavbar,BackgroundStars,Checkout,
            Loading
        },
        data(){
            return{
                item: null,
                load:true
            }
        },
        methods:{
            async getItem(){
                
                let server = this.$store.getters['server'];
                
                let id = this.$route.params.id;

                await this.$http.get(server + '/shipping/products/all').then(response => {
                    this.item = response.data.find(element => {
                        return element.id == id && element.in_stock
                    })
                    if(!this.item)
                        return this.$router.push({name: 'Shop'});
                    
                }).catch(() => {
                    return this.$router.push({name: 'shop'});
                })
                
            },
            payItem(){

            }
        },
        async created(){
            this.load = true;

            await this.getItem();

            this.load = false;
        }
    }
</script>
<style>
    .bg-stars{
        position: relative;
        height: 100%;
        overflow: hidden;
    }
    .dashboard{
        min-height: 100vh;
        overflow: hidden;
        background:  radial-gradient(93.85% 93.85% at 50% 11.72%, #0D1116 46.2%, #300E5B 85.08%, #7371FF 100%), #0D1116;
    }
    .dashboard .bg-animation{
        height: 0px !important;
    }
    .shop-container{
        padding: 32px 24px;
        background-color: #1E213F;
        border-radius: 16px;
        min-height: 500px;
        position: relative;
    }
</style>