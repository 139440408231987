<template>  
    <v-main class="dashboard">
        <dash-navbar></dash-navbar>
        <div class="bg-stars">
            <BackgroundStars></BackgroundStars>
            <MainShop></MainShop>
        </div>
        
    </v-main>    
</template>
<script>
import BackgroundStars from '@/components/Basics/BackgroundStars.vue'
import DashNavbar from '@/components/Dashboard/Basics/DashNavbar.vue'
import MainShop from '@/components/Dashboard/Shop/MainShop.vue'
    export default{
        components:{DashNavbar,BackgroundStars,MainShop},
        data(){
            return{
                
            }
        },
    }
</script>
<style>
    .bg-stars{
        position: relative;
        height: 100%;
        overflow: hidden;
    }
    .dashboard{
        min-height: 100vh;
        overflow: hidden;
        background:  radial-gradient(93.85% 93.85% at 50% 11.72%, #0D1116 46.2%, #300E5B 85.08%, #7371FF 100%), #0D1116;
    }
    .dashboard .bg-animation{
        height: 0px !important;
    }
    .shop-container{
        padding: 32px 24px;
        background-color: #1E213F;
        border-radius: 16px;
        min-height: 500px;
        position: relative;
    }
</style>