<template>
    <div class="wrap-shop-item">
        <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
          {{ alert.message }}
          <template v-slot:action="{ attrs }">
              <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
          </template>
        </v-snackbar>

        <Loading :loading="loading"></Loading>
        <v-dialog
          :opacity="0.8"
          v-model="dialog"
            width="100%"
            max-width="512px"
            content-class="contact-dialog"
           
        >
        <div class="main-form">
            <h2>Generate new payment</h2>
            <p class="mt-10">Are you sure you want to generate new payment link?</p>
            <div class="actions d-flex justify-space-between mt-10">
                <v-btn class="outline-btn mr-1" @click="dialog = false; id = null;">Cancle</v-btn>
                <v-btn class="main-btn ml-1" @click="generatePayment()">Yes, I am sure</v-btn>
            </div>
        </div>
    </v-dialog>

        <div class="shop-item-container mb-7" >
            <h2 class="shop-item-title mb-6">Orders</h2>
            

            <OrdersTable @handle="orderStatus" v-if="!loading && orders" :orders="orders"></OrdersTable>
            <p class="font-weight-regular" v-else-if="!loading">No orders found !</p>
        </div>
        <div class="shop-item-container">
            <h2 class="shop-item-title">Shop</h2>
            <v-row class="d-flex">
                <v-col cols="12" lg="4" class="pa-4">
                    <div class="shop-item">
                        <div class="shop-item-pic">
                            <img width="100%"  :src="getImg(img)" alt="Router">
                        </div>
                        <div class="shop-item-alt-imgs">
                            <div class="alt-img" :class="{active: img == 'miner-01.jpg'}" @click="setImg('miner-01.jpg')">
                                <img height="100%" src="@/assets/products/miner-01.jpg" alt="Router-Img">
                            </div>
                            <div class="alt-img" :class="{active: img == 'miner-02.jpg'}"  @click="setImg('miner-02.jpg')">
                                <img height="100%%" src="@/assets/products/miner-02.jpg" alt="Router-Img">
                            </div>
                            <div class="alt-img" :class="{active: img == 'miner-03.jpg'}" @click="setImg('miner-03.jpg')">
                                <img height="100%" src="@/assets/products/miner-03.jpg" alt="Router-Img">
                            </div>
    
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" lg="8" class="pa-4">
                    <div class="select-miner-model mb-4">
                        <label for="miner-model" class="mb-2 d-block">Select Your Model</label>
                        <v-select  v-if="miners.length" solo v-model="selectedMiner" item-text="name" item-value="id" id="miner-model" return-object :items="miners" label="Select Your Miner Model" hide-details="true" :menu-props="{ maxWidth:'600px' ,bottom: true, offsetY: true, closeOnClick: true,'content-class': 'main-select-menu custom-menu', attach: '#miner-model'}" class="main-input select-model" rounded dense>
                            <template slot="item" slot-scope="{item}">
                                <v-list-item-content>
                                    <v-list-item-title class="d-flex justify-center">
                                        <div class="buy-miner-model-breif">
                                            <h3 class="font-weight-regular  my-4">{{ `${item.name} - ${item.sim_capacity} SIM Capacity` }}</h3>
                                        </div>
                                    </v-list-item-title>
                            </v-list-item-content>
                            </template>
                            <template slot="selection" slot-scope="{item}">
                                <div class="miner-selection">
                                    {{ `${item.name} - ${item.sim_capacity} SIM Capacity` }}
                                </div>
                            </template>
                        </v-select>
                    </div>
                    <div class="shop-item-details" v-if="selectedMiner">
                        <h2 class="shop-item-name mb-4">{{ selectedMiner.name }}</h2>
                        <p>{{ selectedMiner.description }}</p>
                    </div>
                    <div class="shop-item-action my-4">
                        
                        <v-btn class="main-btn text-capitalize pa-4 mt-4 mb-8 mt-8" v-if="selectedMiner" :disabled="!selectedMiner.in_stock" :to="'shop/'+selectedMiner.id">{{selectedMiner.in_stock ? 'Start earning - buy now' : 'Not in stock'}}</v-btn>
                    </div>
                    <div class="shop-item-features">
                            
                        <div class="feature d-flex">
                            <div class="step-line"></div>
                            <div class="feature-content">
                                <h3>Heat</h3>
                                <p>Cool operation, no excessive heat.</p>
                            </div>
                        </div>
                        <div class="feature d-flex">
                            <div class="step-line"></div>
                            <div class="feature-content">
                                <h3>Power</h3>
                                <p>Efficient, low energy usage.</p>
                            </div>
                        </div>
                        <div class="feature d-flex">
                            <div class="step-line"></div>
                            <div class="feature-content">
                                <h3>Noise</h3>
                                <p>Silent, no disruptive noise.</p>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
            
        </div>
    </div>
</template>
<script>
import OrdersTable from './OrdersTable.vue'
import Loading from '@/components/Dashboard/Basics/Loading.vue'
export default{
    emits:['nextStep'],
    components:{OrdersTable, Loading},
    data(){
        return{
            img: 'miner-01.jpg',
            id: null,
            orders: null,
            dialog: false,
            loading: false,
            alert:{
                show:false,
                type: null,
                message: null
            },
            miners: [], 
            selectedMiner: null
        }
    },
    methods:{
        setImg(img){
            this.img = img;
        },
        getImg(img){
            return require(`@/assets/products/${img}`)
        },
        buyItem(){
            this.$router.push({name: 'ShopItem', params:{id: 1}})
            // this.$emit('nextStep', 'Checkout');
        },
        async getProducts(){
            let server = this.$store.getters['server'];

            await this.$http.get(server + '/shipping/products/all').then(response => {
                
                this.miners = response.data

            }).catch(() => {
                this.alert.type = 'error';
                this.alert.message = 'Something went wrong!';
                this.alert.show = true
            })
        },
        async getOrders(){

            let server = this.$store.getters['server'];

            await this.$http.get(server + '/shipping/orders/all').then(response => {
                let orders = response.data;

                orders = orders.forEach((order,index) => {
                    let date = new Date(order.created_at);
                
                    let month = date.toLocaleDateString('default', {month: 'short'});
                    orders[index].date = `${date.getDate()}. ${month} ${date.getFullYear()}.`

                    this.orders = orders;

                })
            }).catch(() => {
                this.alert.type = 'error';
                this.alert.message = 'Something went wrong!';
                this.alert.show = true
            })
        },
        orderStatus(id,status){
            status
            return this.$router.push({name: 'Orders', params: {id} })
                
        },
        generatePayment(){
            if(this.id)
                window.location.href = '/shop/orders/' + this.id
        }
    
        
    },
    async created(){
        this.loading = true;

        await this.getOrders();
        await this.getProducts();
    
        this.selectedMiner = this.miners[1]

        this.loading = false;

        this.$store.dispatch('resetPage')
    }

    
    
}
</script>
<style scoped>
.main-btn .v-btn--disabled{
    background-color: #EEE !important;
}
.feature .feature-content h3{
    margin-bottom: 4px;
}
.feature .feature-content p{
    margin-bottom: 48px !important;
}
.feature .step-line::after{
    min-height: 100px !important;
    content: "";
    display: block;
    width: 5px;
    background-image: linear-gradient(#d16edc,rgba(209,110,220,0));
    margin: 0 auto;
}
.feature .step-line::before{
    display: block;
    border: 3px solid #f9c3ff;
    border-radius: 50%;
    content: "";
    width: 26.67px;
    height: 26.67px;
    background-color: #0e0f1d;
    margin: 0 auto;
    box-shadow: 0 0 32px #f9c3ff;
}
.feature:last-child .step-line::after{
    display: none;
}
.feature .feature-content h3{
    font-family: 'Gilroy', sans-serif;
    font-size: 18px !important;
    color: #E9E9EC;
}
.feature .feature-content p{
    color: #BABBC5;
    max-width: 284px;
}
.main-form h2{
    font-family: 'Gilroy-medium', sans-serif;
    font-size: 28px !important;
    font-weight: normal;
}
.main-form p{
    font-size: 18px !important;
}
.main-form button{
    flex-grow: 1;
    flex-basis: 0;
    padding: 10px 16px;
    font-size: 14px !important;
}
@media (min-width: 1264px){
    .feature .feature-content p{
        max-width: 436px !important;
    }
}

</style>
<style>
.shop-item-action label{
    font-family: 'Gilroy-Medium', sans-serif;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 8px;
    display: block;
}
.shop-item-action .miner-breif{
    max-width: 800px !important;
}
.main-input.select-model .v-input__control{
    min-height: 60px !important;
}
.feature .step-line{
    margin-right: 32px !important;
}
.shop-item-container{
    background-color: #1E213F;        
    padding: 32px 24px;
    border-radius: 16px;
    position: relative;
}
.shop-content{
    margin-top: 66px;
}

.shop-item-pic{
    background-size: cover;
    background-position: top center;
    min-height: 380px;
    display: flex;
    justify-content: center;
    align-content: center;
}
.shop-item-pic img{
    object-fit: contain;
    max-width: 320px;
}
.shop-item-alt-imgs{
    display: flex;
    width: 100%;
    max-width: 320px;
    justify-content: space-between;
    margin: 0px auto;
}

.shop-item-alt-imgs .alt-img{
    width: 32%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    background-color: #FFF;
    border-radius: 4px;
    cursor: pointer;
}
.shop-item-alt-imgs .alt-img.active{
    border: 2px solid #11DBE9;
}
.shop-item-container .shop-item-title{
    font-weight: 400;
    font-size: 18px;
}
.shop-item-container{
    max-width: 575.9px;
    margin: 0px auto;
}
.shop-item-details h2{
    font-family: 'Gilroy',sans-serif;
    font-size: 28px;
    
}
@media (min-width: 1264px){
    .shop-item-container{
        max-width: 100%;
        margin: 0px auto;
    }
}
/* max-width: 575.99px;
    margin: 0px auto; */
</style>