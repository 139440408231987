<template>
    <v-container class="track-earning-container">
        <div class="track-earning d-flex justify-center flex-column">
            <h2>Intuitive Experience to Track Your Earnings</h2>
            <div class="track-earning-action text-center ">
                <v-btn class="btn-mining mt-6" @click="getAccess">Join Waitlist</v-btn>
            </div>
            <div class="track-overview">
                <img width="100%" src="@/assets/58701f3a947ccb63127cf4c3b6681988.png" alt="Overview">
            </div>
        </div>
    </v-container>
</template>
<style scoped>
    .track-earning-container{
        margin-top: 140px;
    }
    .track-earning h2{
        font-family: 'Gilroy-medium', sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 64px;
        letter-spacing: -1.2px;
        background: radial-gradient(51.88% 5897.7% at 50.06% 66.25%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 600px;
    }
    .btn-mining{
        width: auto !important;
        padding: 20px 32px !important;
    }
    .track-overview{
        background-color: #0E0F1D;
        padding: 8px;
        margin-top: 85px;
        box-shadow: 0px 0px 122px 67px rgba(48,47,107,0.71);
        -webkit-box-shadow:0px 0px 122px 67px rgba(48,47,107,0.71);
        -moz-box-shadow: 0px 0px 122px 67px rgba(48,47,107,0.71);
    }
    .track-earning img{
        border-radius: 34px;
    }
    @media(min-width: 600px){
        .track-earning h2{
            font-size: 52px !important;
        }
    }
</style>
<script>
export default{
    emits:['access'],
    methods:{
        getAccess(){
            return this.$emit('access')
        }
    }
}
</script>