<template>
    <div class="custom-range" id="custom-range">
        <input type="range" :style="bgValue" v-model="cost" class="range">
        <div class="custom-range-hints">
            <div class="hint-bar" v-for="i in 10" :key="i"></div>
        </div>
        <div class="handle-cursor d-flex">
            <span class="cursor-val d-inline-block" :style="cursorVal">{{monthlyCost}}</span>
        </div>
    </div>
</template>
<script>
    export default{
        emits:['cost'],
        data(){
            return{
                cost: 30,
            }
        },
        watch:{
            cost(){
                this.$emit('cost', this.monthlyCost)
            }
        },
        computed:{
            bgValue(){
                return `background:linear-gradient(to right, #10EBF9 0%, rgba(162, 249, 255, 0.81) ${this.cost}%, #000 ${this.cost}%,#000 100%);`
            },
            cursorVal(){
                if(this.$vuetify.breakpoint.name == 'xs' && Number.parseInt(this.cost - 1) >= 95)
                    return `left: 94%`;  
                
                return `left: ${Number.parseInt(this.cost - 1)}%`;  
            },
            monthlyCost(){

                return Number.parseInt(this.cost) * 1;


            }
        },
    }

    
</script>

<style>
    .custom-range{
        width: 95%;
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px !important;
        margin-bottom: 12px;
    }
    .custom-range .range{
        width: 100%;
        appearance: none;
        -webkit-appearance: none;
        height: 12px;
        border-radius: 9px;
    }
    .custom-range .range::-webkit-slider-thumb{
        appearance: none;
        -webkit-appearance: none;
        width: 30px;
        height: 30px;
        background-color: #FFF;
        border-radius: 50%;
        border: 3px solid #10EBF9;
        box-sizing: border-box;
       
    }
    .custom-range-hints{
        display: flex;
        justify-content: space-around;
        margin-top: 5px;
        padding: 0px 15px;
    }
    .custom-range-hints .hint-bar{
        width: 1.5px;
        height: 12px;
        background-color: #4D506A;
    }
    .cursor-val{
        position: relative;
        font-family: 'Inter',sans-serif;
        margin-top: 8px;
    }
    /* .cursor-val::before{
        content: '$';
    } */
    .cursor-val::after{
        content: ' $'
    }
    .handle-cursor{
        padding: 0px 15px;
    }
</style>