<template>
    <div class="miner-status-container" :style="stateStyle">
        <div class="miner-status-content d-flex align-center">
            <span>{{ status }}</span>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        status:{
            type: String,
            required: true,
            default: 'reboot needed'
        }
    },
    computed:{
        stateStyle(){
            if(this.status.toLocaleLowerCase() == 'good')
                return {color: '#0DBE74', 'border-color': "#00713E"}
            else
                return {color: '#E4760F', 'borde-color': '#774700'}
        },
    },
    mounted(){
    }
}
</script>
<style>
    .miner-status-container{
        border-width: 2px;
        border-style: solid;
        display: inline-block;
        padding: 2px 8px 2px 6px;
        border-radius: 16px;
    }
.miner-status-content{
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 500;
}
.miner-status-content::before{
    content: '•';
    margin-right: 5px;

}
</style>
