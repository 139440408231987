<template>
    <div class="payment-methods">
        <v-form @click.prevent="addMethod" ref="form">
            <!-- <v-radio-group v-model="selectedMethod"> -->
                <div class="crypto-method">
                    <!-- <div class="method-title">
                    <v-radio
                        label="Cryptocurrency (USDT ERC20, USDT TRC20, ...)"
                        :value="1"
                        color="#10EBF9"
                        ></v-radio>
                    </div> -->
                    <label class="form-label">Please provide your USDT wallet address</label>
                    <div class="method-body">
                        <v-select :rules="rules.required" solo label="Select Network" v-model="wallet.network" :items="networks" class="main-input" :menu-props="{ maxWidth:'600px' ,bottom: true, offsetY: true, closeOnClick: true,'content-class': 'main-select-menu custom-menu'}" rounded dense></v-select>
                        <v-text-field validate-on-blur :rules="[...rules.required]"  v-model="wallet.public_key" class="main-input" solo rounded label="USDT wallet address" dense></v-text-field>
                    </div>
                </div>
                <!-- Working only on USDT for now -->
                <!-- <div class="crypto-method">
                    <div class="method-title d-flex justify-space-between">
                        <v-radio color="#10EBF9" label="Paypal" :value="2"></v-radio>
                    </div>
                    <div class="method-body" v-if="selectedMethod == 2">
                        <v-text-field solo class="main-input" rounded label="Type your paypal address *" dense></v-text-field>
                    </div>
                </div>
                <div class="crypto-method">
                    <v-radio color="#10EBF9" label="Bank Wire" :value="3" ></v-radio>
                    
                    <div class="method-body" v-if="selectedMethod == 3">
                        <v-select  solo label="Select Your bank *" class="main-input" rounded dense>
                            <template slot="prepend-inner">
                                <v-icon color="#4D506A">mdi-bank</v-icon>
                            </template>
                        </v-select>
                        <v-text-field  solo class="main-input" rounded label="Type your bank IBAN *" dense></v-text-field>
                    </div>
                </div> -->
            <!-- </v-radio-group> -->
            <v-btn class="main-btn add-btn" :loading="loading" :disabled="loading" @click="addMethod" block>Add</v-btn>
        </v-form>
    </div>
</template>
<script>
    export default{
        emits:['finish', 'load'],
        data(){
            return{
                // selectedMethod: 1,
                networks: ['ERC20', 'TRC20','BEP20', 'SOL'],
                wallet:{
                    network: 'ERC20',
                    public_key: null
                },
                loading: false
                
            }
        },
        methods:{
            async addMethod(){
                if(this.$refs.form.validate()){


                    this.loading = true;
                    this.$emit('load')

                    let server = this.$store.getters['server'];
                    
                    this.wallet.currency = 'USDT';
                    
                    await this.$http.post(server + '/user/wallet', this.wallet).then(response => {
                    
                    this.$emit('finish', response);

                    }).catch((err) => {
                        this.$emit('finish', err.response)
                    })
                    
                    this.loading = false
                    this.$refs.form.reset()

                    

                }
            }
        },   
        computed:{
            rules(){
                return {required: this.$store.getters['rules'].requiredRules, string: this.$store.getters['rules'].stringRule} 
            }
        }
    }
</script>
<style scoped>
    .crypto-method .method-title{
        margin-bottom: 10px;
    }
    .payment-methods form .crypto-method .form-label {
        font-size: 14px !important; 
        font-family: 'Inter',sans-serif !important;
        margin-bottom: 20px;
        display: block;
        color: #E9E9EC;
    }

    .payment-methods .method-body .v-input__slot input{
        padding: 0px !important;
    }
</style>
<style>
    .payment-methods .method-body .v-input__slot{
        padding-top: 16px;
        padding-bottom: 16px;
        max-height: 56px;
}</style>