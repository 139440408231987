<template>
    <div class="pagination">
        <ul class="pagination-list d-flex justify-space-between align-center mx-3">
            <li class="pagination-previous"><v-btn :disabled="current == 1" @click="previousPage()" :class="[current > 1 ? 'active-action' : 'main-pag-btn']" class="main-pag-btn mr-1"><v-icon small class="mr-2">mdi-arrow-left</v-icon>Previous</v-btn></li>
                <li class="v-pagination-items">
                    <v-pagination :length="size" total-visible="7" v-model="current"></v-pagination>
                </li>
            <li class="pagination-next"><v-btn @click="nextPage()" :disabled="current >= size" :class="[current >= size ? 'main-pag-btn' : 'active-action']" class="main-pag-btn ml-1">Next<v-icon small class="ml-2">mdi-arrow-right</v-icon></v-btn></li>
        </ul>
    </div>
</template>
<script>
export default{
    emits:['getPage'],
    props:{
        size:{
            required: true,
            type: Number
        }
    },
    data(){
        return{
            current: 1,
        }
    },
    watch:{
        current(newVal){
            this.$emit('getPage', newVal)
        }
    },
    methods:{
        nextPage(){
            if(this.current+1 <= this.size)
                this.current += 1
        },
        previousPage(){
            if(this.current -1 >= 1){
                this.current -= 1
            }
        }
    }
}
</script>
<style>
.main-pag-btn{
    height: auto !important;
    padding: 14px 14px !important;
    border-radius: 36px !important;
    background-color: #344054 !important;
    text-transform: none !important;
    font-family: 'Inter',sans-serif !important;
    font-size: 14px !important;
}
.pagination{
    overflow-x: auto;
}
.pagination::-webkit-scrollbar {
  display: none;
}
.pagination .v-pagination-items{
    flex-grow: 100;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pagination {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.pagination ul{
    list-style: none;
    padding: 0px;
}
.pagination-list .pagination-items li:not(:last-child){
    margin-right: 10px;
}
/* .pagination-list .pagination-items li button{
    background-color: transparent !important;
    box-shadow: none !important;
    min-width: auto !important;
    padding: 0px !important;
    border-radius: 8px !important;
    min-width: 40px !important; 
    min-height: 40px !important;

} */

.v-pagination li button{
    background-color: transparent !important;
    box-shadow: none !important;
    min-width: auto !important;
    padding: 0px !important;
    border-radius: 8px !important;
    min-width: 40px !important; 
    min-height: 40px !important;
}
.v-pagination li button.v-pagination__item--active{
    background: linear-gradient(45deg, #344054 0%, #475467 100%) !important;
    color: #FFF !important;
}
.pagination-list .active-action{
    background-color: transparent !important;
    box-shadow: none !important;
    border: 1px solid #098189;
}
.pagination-list .pagination-items button{
    color: #475467;
}
</style>