<template>
    <div class="help-center-container">
        <Loading :loading="loading"></Loading>
        <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
            {{ alert.message }}
            <template v-slot:action="{ attrs }">
                <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
            </template>
        </v-snackbar>

        <v-container>
            <div class="help-content">
                <h2 class="help-center-title">Help Center</h2>
                <FaqComponent></FaqComponent>
                <div class="help-contact-form">
                    <h2>Contact us</h2>
                    <ContactForm :loading="loading" @contact="contact"></ContactForm>
                </div>
            </div>
        </v-container>
    </div>
</template>
<script>
import FaqComponent from '@/components/Basics/FaqComponent.vue';
import ContactForm from '@/components/Basics/ContactForm.vue'
import Loading from '@/components/Dashboard/Basics/Loading.vue'
    export default{
        components:{FaqComponent,ContactForm,Loading},
        data(){
            return{
                loading: false,
                alert:{
                    show: false,
                    type: null,
                    message: null
                },
            }
        },
        methods:{
            async contact(data){

                let server = this.$store.getters['server'];
                
                this.loading = true;

                await this.$http.post(server + '/user/contact', data).then(() => {

                    this.alert.message = 'We have received your message and will respond promptly !'
                    this.alert.type = 'success'
                }).catch(() => {
                    this.alert.message = 'Unknown error occurred!'
                    this.alert.type = 'error'
                })
            

                this.alert.show = true;
                this.loading = false;
                
                
                this.$refs.form.reset();
            }
        }
}
</script>
<style>                                                                             
.help-contact-form h2{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 24px;
    margin-top: 48px;   
}
.help-content .main-faq-temp .v-expansion-panel-header{
    padding-left: 0px !important;
}
.help-content .v-expansion-panel-content__wrap{
    padding-left: 0px !important;
}
.help-center-container .help-content{
    background-color: #1E213F;
    min-height: 800px;
    padding: 32px 24px;
    border-radius: 16px;
    position: relative;
    max-width: 768px;
    margin: 0px auto;
}
.help-center-title{
    font-weight: 400;
    font-size: 18px;
}
.help-center-container{ 
    margin-top: 66px !important;
}
.help-content .main-faq-temp{
    margin-top: 24px !important;
}
</style>
