<template>
    
    <div class="content-container account-balance">
    
    <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" bottom right timeout="2000" transition="scroll-x-transition">
        {{ alert.message }}
        <template v-slot:action="{ attrs }">
            <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
        </template>
    </v-snackbar>
    <div class="text-center">
        <v-dialog :opacity="0.6" v-model="dialog" width="100%" max-width="768px" persistent content-class="payment-dialog">
            <div class="main-form add-wallet-form">
                <v-btn v-if="!loadRequest" color="transparent" class="d-flex ml-auto close-btn" rounded small @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                <h5>Withdrawal</h5>
                <PaymentWithdraws :wallets="wallets" @load="loadRequest = true" @finish="finishRequest"></PaymentWithdraws>
            </div>
        </v-dialog>
    </div>

            <div class="account-balance-header d-flex align-start justify-space-between">
                <h2>Total Balance</h2>
                <div class="selected-coins">
                    <v-select class="fit select-coin-input d-flex align-center" hide-details="true" return-object item-text="name" :menu-props="{ bottom: true, offsetY: true, closeOnClick: true,'content-class': 'dash-menu'}"   :items="coins" v-model="selectedCoin">
                        <template #prepend-inner  v-if="selectedCoin">
                            <img width="20px" :src="getImg(selectedCoin.icon)" alt="Coin">
                        </template> 
                    </v-select>
                </div>
            </div>
            <div class="account-balance-number">
                <p>0.00</p>
            </div>
            <div class="account-analysis-profit d-flex mt-3">
                <div class="account-profit-num d-flex align-center mr-3">
                    <v-icon small color="#37D0B4" class="percent-icon mr-1">mdi-arrow-top-right</v-icon>
                    <p class="analysis-account-precentage up">+0%</p>
                </div>
                <!-- <p class="account-anlysis-text">Increase this Week</p> -->
                <p class="account-anlysis-text">--</p>
            </div>
            <div class="account-withdraw-container">
                <v-btn class="main-btn mt-6" block @click="withdrawDialog">
                    <div class="withdraw-content d-flex align-center">
                        <span class="mr-2 py-3">Withdraw</span><img src="@/assets/credit-download.svg" alt="Credit">
                    </div>
                </v-btn>
            </div>
    </div>
</template>
<script>
import PaymentWithdraws from './PaymentWithdraws.vue'
    export default{
        components: {PaymentWithdraws},
        data(){
            return{
                coins:[
                    {name: 'BTC', icon: 'bitcoin.png'},
                    {name: 'DASH', icon: 'dash.png'},
                    {name: 'DOGE', icon: 'dogecoin.png'},
                    {name: 'LTC', icon: 'litecoin.png'},
                ],
                selectedCoin: {name: 'BTC', icon: 'bitcoin.png'},
                dialog: false,
                alert:{
                    message: null,
                    type: null,
                    show: false,
                },
                loadRequest: false,
            }
        },
        computed:{
            wallets(){
                return this.$store.getters['wallets'];
            }
        },
        methods:{
            getImg(img){
                return require(`@/assets/coins/${img}`)
            },
            async finishRequest(response){
                this.alert.type = response.status == 201 ? 'success' : 'error';
                this.alert.message = response.data.message ?? response.statusText;
                this.alert.show = true;
                this.addDialog = false;
                this.dialog = false;
                this.loadRequest = false

            },
            withdrawDialog(){
                if(!this.wallets.length)
                    return this.alert = {message: "Please add a wallet before making a withdrawal.", type: 'error', show:true}
                
                return this.dialog = true;
            }
        }
    }
</script>

<style>
.selected-coins .v-input__prepend-inner{
    margin-top: 6px !important;
} 
.account-balance .v-input__slot::before {
  border-style: none !important;
}
.account-balance-header .v-input{
    margin-top: 0px;
    padding-top: 0px;
}
.account-balance .select-coin-input .v-input__slot::before {
  border-style: none !important;
}
.account-balance .v-text-field > .v-input__control > .v-input__slot:after{
    border-style: none !important;
}
.account-balance .v-select.v-input--dense .v-select__selection--comma{
    color: #ACB5BB !important;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
}
.account-balance  .v-input__append-inner .v-input__icon i{
    color:#FFF !important;
}
.account-balance .account-balance-number p{
    margin: 0px;
    font-family: 'Gilroy', sans-serif;
    font-size: 42px;
}
.account-balance .account-balance-number p::before{
    content:'$'
}

.dashboard-content .analysis-account-precentage{
    font-family: 'Inter',sans-serif;
    color: #37D0B4;
}
.dashboard-content .analysis-account-precentage.up{
    color: #37D0B4;
}
.dashboard-content .percent-icon{
    color: #37D0B4;
}
.account-analysis-profit .account-anlysis-text{
    font-family: 'Inter',sans-serif;
    color: #B5F9FD;
}
</style>