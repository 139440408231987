L<template>
    <div class="contact-form">
        <v-form ref="form" @submit.prevent="contact">
            
            <v-row>
                <v-col cols="12">
                    <v-text-field :rules="[...rules.requiredRules, ...rules.minRules]" class="main-input" label="Subject" v-model="form.subject" hide-details="true" solo></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-textarea
                    class="main-input"
                    solo
                    name="input-7-1"
                    filled
                    label="Message"
                    :rules="[...rules.requiredRules, ...rules.minRules]"
                    no-resize
                    v-model="form.message"
                    
                    ></v-textarea>
    
                </v-col>
            </v-row>
            <v-btn :loading="loading" @click="contact" :disabled="loading" class="main-btn elevation-0 d-flex ml-auto">Send Message</v-btn>
        </v-form>
    </div>
</template>
<script>
export default{
    props:{
        loading: {
            required: true,
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            form: {
                subject: null,
                message: null
            },
        }
    },
    computed:{
        rules(){
            return this.$store.getters['rules'];
        }
    },
    methods:{
        async contact(){
            
            
            if(this.$refs.form.validate()){
                

                this.$emit('contact', this.form);

            }
            

        }
    }
}
</script>