<template>
    <div class="shop-content">
        <v-container>
            <transition name="component-fade" mode="out-in">
                <component v-if="order" @nextStep="changeStep" :item="order" transition="slide-y-transition" v-bind:is="selectedComponent"></component>
            </transition>
            
        </v-container>
    </div>
</template>
<script>
import PurchaseSuccess from './PurchaseSuccess.vue'
import ItemStatus from './ItemStatus.vue'
export default{
    components:{
        PurchaseSuccess,
         ItemStatus},
    data(){
        return{
            selectedComponent: null,
            order: null
        }
    },
    methods:{
        changeStep(item){
            this.selectedComponent = item
        },
        async orderDetails(id){
            

            let server = this.$store.getters['server'];

            let coins = this.$store.getters['coins']

            await this.$http.get(server + `/shipping/order/view/${id}`).then(response => {
                
                
                
                this.timeout = response.data.wallet_valid_for;
                this.order = response.data;
                this.order.id = this.$route.params.id
            

                if(this.order.currency == 'USDT')
                    this.order.img = 'tether.png';
                else{
                    let index = coins.findIndex(element => element.display.toLowerCase() == this.order.currency.toLowerCase() || element.name.toLowerCase() == this.order.currency.toLowerCase)
                    
                    this.order.img = index != -1 ? coins[index].img : 'default.png';
                }   

                if(this.order.charge_status && this.order.charge_status.toLowerCase() == 'pd')
                    this.selectedComponent = 'PurchaseSuccess'
                else if(this.order.charge_status)
                    this.selectedComponent = 'ItemStatus'
                else
                    window.location.href = '/shop'

            }).catch((err) => {
                if(err.response.status != 401)
                    this.$router.push({name: 'NotFound'})    
            })
        },
    },
    
    async mounted(){
        this.id = this.$route.params.id
    
        if(this.id)
            await this.orderDetails(this.id)
        else
            return this.$router.push({name: 'NotFound'})
            

    }
}
</script>

<style scoped>
</style>
<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .4s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

    @media (min-width: 1904px){
        .shop-content .container{
            max-width: 1340px !important;
        }
    }
</style>