<template>
    <div class="wrap-star">
        <div class="main-header">
        
            <v-container>
                <BackgroundStars></BackgroundStars>
                <div class="header-content miner-shop">
                    <div class="hex-header-content">
                        <div class="hexageo-ltc-img d-flex justify-center">
                            <img src="@/assets/coins/litecoin.png"  alt="Litecoin" width="57px">
                        </div>
                        <div class="content-text text-center">
                                <h1 class="text-center">Litecoin Mining</h1>
                                <p>Harness the Power of 5G Modems for High-Profitability Mining</p>
                            </div>
                            <div class="content-action mt-11 text-center">
                                <div class="hex-header-action">
                                    <v-btn class="btn-mining py-4 px-5" @click="access">Join Waitlist</v-btn>
                                </div>
                        </div>  
                    </div>
                    <div class="miner-cloud-feature">
                        <v-row class="d-flex align-center justify-center miner-cloud-row">
                            <v-col cols="10" lg="6" class="d-flex">
                                <div class="miner-cloud-text mb-10">
                                    <h2>Discover the<br>Potential of Litecoin</h2>
                                    <p class="subtitle">Join the Digital Currency Revolution</p>
                                    <p class="miner-text">Welcome to the world of Litecoin, a groundbreaking cryptocurrency that offers faster transaction times and enhanced security compared to traditional currencies. As one of the leading cryptocurrencies, Litecoin is gaining widespread acceptance in various industries, making it an attractive investment opportunity.</p>
                                    <v-btn class="outline-btn mt-8" @click="access">Join Wailist</v-btn>
                                </div>
                            </v-col>
                            <v-col cols="10" lg="6" class="d-flex justify-center justify-lg-end">
                                <div class="miner-cloud-bg">
                                    <img style="max-width:100%" src="@/assets/06ff14993c9dfacd0745c722ee3d22b2.png" alt="Assets">
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center justify-center miner-cloud-row">
                            <v-col cols="10" lg="6" class="d-flex">
                                <div class="miner-cloud-text mb-10">
                                    <h2>Revolutionize <br>Your Mining Experience</h2>
                                    <p class="subtitle">Say Goodbye to Expensive <br>Setups and Hello to High-Profit Mining</p>
                                    <p class="miner-text">Traditional cryptocurrency mining requires expensive equipment, substantial electricity consumption, and dedicated setups. However, with our innovative 5G modem solution, you can transform your mining experience. Our 5G modems offer a cost-effective and efficient way to mine Litecoin, eliminating the need for complex setups and reducing electricity costs significantly. Say goodbye to outdated mining methods and welcome a new era of profitability.</p>
                                    <v-btn class="outline-btn mt-8"  @click="access">Join Wailist</v-btn>
                                </div>
                            </v-col>
                            <v-col cols="10" lg="6" class="d-flex justify-center justify-lg-start order-lg-first">
                                <img style="max-width:100%" src="@/assets/326574c3212543510472205d6f8a589e.png" alt="Assets">
                            </v-col>
                        </v-row>
                    </div>

                    <div class="miner-details-features d-flex flex-column flex-lg-row">
                        <div class="miner-detail-feature">
                            <div class="miner-feature-img">
                                <div class="miner-feature-img-wrap">
                                    <img src="@/assets/zap.png" alt="Feature">
                                </div>
                            </div>
                            <div class="miner-feature-content text-center">
                                <p class="miner-feature-heading mb-2">Power</p>
                                <p>Our miner is designed to be highly energy-efficient, consuming less power compared to traditional mining equipment.</p>
                            </div>
                        </div>
                        <div class="miner-detail-feature">
                            <div class="miner-feature-img">
                                <div class="miner-feature-img-wrap">
                                    <img src="@/assets/thermometer-03.png" alt="Feature">
                                </div>
                            </div>
                            <div class="miner-feature-content text-center">
                                <p class="miner-feature-heading mb-2">Heat</p>
                                <p>Our miner produces such negligible heat that it operates without requiring a fan.</p>
                            </div>
                        </div>
                        <div class="miner-detail-feature">
                            <div class="miner-feature-img">
                                <div class="miner-feature-img-wrap">
                                    <img src="@/assets/noise-img.png" alt="Feature">
                                </div>
                            </div>
                            <div class="miner-feature-content text-center">
                                <p class="miner-feature-heading mb-2">Noise</p>
                                <p>Unlike mainstream miners that sound like a freight train, Hexageo miners run silently.</p>
                            </div>
                        </div>
                        <div class="miner-detail-feature">
                            <div class="miner-feature-img">
                                <div class="miner-feature-img-wrap">
                                    <img src="@/assets/bank-note-01.png" alt="Feature">
                                </div>
                            </div>
                            <div class="miner-feature-content text-center">
                                <p class="miner-feature-heading mb-2">USD</p>
                                <p>The miner is equipped with advanced cooling mechanisms and efficient heat dissipation technology.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </div>
    </div>
</template>
<script>
import BackgroundStars from '@/components/Basics/BackgroundStars.vue'
export default{
    emits:['access'],
    components:{
        BackgroundStars
    },
    methods:{
        access(){
            return this.$emit('access');
        }
    }

}
</script>
<style scoped>
.main-header{
    background: radial-gradient(103.09% 103.09% at 50.00% 7.14%, #231441 0.01%, #0D1116 54.17%, #300E5B 85.08%, #7371FF 100%), #0D1116;
    margin-top: 0px !important;
}
.main-header .container{
    background-image: url('@/assets/spiral.svg');
    background-position: top center;
}

.header-content{
            margin-top: 147px !important ;
    }
    .header-content .hex-header-content{
        margin-bottom: 100px;
    }
    .miner-cloud-row{
        margin-bottom: 140px !important;
    }
    .miner-feature-content p.miner-feature-heading{
        font-family: 'Gilroy-Medium', sans-serif;
        font-size: 28px;
        color: #E7FDFE;
    }
    .header-miner-features .miner-feature .miner-feature-title{
        font-family: 'Gilroy-medium', sans-serif;
        font-size: 20px;
        color: #F1F1FF !important;
    }
    .header-miner-features .miner-feature .miner-feature-text{
        color: #F1F1FF;
    }
    .miner-feature .miner-feature-icon{
        /* padding: 12px; */
        width: 40px;
        height: 40px;
        display: inline-block;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 50% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
    }
    .miner-feature .miner-feature-icon img{
        width: 24px !important;
        height: 24px !important;
    }
    .header-content{
        position: relative;
        z-index: 5;
    }
    .header-content.miner-shop h1{
        font-family: 'Gilroy', sans-serif;
        font-size: 72px;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        -moz-background-clip: text !important;
        -o-background-clip: text !important;
        background-clip: text !important;
        background: radial-gradient(51.88% 5897.7% at 50.06% 66.25%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
        line-height: 1.1;
        text-align: center;
        margin: 0px auto;
    }
    .miner-cloud-text h2{

        font-family: 'Gilroy-Medium',sans-serif;
        font-size: 54px;
        font-weight: normal;
        line-height: 64px;
        letter-spacing: -1.2px;
        background: radial-gradient(51.88% 5897.7% at 50.06% 66.25%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
        background-clip:text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 24px;
    }
    .miner-cloud-text p.subtitle{

        font-family: 'Gilroy-Medium',sans-serif;
        font-size: 28px;
        color: #D9D9D9;
        margin-bottom: 32px !important;
    }
    .miner-cloud-text p.miner-text{
        font-size: 18px;
        color: #D9D9D9;
        line-height: 30px;
        letter-spacing: 0.09px;
    }


    .header-content.miner-shop .content-text p{
        font-family: 'Gilroy', sans-serif;
        font-size: 28px;
        color: #D9D9D9;
        margin-top: 16px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: none;
    }

    .btn-touch{
        text-transform: none;
        font-family: 'Inter',sans-serif;
        text-decoration: none;
        color: #40EFFA !important;
        text-align: center;
    }
    .earth-img{
        position: relative;
    }
    .hex-header-action{
        display: flex;
        flex-direction: column;
    }
    .hex-header-action button{
        max-width: 209px;
        margin: 0px auto;
    }

    .miner-shop.header-miner-features{
        margin-bottom:140px;
    }
    .miner-shop.header-miner-features{
        flex-wrap: wrap;
        margin-top: 115px;
    }
    .miner-shop .miner-feature{
        background: radial-gradient(63.94% 63.94% at 50.00% -0.00%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(6px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.04);
        padding: 48px 24px;
        max-width: 374px;
        
    }
    /* Miner Details Feature */
    .miner-details-features{
        display: flex;
        justify-content: space-between;
        row-gap: 60px;
        margin-bottom: 150px;
    }

.miner-detail-feature{
        background: radial-gradient(63.94% 63.94% at 50% 0%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(15, 214, 227, 0.12);
        padding: 40px 24px 32px 24px;
        border-radius: 10px;
        /* width: 80%; */
        flex-basis: 23%;
        border: 1px solid rgba(255, 255, 255, 0.04);
        margin-left: auto;
        margin-right: auto;
        position: relative;
        backdrop-filter: blur(6px);
        width: 80%;
    }
    .miner-detail-feature .miner-feature-img{
        position: absolute;
        top: -25px;
        left: calc(50% - 48px/2);
        width: 48px;
        height: 48px;
        background:  rgba(7, 99, 105, 0.30);
        backdrop-filter: blur(4px);
        border: 1px solid;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border-image: linear-gradient(#10EBF9 , #076369); */
        border: 1px solid rgba(16, 235, 249, 0.5);
    }

    /* .miner-cloud-bg{
        background-image: url('@/assets/Lines ground.png');
        background-position: 50% 200%;
    } */

    @media (min-width: 1264px){
        .header-content .content-text h2{
            font-size: 72px;
            max-width: 522px;
        }
        .miner-shop .content-text h1{
            font-size: 80px !important;
            margin: 0px !important;
            text-align: center !important;
        }
        .header-content.miner-shop .content-text p{
            margin-left: 0px;
            margin-right: 0px;
            text-align: center;
            max-width: none;
        }
        .hex-header-action {
            display: block;
        }
        .hex-header-action button{
            max-width: auto !important;
            margin: auto !important;
        }
    }   

</style>
<style>

.main-header{
    margin: 0px auto;
    background-size: cover;
    overflow: hidden;
    
}

@media (min-width: 1264px){
    .main-header{
        width: 98%;
        border: 1px solid #3F3E8C;
        border-radius: 24px;
        margin-top: 32px;
    }
}
</style>