<template>
    <div class="nav-container">
            <div class="navbar landing" :class="dialog ? 'show-menu' : ''">
                <div class="d-flex justify-space-between align-center">
                    <div class="nav-logo">
                        <hexa-logo></hexa-logo>
                    </div>
                    <div class="nav-links">
                        <div class="dialog-menu">
                            <template>
                                <div>
                                    <v-icon
                                    color="white"  
                                    @click="toggleDialog"
                                    class="btn-menu d-flex d-lg-none">
                                        {{ dialog ? 'mdi-close' : 'mdi-menu' }}
                                    </v-icon>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="d-none d-lg-block nav-btns">
                        <router-link to="/signin" class="log-in">{{userLogged ? 'Overview' : 'Log in'}}</router-link>
                        <v-btn class="nav-mining main-color elevation-0 px-3" @click="getAccess" rounded>Join Waitlist</v-btn>
                    </div>
                </div>
            </div>
        <Teleport to="#app">
            <div class='mob-list' :class="dialog && showMenu ? 'show-mob' : 'hide-mob'">
                <div class="mob-list-menu">
                    <div class="menu-container d-flex justify-center">
                        <ul class="d-inline-block text-center pa-0">
                            <li><v-btn class="nav-mining main-color elevation-0 px-3" @click="getAccess">Join Waitlist</v-btn></li>
                            <li><router-link to="/signin" class="log-in ma-0">Log in</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>
<script>
export default{
    emits:['slide','access'],
    props:{
        isTop:{
            type: Boolean
        }
    },
    watch:{
        isTop(){
            console.log('A')
        }
    },
    data(){
        return{
            dialog: false,
            menu: [
                {name: 'Calculator', to: 'profitability', active: true},
                {name: 'Use cases', to: 'network', active: false,},
                {name: 'Features', to: 'features', active: false,},
                // {name: 'How to', to: 'howto', active: false},
            ],
            toggle: false
        }
    },
    methods:{
        closeDialog(){
            this.dialog = false;
        },
        slideTo(data, newIndex){
            this.dialog = false;
            let index = this.menu.findIndex(element => {
                return element.active == true;
            })
            this.menu[index].active = false;
            this.menu[newIndex].active = true;
            
            document.documentElement.style.overflowY = this.dialog ? 'hidden' : 'scroll';

            return this.$emit('slide', data);
        },
        toggleDialog(){
            this.dialog = !this.dialog;

                document.documentElement.style.overflowY = this.dialog ? 'hidden' : 'scroll';
        },
        resetAll(){

            if(window.scrollY == 0){
                let index = this.menu.findIndex(element => {
                    return element.active == true;
                })
                this.menu[index].active = false;
                this.menu[0].active = true;
            }
        },
        getAccess(){
            this.$emit('access');
        }
        
    },
    
    computed:{
        showMenu(){
            let show = this.$vuetify.breakpoint.name != 'lg' &&
            this.$vuetify.breakpoint.name != 'xl';

            if(this.dialog && !show)
                this.closeDialog();

            return show; 
            
        },
        userLogged(){
            return this.$store.getters['isAuth'];
            
        }       
    },

    mounted(){
        window.addEventListener('scroll', this.resetAll);
    },
    


}
</script>
<style scoped>

ul li{
    cursor: pointer;
}
.nav-mining {
    letter-spacing: 0px !important;
    color: #141934 !important
}
.mob-list-menu .menu-container{
        margin-top: 60px;
        display: flex;
        justify-content: center;
}
.mob-list-menu .menu-container ul{
    display: inline-block;
    color: #FFF;
    font-family: 'Gilroy-medium', sans-serif !important;
    text-align: center;
}
.mob-list.show-mob{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.6);
    backdrop-filter: blur(5px);
    z-index: 30;
    animation-name: list;
    animation-duration: 0.5s; 
    animation-delay: 0s;
    animation-direction: normal;
    display: block !important;
}

    .mob-list.hide-mob{
        display: none;
    }
    .mob-list-menu ul li{
        margin-top: 10px;
    } 
.mob-list-menu .menu-container{
    background-color: #0D1116;
    padding: 46px 0px !important;
    border-top: 1px solid #1E213F;
    border-bottom: 1px solid #1E213F;

}
.mob-list-menu ul{
    list-style: none !important;
}
.navbar{
    padding: 20px 32px;
    border-radius: 0px;
    position: relative;
    /* background-color: rgba(30, 33, 63, 0.80); */
    /* backdrop-filter: blur(16px); */
    z-index: 32;
}
.landing .nav-links .desk-menu li{
    color: #FFF;
    padding: 4px 10px 4px 10px;
    border: 1px solid transparent;
    border-radius: 36px;
    
}
.landing .nav-links .desk-menu li.active{
    color: #40EFFA !important;
    border-color: transparent !important; /*Old Color #0BA7B1 */
}
.dialog-menu .mdi-menu,.dialog-menu .mdi-close{
    position: relative;
    z-index: 33;
}
.nav-logo{
    position: relative;
    z-index: 32;
}
.nav-links ul{
    list-style: none;
}
.nav-links .desk-menu{
    list-style: none;
    padding: 0px !important;
    display: flex;
}

.nav-links .desk-menu {
    display: flex;
    align-items: center;
}
.nav-links .desk-menu li{
    cursor: pointer;
}
.nav-links .desk-menu li:not(:last-child){
    margin-right: 36px;
    font-family: 'Inter', sans-serif;
}
.log-in{
    text-decoration: none;
    color: #CCC !important;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
}
.nav-mining{
    text-transform: none !important;
    
}
.navbar .v-list{
    background-color: transparent;
    box-shadow: 0px !important;
}

.btn-menu::after{
    background-color: transparent !important;
    position: relative;
    z-index: 33;
}
.navbar.show-menu{
    background-color: transparent !important;
    backdrop-filter: blur(0px) !important;
}
@keyframes list {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
   }
   @media (min-width: 1264px){
    .mob-list.show-mob{
            display: none !important;
    }
    .mob-list-menu .menu-container{
        margin-top: 80px;
    }
    .log-in{
        margin-right: 20px !important;
    }
    
}
@media (min-width: 960px){
    .mob-list{
        display: none !important;
    }
}

@media (min-width: 1264px){
    .nav-container{
        max-width: 100%;
        margin: 0px auto;
    }
}  
.nav-mining.main-color{
    color: #141934 !important;
} 
</style>