import { render, staticRenderFns } from "./CoinTicker.vue?vue&type=template&id=323a8ee2&scoped=true&"
import script from "./CoinTicker.vue?vue&type=script&lang=js&"
export * from "./CoinTicker.vue?vue&type=script&lang=js&"
import style0 from "./CoinTicker.vue?vue&type=style&index=0&id=323a8ee2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323a8ee2",
  null
  
)

export default component.exports