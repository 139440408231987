<template>
    <div class="checkout-content">
        <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
          {{ alert.message }}
          <template v-slot:action="{ attrs }">
              <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
          </template>
        </v-snackbar>

        <v-dialog 
            :opacity="60"
            v-model="loading"
            width="100%"
            max-width="768px"
            persistent
            content-class="loading-dialog"
            >
                <v-progress-circular
                :size="50"
                color="white"
                indeterminate
                ></v-progress-circular>

            </v-dialog>

        <v-row class="d-flex justify-center">
            <v-col cols="12" sm="10" md="8" lg="6">
                <div class="shop-item-checkout">
                    <h2 class="checkout-title mb-8">Payment Method</h2>
                    <v-form class="checkout-form" ref="form">
                        <h2 class="checkout-title method-title mb-2">Choose which cryptocurrency you want to pay</h2>
                        <v-select :eager="true" v-model="order.currency" validate-on-blur :rules="[...rules.requiredRules]"  label="Select A Payment Method" :items="methods" item-text="coin" item-value="coin"  solo class="main-select mb-8" hide-details="auto" :menu-props="{ bottom: true, offsetY: true, closeOnClick: true,'content-class': 'main-select-menu custom-menu'}">
                            <template v-slot:selection="{ item,index }">
                                
                                <img width="25px" class="mr-2" :id="index" :src="getPic(item.img)" alt="">{{ item.display }}
                            </template>
                            <template v-slot:item="{item,index}">
                                <img width="25px" class="mr-2" :id="index" :src="getPic(item.img)" alt="">{{ item.display }}
                            </template>
                        </v-select>
                        <h2 class="checkout-title mb-4">Shipping</h2>
                        <v-text-field v-model="order.first_name" validate-on-blur :rules="[...rules.requiredRules, ...rules.nameRules]" class="main-input custom my-3" label="First Name" hide-details="auto" solo></v-text-field>
                        <v-text-field v-model="order.last_name" validate-on-blur :rules="[...rules.requiredRules, ...rules.nameRules]" class="main-input custom my-3" label="Last Name" hide-details="auto" solo></v-text-field>
                        <v-text-field v-model="order.street_address" validate-on-blur :rules="[...rules.requiredRules, ...rules.minRules, ...rules.addressRules]" class="main-input custom my-3" label="Street Address" hide-details="auto" solo></v-text-field>
                        <v-autocomplete v-model="order.country_code" validate-on-blur :rules="[...rules.requiredRules]" :items="countries" label="Country" item-text="name" item-value="code" class="main-select country-menu my-3" solo hide-details="auto" :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, contentClass: 'main-select-menu custom-menu'}"></v-autocomplete>
                        <v-autocomplete v-if="order.country_code == 'US'" v-model="order.state" validate-on-blur :rules="[...rules.requiredRules]" :items="states" label="State" item-text="name" item-value="code" class="main-select country-menu my-3" solo hide-details="auto" :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, contentClass: 'main-select-menu custom-menu'}"></v-autocomplete>
                        <v-text-field v-model="order.city" validate-on-blur :rules="[...rules.requiredRules]" class="main-input custom my-3" label="City" hide-details="auto" solo></v-text-field>
                        <v-text-field v-model="order.zip_code" validate-on-blur :rules="[...rules.requiredRules]" class="main-input custom my-3" label="Zip/Postal Code" hide-details="auto" solo></v-text-field>
                        <v-text-field v-model="order.phone" validate-on-blur :rules="[...rules.requiredRules, ...rules.phoneRules]" class="main-input custom my-3" label="Phone Number" hide-details="auto" solo></v-text-field>
                    </v-form>
            
                </div>
            </v-col>
            <v-col cols="12" sm="10" md="8" lg="6" >
                <div class="shop-item-summary">                    
                    <h2 class="summary-title mb-12">Order Summary</h2>
                    <div class="summary-items">
                        <ItemSummary :item="item" :currency="currency" :loadCurrency="loadCurrency">
                            <template v-slot:pay-btn>
                                <v-btn :disabled="loadCurrency" block class="main-btn py-3" @click="payItem">Make Payment</v-btn>
                            </template>
                        </ItemSummary>    
                    </div>  
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
let countries = require('@/assets/json/countries.js')
let states = require('@/assets/json/states_hash.js')

import ItemSummary from './ItemSummary.vue'

export default{
    components:{ItemSummary},
    props:{
        item:{
            required: true,
            type: Object
        }
    },
    data(){
        return { 
            loading: false,
            loadCurrency: false,
            countries,
            states,
            methods:[],
            alert:{
                show:false,
                type: null,
                message: null
            },
            selectedMethod: null,
            order:{
                product: null,
                first_name: null,
                last_name: null,
                street_address: null,
                country_code: null,
                state: null,
                city: null,
                zip_code: null,
                phone: null,
                currency: null
            },
            currency: null
        }
    },
    watch:{
        'order.currency'(newVal){
            this.getCurrency(newVal)
        }
    },  
    methods:{
        getPic(img){
            return require(`@/assets/coins/${img}`)
        },
        async payItem(){
            
            if(this.$refs.form.validate()){
                this.loading = true;

                let server = this.$store.getters['server'];

                // Vuetify remove dublicated values .. 
                this.order.currency = this.order.currency == 'USDT2' ? 'USDT' : this.order.currency;
                
                
                this.order.product = this.$route.params.id;
            
                if(this.order.country_code != 'US')
                    this.order.state = null;

                await this.$http.post(server + '/shipping/order/create', this.order).then(response => {

                    window.location.href = '/shop/orders/'+ response.data.order

                }).catch( () => {
                    
                    this.alert.message = 'Something went wrong!';
                    this.alert.type = 'error';
                    
                    this.alert.show = true;

                })
                
                this.trackOrderPlaced();
                this.loading = false;
            }

        },
        async getCurrency(currency){
            
            if(!currency)
                return ;


            if(currency == 'USDT2')
                currency = 'USDT'

            let server = this.$store.getters['server'];
            
            this.loadCurrency = true;
            
            await this.$http.get(server + '/finance/crypto-listing/'+currency).then(response => {
                
                this.currency = response.data;

            }).catch(() => {
                return this.$router.push({path: '/shop'});
            })
            this.loadCurrency = false;
        },
        trackOrderPlaced() {
            if (process.env.NODE_ENV === 'production'){
                let product = this.order.product == 1 ? "Big Miner" : "Small Miner";
                this.$gtag.event('conversion', {
                    'send_to': 'AW-705764791/kN-UCJvH4dsYELe7xNAC',
                    'value': product
                });
            } else {
                console.log("skipping order placed google tag");
            }
        }
    },
    computed:{
        rules(){
            return this.$store.getters['rules']
        }
    },
    mounted(){
        this.methods = this.$store.getters['coins'];

    }
    
}
</script>
<style scoped>

</style>
<style>
.main-select-menu.custom-menu{
    margin-top: 8px !important;
    /* height: 20px; */
    border-color: transparent !important;
}
.main-select .v-input__control label{
    color: #6A6C82 !important;
}

.main-select-menu.custom-menu .v-list-item{
    margin: 8px 0px !important;
}
.checkout-content{
    max-width: 1024px;
    /* margin: 0px auto !important; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 66px;
}
.shop-item-checkout, .shop-item-summary{
    background-color: #1E213F;
    min-height: 400px !important;
    max-width: 575.99px !important;
    margin: 0px auto;
    position: relative;
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
    border-radius: 16px;
    padding: 32px 24px;
}
/* .shop-item-checkout{
    height: 800px;
    
} */
.checkout-form .v-text-field.v-text-field--solo.main-select .v-input__control{
    min-height: 58px !important;
    border-radius: 12px;
}
.checkout-form .v-text-field.v-text-field--solo.main-select.country-menu .v-input__control{
    min-height: 48px !important;
    border-radius: 12px;
}
.checkout-content .checkout-title{
    font-weight: 400;
    font-size: 18px;
}
.checkout-title.method-title{
    font-size: 14px !important;
}
.checkout-form .v-text-field.v-text-field--solo .v-input__control{
    min-height: 48px !important;
    /* padding: 0px 24px; */
    
}
.checkout-form .v-text-field.v-text-field--solo .v-input__slot{
    border: 3px solid #4D506A !important;
}
.shop-item-summary .summary-title{
    font-size: 18px;
    font-weight: 400;
}

.checkout-form .v-text-field--solo > .v-input__control > .v-input__slot{
    background-color: #0E0F1D !important;
    padding: 0px 24px !important;
}
.main-select-menu.custom-menu .v-list-item .v-list-item__mask{
    padding: 5px;
    background: #7371FF !important;
    color: #FFF;    
}
</style>