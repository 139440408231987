<template>
    <div class="signin">
        <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
          {{ alert.message }}
          <template v-slot:action="{ attrs }">
              <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
          </template>
        </v-snackbar>

        <BackgroundStars></BackgroundStars>
        <v-container>
            <div class="sign-logo d-flex justify-center">
                <hexa-logo></hexa-logo>
            </div>
            <v-dialog 
            :opacity="60"
            v-model="loading"
            width="100%"
            max-width="768px"
            persistent
            content-class="loading-dialog"
            >
                <v-progress-circular
                :size="50"
                color="white"
                indeterminate
                ></v-progress-circular>

            </v-dialog>
            <div class="signin-form">
               <!-- <span class="black--text">{{ isAuth }}</span> {{ true }} -->
                <SignForm @validForm="signIn"></SignForm>
            </div>

        </v-container>
    </div>
</template>
<script>
import BackgroundStars from '@/components/Basics/BackgroundStars.vue'
import SignForm from '@/components/LandingPage/Signin/SignInForm.vue'

export default{
    components:{BackgroundStars,SignForm},
    data(){
        return{
            loading: false,
            alert:{
                show:false,
                type: null,
                message: null
            }
        }
    },
    computed:{
        isAuth(){
            return this.$store.getters['isAuth'];
        }
    },
    methods:{
        async signIn(credentails){
            
            this.loading = true;
            let server = this.$store.getters['server'];
            
            await this.$http.post(server + '/user/login', credentails).then(response => {   
                
                
                this.loading = false;
                
                this.alert.type = 'success';
                this.alert.message = 'You have logged in successfully!';
                this.alert.show = true;
                
                this.loading = false;
                

                
                this.$store.dispatch('attempt', response.data)  

            }).catch((error) => {
                this.loading = false;
                this.alert.type = 'error';
                if(error && error.response)
                    this.alert.message = error.response.data.message;
                else
                    this.alert.message = 'Unknown error occurred!'
                
                this.alert.show = true;
                
            })
            
            
            

        }
    },
    
}
</script>
<style>
    .sign-logo{
        margin-top: 30px;
        margin-bottom: 70px;
        /* margin: 70px 0px; */
    }
    .signin{
        min-height: 100vh !important;
        overflow: hidden !important;
        background:  radial-gradient(93.85% 93.85% at 50% 11.72%, #0D1116 46.2%, #300E5B 85.08%, #7371FF 100%), #0D1116;
    }
    .signin-form{
        background-color: #FFF;
        box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
        border-radius: 8px;
        width: 100%;
        max-width: 456px;
        padding: 32px 24px;
        margin: 0px auto;
        position: relative;
    }
    @media (min-width: 1264px){
        .signin-form{
            padding: 48px;
        }
    }
</style>