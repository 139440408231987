<template>
    <div class="line-chart">
        <LineChart :data="data" :options="options"></LineChart>
    </div>
</template>
<script lang="ts">
    import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
    } from 'chart.js'
    import { Line as LineChart } from 'vue-chartjs'

    ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
    )

export default {
  name: 'App',
  components: {
    LineChart
  },
  data() {
    return{
        options: {
            plugins: {
                legend: {
                    display: false,
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            borderColor: '#7371FF',
            borderJoinStyle: 'round',
            borderWidth: 1,
            pointBorderColor: 'rgba(115, 113, 255, 0.5)',
            pointBorderWidth:  8,
            pointBackgroundColor: '#7371FF',
            
            scales: {
                y: {
                    min: 0,
                    border:{
                        dash: [4,4],
                        display: false,
                    },
                    grid: {
                        color: '#5454B9', // for the grid lines
                        tickColor: '#5454B9', // for the tick mark
                        tickWidth: 1,
                        offset: true,
                    },
                    ticks:{
                        color: '#868E95',
                    }
                    
                },
                x: {
                    border:{
                        display: true,
                        color: '#5454B9'
                    },
                    grid:{
                        drawBorder: false,
                        color: 'rgba(255,255,255,0)'
                    },
                    ticks:{
                        color: '#868E95'
                    }
                }
            },
        },
    data:{
    labels: ['09/22', '09/23', '09/24', '09/25', '09/26', '09/27', '09/28'],
    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        fill: true,
        backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0,0,0,320);
                gradient.addColorStop(0, 'rgba(115, 113, 255, 0.5)');
                gradient.addColorStop(1, 'rgba(115, 113, 255, 0)');

              return gradient;
        },
    
      }
    ]
  }
    }
  }
}
</script>
<style>
.line-chart{
    height: 350px;
}</style>