import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import BasicButton from '@/components/Basics/BasicButton.vue'
import BackgroundStars from '@/components/Basics/BackgroundStars.vue'
import VueJwtDecode from 'vue-jwt-decode'
import vuescroll from 'vue-scroll'
import VueTypedJs from 'vue-typed-js'
import VueCarousel from 'vue-carousel';
import Teleport from 'vue2-teleport'
import VueGtag from 'vue-gtag'
import * as Sentry from "@sentry/vue";




// Global Components

import HexaLogo from '@/components/Basics/HexaLogo.vue'
import axios from 'axios'

axios.interceptors.request.use(function (config) {
  
  if (store.getters['token']) {
    if(!config.url.includes('/finance/crypto-listing'))
      config.headers.Authorization = `Bearer ${store.getters['token']}`
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  let refresh = localStorage.getItem('refresh');
  if (error.response.status === 401) {
      if(error.response.config.headers.Authorization && refresh && !error.response.request.responseURL.includes('user/token/refresh')){

        store.dispatch('refresh')
        store.dispatch('setUser');
      }else{
        store.dispatch('logout')
      }
  }else if(error.response.status == 403){
    store.dispatch('logout');
  }
  return Promise.reject(error)
})

if (process.env.NODE_ENV === 'production'){
  // google analytics
  Vue.use(VueGtag, {
    config: {
      id: 'AW-705764791',
      currency: 'USD',
    }
  })
  // sentry
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/api\.hexageo\.io/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

Vue.use(LottieVuePlayer);
Vue.use(vuescroll);
Vue.use(VueTypedJs);
Vue.use(VueCarousel);
Vue.config.productionTip = false

Vue.prototype.$http = axios;

Vue.component('BackgroundStars', BackgroundStars);
Vue.component('menu-icon', MenuIcon);
Vue.component('hexa-logo', HexaLogo);
Vue.component('basic-button', BasicButton)
Vue.component('Teleport', Teleport)

Number.prototype.countDecimals = function(){
  if(Math.floor(this.valueOf()) == this.valueOf()) return 0;
  return this.toString.split(".")[1].length || 0;
}



new Vue({
  router,
  store,
  vuetify,
  VueJwtDecode,
  render: h => h(App)
}).$mount('#app')
