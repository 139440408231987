<template>  
    <v-main class="dashboard">
        <Loading :loading="loading"></Loading>
        <dash-navbar></dash-navbar>

        <div class="bg-stars">
            <BackgroundStars></BackgroundStars>
            <MainOverview></MainOverview>

        </div>
        
    </v-main>    
</template>
<script>
import BackgroundStars from '@/components/Basics/BackgroundStars.vue'
import DashNavbar from '@/components/Dashboard/Basics/DashNavbar.vue'
import MainOverview from '@/components/Dashboard/Overview/MainOverview.vue'
import Loading from '@/components/Dashboard/Basics/Loading.vue'
    export default{
        components:{DashNavbar,BackgroundStars,MainOverview,Loading},
        data(){
            return{
                loading: false,
                // user: null
            }
        },
        methods:{

        }
    }
</script>
<style>
    .bg-stars{
        position: relative;
        height: 100%;
        overflow: hidden;
    }
    .dashboard{
        min-height: 100vh;
        overflow: hidden;
        background:  radial-gradient(93.85% 93.85% at 50% 11.72%, #0D1116 46.2%, #300E5B 85.08%, #7371FF 100%), #0D1116;
    }
    .dashboard .bg-animation{
        height: 0px !important;
    }
</style>