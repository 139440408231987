<template>
    <div class="forgot-content">
        <v-form ref="form" @submit.prevent="reset">
            <h2 class="text-center">Forget your password?</h2>
            <div class="text-center forgot-suggest">Enter an email and you will receive a link to reset a password</div>
            <label for="email" class="lbl-input mb-1">Email</label>
            <v-text-field validate-on-blur class="form-input" v-model="email" :rules="[...rules.requiredRules, ...rules.emailRules]" id="email" outlined></v-text-field>
            <v-btn block class="form-action" @click="reset">Reset Password</v-btn>
        </v-form>
    </div>
</template>
<script>
export default{
    emits:['reset'],
    data(){
        return{
            email: null
        }
    },
    computed:{
        rules(){
            return this.$store.getters['rules'];
        }
    },
    methods:{
        reset(){
            if(this.$refs.form.validate()){
                return this.$emit('reset', this.email)
            }
        }
    }
}
</script>
<style>
.forgot-suggest{
    color: #485158;
    font-family: 'Inter',sans-serif;
    margin: 8px 0px 32px 0px;
}
    .forgot-content h2{
        font-family: 'Inter',sans-serif;
        font-size: 28px;
        color: #212445;
        font-weight: normal;
    }
    .forgot-content .form-input fieldset{
        border: 2px solid #C7D5E1;
        border-radius: 12px;

    }
    .forgot-content .form-input .v-text-field__slot input{
        color: #344054 !important;
    }
    .forgot-content .form-action{
        min-height: 48px !important;
        background-color: #10EBF9 !important;
        box-shadow: none !important;
        border-radius: 36px;
        border: 1px solid #10EBF9;
        height: 48px !important;
        margin-bottom: 24px;
        color: #141934;
        text-transform: none;
        font-family: 'Inter',sans-serif;
        font-weight: 500 !important;
        letter-spacing: 0px !important;
        font-size: 16px;
    }
    .forgot-content .lbl-input{
        color: #344054;
        font-family: 'Inter',sans-serif;
        font-weight: 500;
        font-size: 14px;
        display: block;
    }
@media (min-width: 1264px){

    .forgot-content h2{
        font-weight: 600;
        font-size: 32px !important;
    }
}
</style>