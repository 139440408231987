<template>
    <div class="cookie-page">

    <!-- Alert Notification -->
    <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
        {{ alert.message }}
        <template v-slot:action="{ attrs }">
            <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
        </template>
    </v-snackbar>

    <!-- Alert Notification -->

    <!-- Access Dialog -->
    <v-dialog
    :opacity="0.6"
    v-model="dialog"
    width="100%"
    max-width="768px"
    persistent
    content-class="contact-dialog">
        <v-form @submit.prevent="subscribe" ref="form">
            <div class="main-form dialog-access">
                <v-btn color="transparent" v-if="!loadAccess" class="d-flex ml-auto close-btn" rounded small @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                <h2 class="text-center">Secure your<span class="d-block access-headline-custom">1.5x earning multiplier</span></h2>
                <p class="text-center">We're excited to have you on board as we revolutionize the world of cryptocurrency mining. Kindly share your email with us, and you'll be at the forefront of this innovative journey. Stay connected for updates, insights, and the opportunity to be part of the future of mining. Thank you for joining us!</p>
                <v-text-field v-model="email" validate-on-blur :rules="[...rules.requiredRules, ...rules.emailRules]" class="main-input mt-10" label="Email" hide-details="auto" solo></v-text-field>
                <v-btn :loading="loadAccess" :disabled="loadAccess" @click="subscribe" class="main-btn py-4 mt-10" block>Join Waitlist</v-btn>
            </div>
        </v-form>
    </v-dialog>
    <!-- Access Dialog -->


        <HexaNavbar @access="dialog = true"></HexaNavbar>
        <BackgroundStars></BackgroundStars>
        <v-container class="cookies-container">
            <div class="cookie-header">
                <h1>Cookie Policy</h1>
                <p class="font-italic">Last Updated: [Tue Sep 12 2023]</p>
                <p>Hexageo.io uses cookies and similar tracking technologies on our website. By using the Service, you consent to the use of cookies as described in this Cookie Policy.</p>
            </div>
            <div class="cookies-content">
                <div class="logs-content">
                    <h2>What Are Cookies?</h2>
                    <p>Cookies are small text files that are placed on your device (computer, tablet, smartphone) when you visit a website. They are widely used to make websites work or improve their efficiency, as well as to provide information to website owners.</p>
                </div>
                <div class="cookies-type">
                    <h2>Types of Cookies We Use</h2>
                    <ul>
                        <li>
                            <h3>Essential Cookies</h3>
                            <p>These cookies are necessary for the Service to function properly. They enable you to navigate our website and use its features.</p>
                        </li>
                        <li>
                            <h3>Analytical/Performance Cookies</h3>
                            <p>These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. This helps us improve the Service.</p>
                        </li>
                        <li>
                            <h3>Functionality Cookies</h3>
                            <p>These cookies are used to recognize you when you return to our website. They remember your preferences and allow for a more personalized user experience.</p>
                        </li>
                        <li>
                            <h3>Targeting/Advertising Cookies</h3>
                            <p>These cookies are used to deliver advertisements that are relevant to you and your interests. They may also limit the number of times you see an ad and help measure the effectiveness of advertising campaigns.</p>
                        </li>
                    </ul>
                </div>
                <div class="third-party-content">
                    <h2>How We Use Cookies</h2>
                    <p class="mb-4">We use cookies for the following purposes:</p>
                    <ul>
                        <li>To provide and maintain the Service.</li>
                        <li>To gather analytics to improve the Service.</li>
                        <li>To personalize your experience on our website.</li>
                        <li>To deliver targeted advertisements.</li>
                    </ul>
                </div>
                <div class="traffic-content">
                    <h2>Your Cookie Choices</h2>
                    <p class="mb-4">Most web browsers allow you to control and manage cookies through their settings. You can usually find these settings in the "Options" or "Preferences" menu of your browser. However, if you limit the use of cookies, some features of the Service may not function properly.</p>
                    <p>You can also opt-out of targeted advertising by visiting the websites of relevant advertising networks or by using third-party tools.</p>
                </div>
                <div class="changes-content">
                    <h2>Changes to this Cookie Policy</h2>
                    <p>We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated Cookie Policy will be posted on this page with the "Last Updated" date.</p>
                </div>
                <div class="questions-content">
                    <h2>Contact Us</h2>
                    <p>If you have any questions about this Cookie Policy or our use of cookies, please contact us at <a href="mailto:support@hexageo.io">[support@hexageo.io]</a>.</p>
                </div>
            </div>
        </v-container>
        <MainFooter  @access="dialog = true"></MainFooter>
        
        
    </div>
</template>
<script>
import MainFooter from '@/components/Basics/MainFooter.vue'
import HexaNavbar from '@/components/Basics/Navbar.vue'
export default{
    components:{MainFooter,HexaNavbar},
    data(){
        return{
            dialog: false,
            alert:{
                show: false,
                message: null,
                type: null,
            },
            loadAccess: false,
            email: null
        }
    },
    computed:{
        rules(){
            return this.$store.getters['rules']
        }
    },
    methods:{
        accessOpen(){
            if(this.$store.getters['isWhitelisted'])
            return;
        
            this.dialog = true;
        },
        async subscribe(){
            if(this.$refs.form.validate()){
                
                let server = this.$store.getters['server'];
            
                
                let data = {email: this.email}
                
                this.loadAccess = true;

                await this.$http.post(server + '/user/subscribe',data).then((response) => {
                  
                    this.$store.dispatch('setWhitelisted', true)
                    this.alert.type = 'success';
                    this.alert.message = response.data.message ?? 'You have successfully subscribed';
                    this.trackSubscriber();
                }).catch((error) => {
                    this.alert.type = 'error';
                    
                    this.alert.message = error.response.data.message ?? 'Something went wrong during subscribing!'
                })

                this.alert.show = true;

                this.loadAccess = false;
                this.dialog = false;
                
                
                this.email = null;

                // if(!data)
                //   this.$refs.form.reset();
               
            }
        },
        trackSubscriber() {
            if (process.env.NODE_ENV === 'production'){
                this.$gtag.event('early_subscriber', {'send_to': 'AW-705764791/EEQrCNWL99wYELe7xNAC'})
            } else {
                console.log("skipping google tag");
            }
        },

    }
}
</script>
<style scoped>
    .cookies-container{
        max-width: 768px !important;
    }
    .cookie-page{
        min-height: 100vh !important;
        overflow: hidden !important;
        background:  radial-gradient(93.85% 93.85% at 50% 28.72%, #0D1116 46.2%, #300E5B 85.08%, #7371FF 100%), #0D1116;
    }
    .cookie-page .cookie-header{
        margin: 80px 0px;
    }
    .cookie-page .cookie-header h1{
        font-family: 'Gilroy-Medium', sans-serif;
        font-weight: normal;
        color: #EEE;
        margin-bottom: 4px;
    }
    .cookie-page .cookie-header p{
        color: #d8d8d8;
    }
    .cookie-page .cookies-content{
        display: flex;
        flex-direction: column;
        gap: 44px;
    }
    .cookie-page .cookies-content > div h2{
        margin-bottom: 16px;
        color: #EEE;
        font-weight: normal;
    }
    .cookie-page .cookies-content > div p{
        font-size: 15px;
        color: #d8d8d8;
    }
    ul{
        list-style-type: "- ";
    }
    .cookies-type ul li h3{
        font-weight: normal;
        margin-bottom: 6px;
    }
    .cookies-type ul li{
        margin-bottom: 24px;
    }
    
</style>
