<template>
    <div class="hexa-logo ">
        <a @click="redirect" class="d-flex align-center">
            <img src="@/assets/heax-logo.svg">
            <h3>Hexageo</h3>
        </a>
    </div>
</template>
<script>
export default{
    props:{
        landing:{
            type: Boolean,
            require: true,
            default: false      
        }
    },
    methods:{
        redirect(){
            let isAuth = this.$store.getters['isAuth'];
            if(isAuth && !this.landing)
                window.location.href = '/overview'
            else
                window.location.href = '/'
        }
    }
}
</script>
<style>
    .hexa-logo a{
        text-decoration: none;
        color: #FFF !important;
    }
    .hexa-logo a img{
        margin-right: 14px;
    }
    .hexa-logo a h3{
        font-family: Aeonik,sans-serif;
        font-size: 26.75px;
    }
    
</style>