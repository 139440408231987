<template>
    <div class="privacy-page">

    <!-- Alert Notification -->
    <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
        {{ alert.message }}
        <template v-slot:action="{ attrs }">
            <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
        </template>
    </v-snackbar>

    <!-- Alert Notification -->

    <!-- Access Dialog -->
    <v-dialog
    :opacity="0.6"
    v-model="dialog"
    width="100%"
    max-width="768px"
    persistent
    content-class="contact-dialog">
        <v-form @submit.prevent="subscribe" ref="form">
            <div class="main-form dialog-access">
                <v-btn color="transparent" v-if="!loadAccess" class="d-flex ml-auto close-btn" rounded small @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                <h2 class="text-center">Secure your<span class="d-block access-headline-custom">1.5x earning multiplier</span></h2>
                <p class="text-center">We're excited to have you on board as we revolutionize the world of cryptocurrency mining. Kindly share your email with us, and you'll be at the forefront of this innovative journey. Stay connected for updates, insights, and the opportunity to be part of the future of mining. Thank you for joining us!</p>
                <v-text-field v-model="email" validate-on-blur :rules="[...rules.requiredRules, ...rules.emailRules]" class="main-input mt-10" label="Email" hide-details="auto" solo></v-text-field>
                <v-btn :loading="loadAccess" :disabled="loadAccess" @click="subscribe" class="main-btn py-4 mt-10" block>Join Waitlist</v-btn>
            </div>
        </v-form>
    </v-dialog>
    <!-- Access Dialog -->


    <HexaNavbar @access="dialog = true"></HexaNavbar>
    <BackgroundStars></BackgroundStars>
        <v-container class="privacy-container">
            <div class="privacy-header">
                <h1>Privacy Policy</h1>
                <p class="font-italic">Last Updated: [Tue Sep 12 2023]</p>
                <p>This privacy policy attempts to outline how Hexageo Limited. collects and uses information from its users.</p>
            </div>
            <div class="privacy-content">
                <div class="logs-content">
                    <h2>Logs</h2>
                    <p>Like most other websites, this website collects non-personal information about users whenever they access the site. These logs may include the browser name, the type of computer and technical information about the means of connection to the site, such as the operating system, the Internet service providers utilized and other similar information.</p>
                </div>
                <div class="cookies-content">
                    <h2>Cookies</h2>
                    <p>Cookies are files with small amount of data which are sent to your browser from a website and stored on your computer's hard drive. Tjis website uses cookies to track preferences and AB testing experiments. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
                </div>
                <div class="data-content">
                    <h2>Data</h2>
                    <p>With the exception of deliberately submitted contact requests and error reports, this website does not collect or retain any data submitted by users. Any contact requests or error reports submitted will be kept confidential and deleted upon request.</p>
                </div>
                <div class="third-party-content">
                    <h2>Third Party</h2>
                    <p>This website uses reputable third party vendors to provide traffic analytics, security, and advertising services.</p>
                </div>
                <div class="traffic-content">
                    <h2>Traffic Analytics</h2>
                    <p>Traffic analysis and monitoring of this website is provided by Google Analytics. This information is used to direct resources, plan maintenance windows and improve usability. Google uses cookies and may use the data it collects to contextualize and personalize the ads of its own advertising network.</p>
                </div>
                <div class="changes-content">
                    <h2>Changes</h2>
                    <p>This privacy policy may be revised at any time without notice. We encourage users to frequently check this page for any changes. Your continued use of this website after any changes or revisions to this policy shall indicate your agreement with the revised privacy policy.</p>
                </div>
                <div class="questions-content">
                    <h2>Questions</h2>
                    <p>If you have any questions about this Privacy Policy, or anything else related to this website, please contact us at <a href="mailto:support@hexageo.io">[support@hexageo.io]</a>.</p>
                </div>
            </div>
        </v-container>
        <MainFooter  @access="dialog = true"></MainFooter>
    </div>
</template>
<script>
import MainFooter from '@/components/Basics/MainFooter.vue'
import HexaNavbar from '@/components/Basics/Navbar.vue'
export default{
    components:{MainFooter,HexaNavbar},
    data(){
        return{
            dialog: false,
            alert:{
                show: false,
                message: null,
                type: null,
            },
            loadAccess: false,
            email: null
        }
    },
    computed:{
        rules(){
            return this.$store.getters['rules']
        }
    },
    methods:{
        accessOpen(){
            if(this.$store.getters['isWhitelisted'])
            return;
        
            this.dialog = true;
        },
        async subscribe(){
            if(this.$refs.form.validate()){
                
                let server = this.$store.getters['server'];
            
                
                let data = {email: this.email}
                
                this.loadAccess = true;

                await this.$http.post(server + '/user/subscribe',data).then((response) => {
                  
                    this.$store.dispatch('setWhitelisted', true)
                    this.alert.type = 'success';
                    this.alert.message = response.data.message ?? 'You have successfully subscribed';
                    this.trackSubscriber();
                }).catch((error) => {
                    this.alert.type = 'error';
                    
                    this.alert.message = error.response.data.message ?? 'Something went wrong during subscribing!'
                })

                this.alert.show = true;

                this.loadAccess = false;
                this.dialog = false;
                
                
                this.email = null;

                // if(!data)
                //   this.$refs.form.reset();
               
            }
        },
        trackSubscriber() {
            if (process.env.NODE_ENV === 'production'){
                this.$gtag.event('early_subscriber', {'send_to': 'AW-705764791/EEQrCNWL99wYELe7xNAC'})
            } else {
                console.log("skipping google tag");
            }
        },

    }
}
</script>
<style scoped>
    .privacy-page{
        min-height: 100vh !important;
        overflow: hidden !important;
        background:  radial-gradient(93.85% 93.85% at 50% 28.72%, #0D1116 46.2%, #300E5B 85.08%, #7371FF 100%), #0D1116;
    }
    .privacy-page .privacy-header{
        margin: 80px 0px;
    }
    .privacy-page .privacy-header h1{
        font-family: 'Gilroy-Medium', sans-serif;
        font-weight: normal;
        color: #EEE;
        margin-bottom: 4px;
    }
    .privacy-page .privacy-header p{
        color: #d8d8d8;
    }
    .privacy-page .privacy-content{
        display: flex;
        flex-direction: column;
        gap: 44px;
    }
    .privacy-page .privacy-content > div h2{
        margin-bottom: 16px;
        color: #EEE;
        font-weight: normal;
    }
    .privacy-page .privacy-content > div p{
        font-size: 15px;
        color: #d8d8d8;
    }
    .privacy-page .privacy-container{
        max-width: 768px !important;
    }
    .main-form.dialog-access h2{
        font-family: 'Gilroy-Medium', sans-serif;
        font-size: 48px;
        margin-bottom: 24px;
    /* font-weight: normal; */
    }
    .main-form.dialog-access p{
        font-size: 18px;
    }
    .access-headline-custom{
    color: #5FF2FB;
    }
</style>