<template>
    <div class="wrap-star">
        <BackgroundStars/>
        <v-container>
            <div class="shop-item-header text-center">
                <h2>Shop</h2>
                <p>Harness the Power of 5G Modems for High-Profitability Mining</p>
            </div>
            <div class="shop-item-container">
                    <v-row class="d-flex" v-for="(item,index) in miners" :key="index">
                        
                        <v-col cols="12" lg="4" class="pa-4">
                            <div class="shop-item">
                                <div class="shop-item-pic">
                                    <img width="100%"  :src="getImg(item.img)" alt="Router">
                                </div>
                                <div class="shop-item-alt-imgs">
                                    <div class="alt-img" :class="{active: item.img == 'miner-01.jpg'}" @click="setImg('miner-01.jpg',index)">
                                        <img height="100%" src="@/assets/products/miner-01.jpg" alt="Router-Img">
                                    </div>
                                    <div class="alt-img" :class="{active: item.img == 'miner-02.jpg'}"  @click="setImg('miner-02.jpg',index)">
                                        <img height="100%%" src="@/assets/products/miner-02.jpg" alt="Router-Img">
                                    </div>
                                    <div class="alt-img" :class="{active: item.img == 'miner-03.jpg'}" @click="setImg('miner-03.jpg',index)">
                                        <img height="100%" src="@/assets/products/miner-03.jpg" alt="Router-Img">
                                    </div>
            
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="8" class="pa-4">
                            <div class="shop-item-details">
                                <h2 class="shop-item-name mb-4">{{ item.name }}</h2>
                                <p>{{ item.description }}</p>
                            </div>
                            <div class="shop-item-action my-4">
                                
                                <v-btn class="main-btn text-capitalize pa-4 mt-4 mb-8 mt-8" :to="'shop/'+item.id">Start earning - buy now</v-btn>
                            </div>
                            <div class="shop-item-features">
                                    
                                <div class="feature d-flex">
                                    <div class="step-line"></div>
                                    <div class="feature-content">
                                        <h3>Heat</h3>
                                        <p>Cool operation, no excessive heat.</p>
                                    </div>
                                </div>
                                <div class="feature d-flex">
                                    <div class="step-line"></div>
                                    <div class="feature-content">
                                        <h3>Power</h3>
                                        <p>Efficient, low energy usage.</p>
                                    </div>
                                </div>
                                <div class="feature d-flex">
                                    <div class="step-line"></div>
                                    <div class="feature-content">
                                        <h3>Noise</h3>
                                        <p>Silent, no disruptive noise.</p>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    
                </div>
        </v-container>
    </div>
</template>
<script>
import BackgroundStars from '@/components/Basics/BackgroundStars.vue'
export default{
    components:{BackgroundStars},
    data(){
        return{
            img: 'miner-01.jpg',
            img2: 'miner-01.jpg',
            miners:[
                {
                    id:1,
                    name: 'Big Miner',
                    description: "The Hexageo Big Miner is capable of mining up to 6 different coins at the same time. No other miner has this capability. Equipped with 6 sim card slots, each slot is capable of earning up to a maximum of $200 USD in the cryptocurrency of your choice.",
                    price_usd: "3000.00",
                    sim_capacity: 6,
                    img: 'miner-01.jpg'
                },
                {
                    id:2,
                    name: 'Small Miner',
                    description: "The Hexageo Small Miner is capable of earning up to a maximum of $200 USD in the cryptocurrency of your choice.",
                    price_usd: "600.00",
                    sim_capacity: 1,
                    img: 'miner-01.jpg'
                }
            ]
        }
    },
    methods:{
        setImg(img,index){
            this.miners[index].img = img;
       },
        getImg(img){
            return require(`@/assets/products/${img}`)
        },
    }
}
</script>
<style scoped>
.shop-item-header{
    /* margin: 130px 0px; */
    margin-top: 120px;
    margin-bottom: 80px;
}
.shop-item-header h2{
    font-family: 'Gilroy', sans-serif;
        font-size: 36px;
        background: radial-gradient(5897.84% 66.28% at 50.06% 66.25%, #FFF 0%, rgba(255, 255, 255, 0.50) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
}
.shop-item-header p{
    color: #D9D9D9;
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 28px;

}
.main-btn .v-btn--disabled{
    background-color: #EEE !important;
}
.feature .feature-content h3{
    margin-bottom: 4px;
}
.feature .feature-content p{
    margin-bottom: 48px !important;
}
.feature .step-line::after{
    min-height: 100px !important;
    content: "";
    display: block;
    width: 5px;
    background-image: linear-gradient(#d16edc,rgba(209,110,220,0));
    margin: 0 auto;
}
.feature .step-line::before{
    display: block;
    border: 3px solid #f9c3ff;
    border-radius: 50%;
    content: "";
    width: 26.67px;
    height: 26.67px;
    background-color: #0e0f1d;
    margin: 0 auto;
    box-shadow: 0 0 32px #f9c3ff;
}
.feature:last-child .step-line::after{
    display: none;
}
.feature .feature-content h3{
    font-family: 'Gilroy', sans-serif;
    font-size: 18px !important;
    color: #E9E9EC;
}
.feature .feature-content p{
    color: #BABBC5;
    max-width: 284px;
}
.shop-item-container{
    background-color: #1E213F;        
    padding: 32px 24px;
    border-radius: 16px;
    position: relative;
}
.shop-content{
    margin-top: 66px;
}

.shop-item-pic{
    background-size: cover;
    background-position: top center;
    min-height: 380px;
    display: flex;
    justify-content: center;
    align-content: center;
}
.shop-item-pic img{
    object-fit: contain;
    max-width: 320px;
}
.shop-item-alt-imgs{
    display: flex;
    width: 100%;
    max-width: 320px;
    justify-content: space-between;
    margin: 0px auto;
}

.shop-item-alt-imgs .alt-img{
    width: 32%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    background-color: #FFF;
    border-radius: 4px;
    cursor: pointer;
}
.shop-item-alt-imgs .alt-img.active{
    border: 2px solid #11DBE9;
}
.shop-item-container .shop-item-title{
    font-weight: 400;
    font-size: 18px;
}
.shop-item-details h2{
    font-family: 'Gilroy',sans-serif;
    font-size: 28px;
    
}
@media(min-width: 600px){
    .shop-item-header h2{
        font-size: 54px;
    }
}
@media (min-width: 1264px){
    .feature .feature-content p{
        max-width: 436px !important;
    }
    .shop-item-container{
        max-width: 100%;
        margin: 0px auto;
    }
}
</style>