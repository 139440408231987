<template>
    <div class="profitability" v-if="prepareData" >

        <v-container>
            <div class="main-profit">
                <div class="head-profit text-center">
                    <h2>Profitability Calculator</h2>
                </div>
                <div class="calculate-profit d-flex justify-space-between" id="calculate-profit">
                    <v-btn-toggle :max="1" mandatory v-model="sim" class="comparison-toggle">
                        <v-btn :value="6">
                            <span>Big Miner</span>
                        </v-btn>
                        <v-btn :value="1">
                            <span>Small Miner</span>
                        </v-btn>
                    </v-btn-toggle>

                    <div class="wrap-input" id="head-profit-wrap">
                        <v-autocomplete
                            :items="prepareData"
                            :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, contentClass: 'main-select-menu', auto:true, attach: '#calculate-profit', eager:true,origin:'auto'}" 
                            class="input-select input-select-coin mb-2" 
                            hide-details="true" 
                            return-object
                            item-text="name"
                            item-value="name"
                            v-model="selectedCoin"
                            @change="addCoin(selectedCoin)"
                            solo 
                            label="Search for coin"
                            :eager="true"
                            >
                        
        <template  slot="item" slot-scope="{ item }">   
            <v-list-item-content>
                <v-list-item-title>
                    <div class="title-content d-flex align-center">
                        <img width="20px" :src="getImg(item.slug)" class="mr-2">
                        <p>{{ item.coin }}</p>
                    </div>
                </v-list-item-title>
            </v-list-item-content>
        </template>
        <template slot="selection" slot-scope="{ item }">
            <div class="input-content d-flex align-center">
                <img width="20px" :src="getImg(item.slug)" class="mr-2" alt="Coin">
                <p>{{ item.coin }}</p>
            </div>
        </template>
        <template slot="prepend-inner" v-if="!selectedCoin">
            <v-icon>mdi-magnify</v-icon>
        </template>
                        </v-autocomplete>

                    </div>
                    
                    
                </div>
            </div>
            <div class="coin-profits">
                <TransitionGroup name="list" tag="ul">
                    <li v-for="(item,index) in coins" :key="index">
                        <CoinProfit @remove="removeItem" :coin="item"></CoinProfit>
                    </li>
                </TransitionGroup>
            </div>
            <div class="set-cost-limit text-center mt-12">
                <h2>Monthly cost per unlimited data SIM</h2>
                <CustomRange @cost="getCost"></CustomRange>
                <p>Move slider to adjust your monthly cost of SIM</p>
                <v-btn class="btn-mining mt-12" @click="getAccess">Get Early Access</v-btn>
            </div>
        </v-container>
    </div>
</template>
<script>
import CoinProfit from './CoinProfit.vue';
import CustomRange from './CustomRange.vue'
export default{
    components:{CoinProfit,CustomRange},
    props:['coinsList'],
    emits:['access'],
    data(){
        return{
            coins:[],
            selectedCoin: null,
            cost: 30,
            sim: 6

       }
    },
    computed:{
        prepareData:{
            get(){
                return this.coinsList
            },
            set(value){
                this.$emit('listChanged', value)
            }
        },
        sliderHandler(){
            return `${this.sim}|${this.cost}`
        },
    
    },
    watch:{
        sliderHandler(){
            this.coins.map(element => {
                element.incomeUSD =  200 * Number.parseInt(this.sim);
                element.cost = Number.parseInt(this.cost) * this.sim;
                element.profit = Number(Number.parseFloat(element.incomeUSD) - Number.parseFloat(element.cost)).toFixed(2);
                element.mining = Number.parseFloat( element.profit / element.price_usd)
            })
        },
    },
    methods:{
        getCost(value){
            this.cost = value
        },
        getImg(coin){
            try{
                return require(`@/assets/coins/${coin}.png`)
            }catch{
                return require('@/assets/coins/default.png')
            }
        },  
        removeItem(name){
            let coinIndex = this.coins.findIndex(element => {
                return element.name == name
            })
            

            this.coins.splice(coinIndex,1)

            this.selectedCoin = null;
            
        },
        addCoin(item){

            let coin = item;
            if(coin){
                let coinIndex = this.coins.findIndex(element => {
                        return element.coin == item.coin;
                })
    
                if(coinIndex == -1){
                    coin.incomeUSD =  200 * Number.parseInt(this.sim);
                    coin.cost = Number.parseInt(this.cost) * this.sim;
                    coin.profit = Number(Number.parseFloat(coin.incomeUSD) - Number.parseFloat(coin.cost)).toFixed(2);
                    coin.mining = Number.parseFloat(coin.profit / coin.price_usd)
    
                    this.coins.push(coin)
                
                }
            }
            
        },
        getAccess(){
            this.$emit('access');
        }
    },
    created(){
        this.addCoin(this.coinsList[0])
        this.selectedCoin = this.coinsList[0]
    },
   
    

}
</script>
<style scoped>

.comparison-toggle{
    /* width: 100%; */
    background-color: transparent !important;
    /* display: flex; */
    /* justify-con  tent: center; */
    /* margin-top: 30px; */
    height: 46px;
    gap: 30px;
    /* background-color: #F1F1EF !important; */
}
.comparison-toggle .v-btn{
    /* color: #333; */
    /* background-color: #FFF; */
    width: 171.5px;
    height: 56px !important;
    max-width: 120px !important;

}
.comparison-toggle .v-btn::before{
    background-color: transparent !important;
}

.comparison-toggle .v-btn{
    border-radius: 6px;
    background-color: transparent;
    border: 0px;
    color: #91F6FC;
    text-transform: none;
    letter-spacing: 0px !important;
    font-size: 18px;
    font-weight: 600;
}
.comparison-toggle .v-btn.v-item--active{
    background-color: #10EBF9 !important;
    color: #0E0F1D;
}

</style>
<style>
    .coin-profits ul{
        list-style: none !important;
        padding: 0px !important;
    }
/* For Auto Property Back Here Again */
    .v-menu__content.v-menu__content--auto.main-select-menu{
        max-height: 304px !important;
        min-width: 320px !important;
        /* top: 2048px !important;
        left: 263px !important; */
        transform-origin: center top !important;
        z-index: 8 !important;
        margin-left: 16px;
    
    }

/* --END-- */
    .main-select-menu{
        background-color: #212445 !important;
        border-radius: 8px !important;
        border: 1px solid #6A6C82;

    }
    .main-select-menu::-webkit-scrollbar{
        width: 5px !important;
        height: 3px !important;
        -webkit-appearance: none !important;
    }
    .main-select-menu::-webkit-scrollbar-button {
        width: 50px; 
        height: 3%;
    }
    .main-select-menu::-webkit-scrollbar-thumb{
        background: #0E0F1D;
        border-radius: 8px !important;    
    }
    .main-select-menu .v-list{
        background-color: #212445;
        padding-right: 10px;
        padding-left: 10px;
        overflow: hidden !important;
        border-radius: 8px !important;
  
    }
    .main-select-menu .v-list .v-list-item{
        border-radius: 8px !important;
        padding-left: 8px !important;
    }
    .main-select-menu .v-list .v-list-item.primary--text{
        color: #FFF !important;

    }
    .main-select-menu .theme--dark.v-list-item.v-list-item--highlighted::before{
        opacity: 0 !important;
    }
    .main-select-menu .v-list-item--active{
        background-color: #5250B5 !important;
        /* opacity: 1 !important; */
        caret-color: #5250B5 !important;
        color: #FFF;
        border-radius: 8px !important;
        -moz-border-radius: 8px !important;
        -o-border-radius: 8px !important;
    }
    .main-select-menu .theme--dark.v-list-item.v-list-item--highlighted::before{
        border-radius: 8px !important;
    }
    .main-select-menu .v-list .theme--dark.v-list-item:hover::before{
        border-radius: 6px !important;
    }
    /* .head-profit h2{
        margin-top: 128px !important;
    } */
    .main-profit .head-profit h2{
        font-family: 'Gilroy', sans-serif;
        font-size: 36px;
        background: radial-gradient(51.88% 5897.7% at 50.06% 66.25%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-top: 40px;
    }
    .main-profit .head-profit p{
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        max-width: 500px;
        margin: 0px auto;
    }
    .input-select .v-input__slot{
        border: 2px solid #7371FF;
        height: 56px;
        border-radius: 12px;
    }
    .input-select .v-input__slot label{
        font-family: 'Inter',sans-serif !important;
        color: #4D506A !important;
        
    }
    .input-select .v-input__slot .v-input__prepend-inner i{
        color: #4D506A !important;
    }
    .input-select-coin{
        width: 320px !important;
    }
    .profit-search-container .v-input__slot{
        background-color: transparent !important;
        box-shadow: none !important;
        border: 1px solid #6A6C82;
        border-radius: 8px !important;
        /* color: #FFF !important; */
    }
    .profit-search-container .v-text-field input::placeholder{
        color: #667085;
    }
    .calculate-profit{
        margin-top: 64px !important;
        gap: 24px;
        flex-wrap: wrap;
    }
    .set-cost-limit h2{
        font-family: 'Gilroy', sans-serif;
        font-size: 28px;
        color: #F7F9FB;
        font-weight: normal;
    }
    .set-cost-limit p{
        max-width: 850px;
        margin: 0px auto;
        font-family: 'Inter',sans-serif;
        color: #BABBC5;
    }

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.list-enter,
.list-leave-to{
    opacity: 0;
    transform: translateX(30px);
}
@media(min-width: 600px){
    .main-profit .head-profit h2{
        font-size: 54px;
    }
}
@media (min-width: 1264px){
    #calculate-profit{
        width: 93%;
        
    }
}
</style>