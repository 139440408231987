<template>
    <div class="my-cart" v-if="order">
        <h2 class="my-cart-title">My Cart</h2>
        <div class="cart-item-wrap mt-3">
            <div class="cart-item d-flex justify-space-between align-end flex-wrap">
                <div class="cart-item-det d-flex">
                    <div class="cart-item-img">
                        <img width="100%" src="@/assets/products/miner-01.jpg" alt="">
                    </div>
                    <div class="cart-item-details ml-6 d-flex justify-space-between flex-wrap flex-grow-1 align-end">
                        <div>
                            <h2 class="cart-item-details-title">{{ order.product_name }}</h2>
                        <p class="my-2">Order #{{ order.id }}</p>
                        <router-link class="main-route" to="/shop">See Details</router-link>
                        </div>
                    </div>
                </div>
                    
                <div class="value-amount d-flex  flex-grow-1 flex-sm-grow-0 justify-space-between d-sm-block mt-6">
                    <p class="mb-sm-2">Total Amount</p>
                    <div class="coin-details d-flex">
                        <div class="wrap-img">
                            <img :src="getPic(order.img)" @error="replaceImg" class="mr-2" width="25px" alt="Bitcoin">
                        </div>
                        <div>
                            <p class="coin-details-value">{{order.currency}} <span>{{ parseFloat(order.crypto_amount).toFixed(5) }}</span></p>
                            <p class="mt-1">USD {{ order.price_usd }}$</p>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="payment-details">
            <div class="payment-details-address">
                <h2 class="mb-2 mt-6 mb-2">Please send your paymenet to:</h2>
                <div class="address-details d-flex">
                    <p class="pay-details-title">Address</p>
                    <div class="wrap-address d-flex justify-space-between flex-grow-1 align-center">
                        <p class="pay-details-value">{{ order.wallet }}</p>
        <v-tooltip
        open-delay="1000"
          v-model="showTip"
          top
          content-class="copied-tooltip">
        <template v-slot:activator="{attrs }">
            <v-btn
              icon
              v-bind="attrs"
              color="#40EFFA"
              class="mr-1"
              @click="copyContent(order.wallet)"
            >
              <v-icon color="#40EFFA">
                mdi-content-copy
              </v-icon>
            </v-btn>
          </template>
          <span>Copied !</span>
        </v-tooltip>
                    </div>
                </div>
                <div class="network-details mt-2 d-flex">
                    <p class="pay-details-title">Network</p>
                    <div class="pay-details-value d-flex">
                        <img width="25px" class="mr-2" @error="replaceImg" :src="getPic(order.img)" alt="">
                        <p>{{ order.currency }}</p>
                    </div>
                </div>
            </div>
            <div class="hint-details d-flex pa-4 mt-3">
                <div class="mr-3">
                    <img src="@/assets/info.png" alt="Info">
                </div>
                <div class="hint-details-value">
                    <p class="hint">This wallet is valid for 1 hour. Once this wallet expires, a new one will be generated.</p>
                    <p class="mt-1 mb-3">Payments are usually confirmed within 1 hour of payment.</p>
                    <router-link class="main-route article" to="/">Read Article</router-link>
                </div>
            </div>
        </div>
        <div class="payment-details-timeout d-flex justify-end align-center mt-6">
            <p class="mr-6">Wallet valid for:</p>
            <p class="timeout">{{ timer }}</p>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        item:{
            type: Object,
            required: true
        },
        coinsImg: [
            
        ]
    },
    data(){
        return{
            id: null,
            showTip: false,
            timeout: 0
        }
    },
    watch:{
        timeout(old){
            // console(newVal)
            if(old)
                setTimeout(() => {
                    this.timeout -= 1
                },1000)
             
        }
    },
    computed:{
        timer(){


            if(!this.timeout)
                return "00:00:00"

            let hours = Math.floor(this.timeout / 3600);
            let minutes = Math.floor(this.timeout / 60) % 60;
            let seconds = this.timeout % 60;            
                return String(hours).padStart(2,'0') + ':' + String(minutes).padStart(2,'0') + ":" + String(seconds).padStart(2, '0')
            
            },
            order(){
                return this.item
            }
        
    },
    methods:{
        async copyContent(content){
            this.showTip = true;
            navigator.clipboard.writeText(content);
            await new Promise(resolve => setTimeout(resolve,500))
            this.showTip = false;
            
        },
        getPic(handle){
            let coin = String(handle).toLowerCase();
            
            let img = null;
            
            try{
                img = require(`@/assets/coins/${coin}`) 
            }catch(err){
            
                img = require('@/assets/default-placeholder.png')
            }

            return img 
        },
        replaceImg(){
            return this.$store.dispatch('defaultImg')
        }
    },
    
    mounted(){
        
        if(this.order){
            this.timeout = this.order.wallet_valid_for;
            // this.timeout = 4 
        }

    }

}
</script>
<style>
.copied-tooltip{
    background-color: transparent;
}
.cart-item-wrap{
    border-radius: 12px;
}
.my-cart{
    position: relative;
    background-color: #1E213F;
    padding: 32px 24px;
    border-radius: 12px;
    max-width: 576px;
    margin: 0px auto;
}
.my-cart .my-cart-title{
    font-weight: 400;
    font-size: 18px;
    font-size: 20px;
}
.my-cart .cart-item-img{
    width: 100px;
    height: 100px;
    padding: 5px;
    background-color: #FFF;
}
.cart-item-details-title{
    font-family: 'Gilroy-Medium';
    font-size: 28px;
    font-weight: normal;
}

.payment-details h2{
    font-family: 'Gilroy-Medium';
    font-size: 18px;
    font-weight: normal;
}
.payment-details .address-details,
.payment-details .network-details{
    background-color: #0E0F1D;
    border-radius: 12px;
    border: 2px solid #4D506A;
}
.payment-details .wrap-address{
    overflow: hidden !important;
}
.payment-details .pay-details-title{
    border-right: 1px solid #4D506A;
    padding: 16px 24px;
}
.payment-details .pay-details-value{
    padding: 16px 24px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
.payment-details-timeout .timeout{
    /* background-color: #171A31; */
    border-radius: 4px;
    /* padding: 8px; */
    font-size: 24px;
    color: #5FF2FB;
    font-weight: 600;
    min-width: 140px;
    text-align: right;

}
.hint-details{
    background-color: #4D506A;
    border-radius: 12px;
}
.hint-details-value p:first-child{
    font-weight: 600 !important;
}
.hint-details-value *{
    font-size: 14px !important;
    color: #E9E9EC !important;
}
.main-route.article{
    font-weight: 600 !important;
}

</style>