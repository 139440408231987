<template>
    <div class="dashboard-content">
        <v-container>
            <v-row class="d-flex justify-center mt-12 mt-md-16">
                <v-col cols="12" sm="10" md="8" lg="8">
                    <UserWallet></UserWallet>
                </v-col>
                <v-col cols="12" sm="10" md="8" lg="4">
                    <BalanceAnalysis></BalanceAnalysis>
                </v-col>
            </v-row>
        </v-container>
        </div>
</template>
<script>
import UserWallet from '@/components/Dashboard/Basics/UserWallet.vue'
import BalanceAnalysis from '@/components/Dashboard/Basics/BalanceAnalysis.vue'
export default{
    components:{UserWallet,BalanceAnalysis},
    data(){
        return{
            wallets: [
                // {name: 'Bitcoin', address: '0xee2e4ffBCAdb9B31be744bd0466C691ACed3Ce7b' ,img: 'bitcoin.png'},
                // {name: 'Litecoin', address: '0xee2e4ffBCAdb9B31be744bd0466C691ACed3Ce7b',  img: 'litecoin.png'},
                // {name: 'Paypal', address: 'gbabarogic@gmail.com',  img: 'PayPal - png 0.png'},
            ], 
        }
    },
    methods:{
        async getWallets(){
            let server = this.$store.getters['server'];

            await this.$http.get(server + '/user/wallets').then(response => {
                this.wallets = response.data.wallets;
            }).catch(() => this.wallets = [])
        }
    },
    async created(){
        // await this.getWallets()
    }
}
</script>
<style>
@media (min-width: 1904px){
    .dashboard-content .container{
        max-width: 1340px !important;
    }
}
.content-container{
    position: relative;
    background-color: #1E213F;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    padding: 32px;
}
.content-container h2{
    font-weight: normal;
    font-size: 18px;
    font-family: 'Gilroy-Medium',sans-serif;
}
</style>