<template>
    <div class="miner-tables">
        <div class="body-miner-table">

          <v-simple-table class="transparent">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Orders #
            </th>
            <th class="text-left">
              Product
            </th>
            <th class="text-left">
              Shipping Status
            </th>
            <th class="text-left">
              Ship To
            </th>
            <th class="text-left">
              Order Placed
            </th>
            <th class="text-left">
                Payment Status
            </th>
            <th class="text-left">
                Coin
            </th>
            <th class="text-left">
                Total
            </th>
            <th class="text-left">
                Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in orders"
            :key="key"
          >
          <td>{{ item.id }}</td>
          <td>{{ item.product }}</td>
          <td>
            <span> {{ status[item.status] }} </span>
            <!-- {{ item.shipStatus }} -->
          </td>
          <td>
            <ul class="address-list">
              <li class="country">{{ item.country }}</li>
              <li class="address">{{ item.address }}</li>
            </ul>
          </td> 
          <td>{{ item.price_usd }}</td>
          <td>
            <OrderStatus :status="item.payment_status"></OrderStatus>
          </td>
          <td>
            <div class="coin d-flex">
              <!-- <img width="20px" :src="getPic(item.coin.img)" :alt="item.coin.name"> -->  
                <p class="ml-3">{{ item.currency }}</p>
            </div>
          </td>
          <td>$ {{ item.price_usd }}</td>
          <td>
            <v-btn @click="handleStatus(item.id, item.payment_status)"  :class="item.payment_status.toLowerCase() == 'pd' ? 'outline-btn' : 'main-btn'" class="main-btn order-btn-state">{{item.payment_status.toLowerCase() == 'pd' ? 'Invoice' : 'Pay Now'}}</v-btn>
          </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center mt-3">
        <!-- <Pagination></Pagination> -->
    </div>
        </div>
    </div>
</template>
<script>
import OrderStatus from './OrderStatus.vue'
  export default {
    emits:['handle'],
    components:{
      OrderStatus
        // Pagination
        // ,MinerStatus,
        // MinerCoin
    },
    props:{
      orders:{
        type: Array,
        required: true,
        default: function(){
          return []
        }
      }
    },
    data () {
      return {
        page: 1,
      }
    },
    computed:{
        status(){
          return this.$store.getters['status'];
        }
    },
    methods:{
      getPic(img){
        return require(`@/assets/coins/${img}`)
      },
      handleStatus(id,status){
        this.$emit('handle', id,status)
      }
    },
  }
</script>
<style>
.address-list{
  list-style: none !important;
  padding: 0px !important;
}
.address-list .address{
  font-family: 'DINNextW01-Regular',sans-serif;
  font-size: 13px;
  color: #999AA9;
}
.order-btn-state{
  width: 143px !important;
  font-size: 14px !important;
}
.v-pagination > li:first-child,
.v-pagination > li:last-child {
  display: none;
}
.miner-tables .v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}
.miner-tables .body-miner-table{
  background-color: #2B2E54;
  padding-bottom: 18px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
/* Hide scrollbar for IE, Edge and Firefox */
.miner-tables .v-data-table__wrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.miner-tables .v-data-table table{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #2B2E54;
    font-family: 'Inter',sans-serif !important; 
    border-bottom: 1px solid #323665;
}
.miner-tables .v-data-table .v-data-table__wrapper, 
.miner-tables .v-data-table table thead{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.miner-tables .v-data-table table thead tr th{
    font-size: 10px;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    color: #999AA9 !important;
}
.miner-tables .v-data-table table tbody tr td{
    font-size: 14px !important;
}
.v-data-table{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.miner-tables .v-data-table table thead{
    background-color: #171A31;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.miner-tables .v-data-table table tbody tr td{
    white-space: nowrap !important;
}
.miner-tables .v-data-table table tbody tr td .miner-location-region{
    color: #999AA9;
}
.miner-tables .v-data-table table tbody tr td .miner-earning{
    font-size: 14px !important;
}
.miner-tables .v-data-table table tbody tr td .miner-earning::before{
    content: '$';
    margin-right: 3px;
}
.header-miner-tables{
  margin-top: 45px;
  margin-bottom: 25px;
}

.miner-tables .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #383b68;
}

</style>