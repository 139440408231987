<template>
    <div class="verify-email">
        <v-container>
            <div class="verification mb-3 mt-10">
                <div class="verify-head d-flex mb-4 justify-center">
                    <v-icon>{{success ? 'mdi-check-decagram' : 'mdi-emoticon-sad-outline'}}</v-icon>
                    <h1>{{success ? 'Verification Success' : 'We couldn\'t verify your email' }}</h1>
                </div>
                <p class="text-center">{{success ? 'Thank you for your support, we have successfully verified your email address.' : 'The email verification link you clicked is invalid.'}}</p>
            </div>
            <p class="text-center">You will be redirected shortly ..</p>
        </v-container>
    </div>
</template>
<script>
export default{

    data(){
        return{
            success: true
        }
        
    },  
    methods:{
        async verifyEmail(token){
            
            let server = this.$store.getters['server'];

            await this.$http.get(server + `/user/email/verify/${token}`).then(() =>{this.success = true}).catch(() => this.success = false)
            
            await this.$store.dispatch('setUser')

            await new Promise(resolve => setTimeout(() => {
                window.location.href = '/signin'
                return resolve;
            }, 2000))
        }   
    },
    async created(){
        await this.verifyEmail(this.$route.params.token)
    }
}
</script>
<style scoped>
    .verify-email{
        min-height: 100vh !important;
        overflow: hidden !important;
        background:  radial-gradient(93.85% 93.85% at 50% 11.72%, #0D1116 46.2%, #300E5B 85.08%, #7371FF 100%), #0D1116;
    }
    .verify-head{
        gap: 16px;
        align-items: center;
    }
    .verify-head i{
        font-size: 35px;
        color: #10EBF9;
    }
    .verify-head h1{
        font-weight: normal;
        font-size: 25px;
    }
</style>