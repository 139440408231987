<template>
    <div class="payment-methods">
        <v-form @click.prevent="addMethod" ref="form">
                <div class="crypto-method">
                    <!-- <label class="form-label">withdrawal</label> -->
                    <div class="method-body">
                        <v-select :rules="rules.required" solo label="Select Network" v-model.number="wallet" :items="wallets" item-text="public_key" item-value="id" item class="main-input" :menu-props="{ maxWidth:'600px' ,bottom: true, offsetY: true, closeOnClick: true,'content-class': 'main-select-menu custom-menu'}" rounded dense>
                            <template v-slot:item="{ item,index }">
                                <div class="select-user-wallets">
                                    <p :id="index">{{ item.public_key }}</p>
                                    <p>{{ item.network }}</p>
                                </div>
                            </template>
                            
                        </v-select>
                        <v-text-field validate-on-blur :rules="[...rules.required, withdrawRule]"  v-model.number="amount" class="main-input" solo rounded label="USDT wallet address" dense></v-text-field>
                    </div>
                </div>
            <v-btn class="main-btn add-btn" :loading="loading" :disabled="loading" @click="withdraw" block>Withdraw</v-btn>
        </v-form>
    </div>
</template>
<script>
    export default{
        emits:['load','finish'],
        props:{
            wallets:{
                type: Array,
                required: true,
            }
        },
        data(){
            return{
                wallet:{
                    network: 'ERC20',
                    public_key: null
                },
                amount: null,
                loading: false,
                withdrawRule: (v) => (v && v >= 10 || 'The minimum withdrawal amount is 10$'),
                
            }
        },
        methods:{
            async withdraw(){
                if(this.$refs.form.validate()){

                    this.loading = true;
                    this.$emit('load');
            
                    let data = {
                        amount: this.amount, 
                        wallet: this.wallet
                    }
                   let server = this.$store.getters['server'];
                   
                
                    await this.$http.post(server + '/finance/withdraw', data).then(response => {
                        return this.$emit('finish', response)
                    }).catch(err => {
                        return this.$emit('finish', err.response) 
                    });
                    

                    this.loading = false;
                    this.$refs.form.reset();

                }

            }
        },   
        computed:{
            rules(){
                return {required: this.$store.getters['rules'].requiredRules, number: this.$store.getters['rules'].numberRules} 
            },
        }
    }
</script>
<style scoped>
    .crypto-method .method-title{
        margin-bottom: 10px;
    }
    .payment-methods form .crypto-method .form-label {
        font-size: 14px !important; 
        font-family: 'Inter',sans-serif !important;
        margin-bottom: 20px;
        display: block;
        color: #E9E9EC;
    }
    .payment-methods .method-body .v-input__slot{
        padding-top: 16px;
        padding-bottom: 16px;
        max-height: 56px;
    }
    .payment-methods .method-body .v-input__slot input{
        padding: 0px !important;
    }
    .select-user-wallets{
        gap: 2px !important;
        display: flex;
        flex-direction: column !important;
        padding: 10px 0px;
        max-width: 320px;
    }
    .select-user-wallets p:first-child{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .select-user-wallets p:last-child{
        font-size: 12px;
    }
</style>