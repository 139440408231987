<template>
    <div class="main-footer">
        <v-container>
            <div class="main-footer-header text-center">
                <h2>Start Your Mining Journey Today</h2>
                <p>Tap into the Power of 5G Modems and Unlock Lucrative Earnings</p>
                <!-- <h4>Ready to earn?</h4>
                    <p>Lorem ipsum paragraph</p> -->
                    <div class="footer-action mt-10">
                        <v-btn class="btn-mining elevation-0" @click="getAccess">Join Waitlist</v-btn>
                        <!-- <v-btn class="btn-mining btn-contact elevation-0" @click="dialog = true">Contact us</v-btn> -->
                    </div>
                </div>
                <div class="main-footer-body  d-flex flex-column flex-lg-row justify-space-between align-center text-center mt-10">
                    <p>© 2023 Hexageo. All rights reserved.</p>
                    <div class="hexa-logo my-4r">
                        <img src="@/assets/heax-logo.svg" alt="Logo">
                    </div>
                    <ul class="pa-0">
                        <li><router-link to="/terms">Terms</router-link></li>
                        <li><router-link to="/privacy">Privacy</router-link></li>
                        <li><router-link to="/cookies">Cookies</router-link></li>
                    </ul>
            </div>
        </v-container>
    </div>
</template>
<script>
export default{
    emits:['access'],
    methods:{
        getAccess(){
            this.$emit('access');
        },
    
    }
}
</script>
<style scoped>
.btn-mining{
        height: auto !important; 
        padding: 12px 18px !important;
        background:none;
        background-color: #10EBF9 !important;
        font-size: 16px !important;
        font-weight: normal;
        margin: 0px 10px;
        font-weight: 500;
    }
    .btn-mining.btn-contact{
        background-color: transparent !important;
        color: #10EBF9 !important;
        border: 1px solid #10EBF9;
    }
</style>
<style>
    .main-footer .footer-action{
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .close-btn{
        box-shadow: none !important;
    }
    .main-footer{
        margin-top: 165px;
        position: relative;
    }
    .main-footer ul{
        list-style: none;
    }
    .main-footer ul li{
        display: inline-block;
        color: #D0D5DD;
    }
    .main-footer ul li a{
        color: #d0d0d0;
        text-decoration: none;
    }
    .main-footer ul li:not(:last-child){
        margin-right: 10px;
        
    }
    .main-footer h4{
        font-family: 'Gilroy', sans-serif;
        font-size: 28px;
        font-weight: normal;
    }
    .main-footer p{
        color: #7A8995;
        font-family: 'Inter', sans-serif;
        margin: 16px 0px 40px 0px;
    }
    .main-footer .v-overlay__content{
        width: 100%;
        max-width: 768px;
    }
    .main-footer .v-overlay{
        z-index: 34 !important;
        backdrop-filter: blur(5px);
        overflow-y: scroll;
    }
    /* .contact-dialog{
        overflow: hidden !important;
    } */
    .contact-dialog .main-form h5{
        font-size: 18px;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        margin: 24px 0px;
    }
    .main-footer .main-footer-header h2{
        font-family: 'Gilroy-medium', sans-serif;
        font-size: 42px;
        background: radial-gradient(32.46% 3702.03% at 50.06% 66.25%, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        
    }
    .main-footer .main-footer-header p{
        font-family: 'Gilroy-medium', sans-serif;
        font-size: 20px;
        color: rgb(241, 241, 255);;
    }
    .main-footer .main-footer-body{
        
        gap: 15px;
    }
    .main-footer-body > *{
        flex: 1;;
    }
</style>