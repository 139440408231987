<template>
    <div class="account-analysis-content">
        <div class="account-analysis-profit d-flex align-center">
            <p class="analysis-account-balance">0</p>
            <p class="analysis-account-precentage up">0%</p>
            <p class="analysis-account-period">{{ selectedPeriod }}</p>
        </div>
        <div class="account-analysis-chart">
            <v-form>
                <v-select  :menu-props="{ bottom: true, offsetY: true, closeOnClick: true,'content-class': 'dash-menu'}"   class="period-input fit" dense hide-details="true" v-model="selectedPeriod" :items="periods"></v-select>
            </v-form>
            <ChartComponent></ChartComponent>
        </div>
    </div>
</template>
<script>
import ChartComponent from './ChartComponent.vue'
export default{
    components:{ChartComponent},
    data(){
        return{
            selectedPeriod:'Weekly',
            periods:[
                'Daily',
                'Weekly',
                'Monthly'
            ]
        }
    }
}
</script>
<style>
.account-analysis-content .period-input{
    margin-left: auto;
    
}
.account-analysis-content .period-input .v-input__slot::before {
  border-style: none !important;
}
.account-analysis-content .v-text-field > .v-input__control > .v-input__slot:after{
    border-style: none !important;
}
.account-analysis-content .v-select.v-input--dense .v-select__selection--comma{
    color: #ACB5BB !important;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
}
.account-analysis-content .period-input .v-input__append-inner .v-input__icon i{
    color:#ACB5BB !important;
}
.account-analysis-content .account-analysis-profit p{
    margin-bottom: 0px;

}
.account-analysis-content .account-analysis-profit .analysis-account-balance{
    font-family: 'Gilroy',sans-serif;
    font-size: 42px;   
    margin-right: 16px;
}
.account-analysis-content .account-analysis-profit .analysis-account-balance::before{
    content: '$'
}
.account-analysis-content .account-analysis-profit .analysis-account-precentage{
    font-family: 'Inter',sans-serif;
    color: #37D0B4;
    margin-right: 12px
}
.account-analysis-content .account-analysis-profit .analysis-account-period{
    font-family: 'Inter',sans-serif;
}
.dash-menu  .v-list{
    background-color: #212445 !important
}
</style>