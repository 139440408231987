<template>
    <div class="products">
    <!-- Alert Notification -->
    <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
        {{ alert.message }}
        <template v-slot:action="{ attrs }">
            <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
        </template>
    </v-snackbar>
    
    <!-- Alert Notification -->

    <!-- Access Dialog -->
    <v-dialog
    :opacity="0.6"
    v-model="dialog"
    width="100%"
    max-width="768px"
    persistent
    content-class="contact-dialog">
        <v-form @submit.prevent="subscribe" ref="form">
            <div class="main-form dialog-access">
                <v-btn color="transparent" v-if="!loadAccess" class="d-flex ml-auto close-btn" rounded small @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                <h2 class="text-center">Secure your<span class="d-block access-headline-custom">1.5x earning multiplier</span></h2>
                <p class="text-center">We're excited to have you on board as we revolutionize the world of cryptocurrency mining. Kindly share your email with us, and you'll be at the forefront of this innovative journey. Stay connected for updates, insights, and the opportunity to be part of the future of mining. Thank you for joining us!</p>
                <v-text-field v-model="email" validate-on-blur :rules="[...rules.requiredRules, ...rules.emailRules]" class="main-input mt-10" label="Email" hide-details="auto" solo></v-text-field>
                <v-btn :loading="loadAccess" :disabled="loadAccess" @click="subscribe" class="main-btn py-4 mt-10" block>Join Waitlist</v-btn>
            </div>
        </v-form>
    </v-dialog>
    <!-- Access Dialog -->

        <HexaNavbar @access="accessOpen"></HexaNavbar>
        <CardItem></CardItem>
        <v-container class="new-feature-container">
            <MinerDetails></MinerDetails>
        </v-container>
        <MainFAQ></MainFAQ>
        <MainFooter @access="accessOpen"></MainFooter>
    </div>
</template>
<script>
import HexaNavbar from '@/components/LandingPage/Products/Navbar.vue'
import CardItem from '@/components/LandingPage/Products/CardItem.vue'
import MinerDetails from '@/components/LandingPage/Products/MinerDetails.vue'
// import LiteMining from '@/components/LandingPage/LiteCoin/LiteMining.vue'
import MainFAQ from '@/components/LandingPage/Homepage/FAQ/MainFAQ.vue'
import MainFooter from '@/components/Basics/MainFooter.vue'
export default{
    components:{HexaNavbar,CardItem,MinerDetails,MainFAQ,MainFooter},
    data(){
        return{
            dialog: false,
            alert:{
                show: false,
                message: null,
                type: null,
            },
            loadAccess: false,
            email: null
        }
    },
    computed:{
        rules(){
            return this.$store.getters['rules']
        }
    },
    methods:{
        accessOpen(){
            if(this.$store.getters['isWhitelisted'])
            return;
        
            this.dialog = true;
        },
        async subscribe(){
            if(this.$refs.form.validate()){
                
                let server = this.$store.getters['server'];
            
                
                let data = {email: this.email}
                
                this.loadAccess = true;

                await this.$http.post(server + '/user/subscribe',data).then((response) => {
                  
                    this.$store.dispatch('setWhitelisted', true)
                    this.alert.type = 'success';
                    this.alert.message = response.data.message ?? 'You have successfully subscribed';
                    this.trackSubscriber();
                }).catch((error) => {
                    this.alert.type = 'error';
                    
                    this.alert.message = error.response.data.message ?? 'Something went wrong during subscribing!'
                })

                this.alert.show = true;

                this.loadAccess = false;
                this.dialog = false;
                
                
                this.email = null;
               
            }
        },
        trackSubscriber() {
            if (process.env.NODE_ENV === 'production'){
                this.$gtag.event('early_subscriber', {'send_to': 'AW-705764791/EEQrCNWL99wYELe7xNAC'})
            } else {
                console.log("skipping google tag");
            }
        },

    }
}
</script>
<style scoped>
.main-form.dialog-access h2{
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 48px;
    margin-bottom: 24px;
    /* font-weight: normal; */
}
.main-form.dialog-access p{
    font-size: 18px;
}
.access-headline-custom{
color: #5FF2FB;
}
.new-feature-container{
    margin-top: 100px;
    position: relative;
    z-index: 6;
  }
</style>