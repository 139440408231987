<template>
    <div class="signin-content">
        
        <v-snackbar v-model="alert.show" class="main-alert" :color="alert.type" top right timeout="2000" transition="scroll-x-transition">
          {{ alert.message }}
          <template v-slot:action="{ attrs }">
              <v-icon color="#FFF" v-bind="attrs" @click="alert.show = false">mdi-close-circle</v-icon>
          </template>
        </v-snackbar>
        <v-form  lazy-validation ref="form">
          <h2 class="text-center">Sign in to your account</h2>
          <div class="text-center signup-suggest">Dont have an account? <router-link class="sign-links" to="/signup">Sign up</router-link></div>
          <label for="email" class="lbl-input mb-1">Email</label>
          <v-text-field validate-on-blur class="form-input" v-model="user.email" :rules="[...rules.requiredRules, ...rules.emailRules]" id="email" outlined></v-text-field>
          <div class="d-flex justify-space-between mb-1">
              <label class="lbl-input" for="password">Password</label>
              <router-link to="/forgot" class="sign-links">Forgot password?</router-link>
          </div>
          <v-text-field validate-on-blur v-model="user.password" @keyup.enter="signIn" class="form-input" :rules="[...rules.requiredRules]" type="password" outlined id="password"></v-text-field>
          <v-btn block :loading="logging" @click="signIn"  class="main-btn mb-6" :disabled="logging" >Log in</v-btn>       
      </v-form>
        <v-form @submit.prevent>
          <!-- <button class="social-btn social-btn-google d-flex justify-center align-center">
            <div class="btn-icon d-flex mr-2"><img src="@/assets/google-soical.svg" alt="Google"></div>
            <div class="btn-text">
              <h5>Sign In with Google</h5>
            </div>
          </button> -->
          <!-- <button class="social-btn social-btn-facebook d-flex justify-center align-center">
            <div class="btn-icon d-flex mr-2"><img src="@/assets/face-social.svg" alt="Facebook"></div>
            <div class="btn-text">
              <h5>Sign In with Facebook</h5>
            </div>
          </button>
          <button class="social-btn social-btn-apple d-flex justify-center align-center">
            <div class="btn-icon d-flex mr-2">
              <img src="@/assets/apple-social.svg" alt="Apple">
            </div>
            <div class="btn-text">
              <h5>Sign In with Apple</h5>
            </div>
          </button> -->
      </v-form>
    </div>
</template>
<script>
  export default{
    data(){
      return{
        user:{
          username: null,
          password: null,
        },
        logging: false,
        alert: {
          show: false,
          message: null,
        }
      }
    },
    computed:{
        rules(){
          return this.$store.getters['rules'];
        }
    },
    methods:{
      async signIn(){ 
        
        if(this.$refs.form.validate()){

          this.$emit('validForm', this.user);

        }

        
      }
    }

  }
</script>
<style scoped>
  .main-btn{
    min-height: 48px !important;;
    height: 48px !important;
  }
</style>
<style>
.main-alert .v-snack__wrapper{
  background-color: #FFF;
}
.signup-suggest{
    color: #485158;
    font-family: 'Inter',sans-serif;
    margin: 8px 0px 32px 0px;
}
    .signin-content h2{
        font-family: 'Inter',sans-serif;
        font-size: 28px;
        color: #212445;
        font-weight: normal;
    }
    .signin-content .form-input fieldset{
        border: 2px solid #C7D5E1;
        border-radius: 12px;

    }
    .signin-content .form-input .v-text-field__slot input{
        color: #344054 !important;
    }
    .signin-content .lbl-input{
        color: #344054;
        font-family: 'Inter',sans-serif;
        font-weight: 500;
        font-size: 14px;
        display: block;
    }
  .signin .social-btn{
  height: 48px !important;
  display: block;
  width: 100%;
  margin: 15px auto;
  padding: 10px 0px;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
}
.signin .social-btn .btn-text{
  font-family: 'Inter',sans-serif;
  font-size: 18px;
}
.signin .social-btn{
  background-color: #FFF;
  color: #344054;
  border-color: #D0D5DD !important;
}
.signin-content .sign-links{
    text-decoration: none;
    font-family: 'Inter',sans-serif;
    color: #0FD6E3;
    font-size: 15px !important;
}
@media (min-width: 1264px){

    .signin-content h2{
        font-weight: 600;
        font-size: 32px !important;
    }
}
</style>