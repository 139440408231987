<template>
    <div class="dashboard-content">
        
        <v-container> 
            <welcome-user v-if="name" :name="name"></welcome-user>
            <v-row class="d-flex justify-center">
                <v-col cols="12" class="order-2 order-lg-1 py-0" lg="8">
                    <div class="content-container account-income mt-8 mt-md-0">
                        <h2>Income Analysis</h2>
                        <analysis-chart></analysis-chart>
                        <MinersTable></MinersTable>
                    </div>
                </v-col>
                <v-col cols="12" class="order-1 order-lg-2 py-0" lg="4">
                    <BalanceAnalysis></BalanceAnalysis>
                    <UserWallet class="my-8" :wallets="wallets"></UserWallet>
                </v-col>
            </v-row>
        </v-container>
        </div>
</template>
<script>
import WelcomeUser from './WelcomeUser.vue'
import AnalysisChart from './AnalysisChart.vue';
import MinersTable from './MinersTable.vue'
import UserWallet from '@/components/Dashboard/Basics/UserWallet.vue'
import BalanceAnalysis from '@/components/Dashboard/Basics/BalanceAnalysis.vue'
export default{
        components:{WelcomeUser,AnalysisChart,MinersTable,UserWallet,BalanceAnalysis},
        data(){
            return{
                wallets: [
                    // {name: 'Bitcoin',  img: 'bitcoin.png'},
                    // {name: 'Litecoin',  img: 'litecoin.png'},
                    // {name: 'Doge',  img: 'dogecoin.png'},
                ]  
            }
        },
        computed:{
            name(){
                return this.$store.getters['user'] ? this.$store.getters['user']['first_name'] : null;
            }
        }
    }
</script>
<style>
@media (min-width: 1904px){
    .dashboard-content .container{
        max-width: 1340px !important;
    }
}
.content-container{
    position: relative;
    background-color: #1E213F;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    padding: 32px;
}
.content-container h2{
    font-weight: normal;
    font-size: 18px;
    font-family: 'Gilroy-Medium',sans-serif;
}
</style>