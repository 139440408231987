<template>
<div class="item-checkout">
    <div class="item-summary d-flex justify-space-between align-center">
        <div class="item-details d-flex align-center">
            <img width="48px" class="mr-3" :src="getImg('miner-01.jpg')" alt="Product">
            <div class="item-desc">
                <p class="item-name">{{ item.name }}</p>
                <p class="item-description">{{ item.description }}</p>
            </div>
            
        </div> 
        <!-- <div class="item-price">
            <p>${{ item.price_usd }}</p>
        </div> -->
    </div>
    <div class="items-overview">
        <div class="overview-details my-6">
            <div class="detail-item d-flex justify-space-between">
                <div class="detail-item-text">Subtotal</div>
                <div class="detail-item-value">${{ Number.parseFloat(item.price_usd) +  Number.parseInt(shipping) }}</div>
            </div>
            <div class="detail-item d-flex mt-2 justify-space-between">
                <div class="detail-item-text">Estimated Shipping</div>
                <div class="detail-item-value">${{ shipping }}</div>
            </div>
            <div class="detail-item d-flex mt-2 justify-space-between">
                <div class="detail-item-text">Tax</div>
                <div class="detail-item-value">${{ tax }}</div>
            </div>
        </div>
        
        <hr v-if="!loadCurrency">
        <v-progress-linear v-else indeterminate color="#10EBF9"></v-progress-linear>
        
        <div class="item-cost d-flex justify-space-between mt-8 mb-12">
            <div>
                <div class="item-cost-title d-flex">
                    <!-- <img src="@/assets/coins/Subtract.png" class="mr-3" alt="Coins"> -->
                    <h2>Total cost</h2>
                </div>
            </div>
            <div class="item-cost-value text-right">
                <p>${{ getItemCost }}</p>
                <div class="item-cost-currency d-flex align-center" v-if="currency">
                    <img width="30px" :src="getCoinImg(currency.listing.slug)" class="mr-3" alt="Coins">
                    <p>{{ geetItemCostCurrency }}</p>
                </div>
            </div>
        </div>
    </div>
    
    <div class="item-payment">
        <slot name="pay-btn"></slot>
        <div class="secured d-flex justify-center mt-3">
            <div class="secured-icon mr-3">
                <img src="@/assets/sheild.png" class="d-inline" alt="Sheild">
            </div>
            <p>Payment secured by Coinbase</p>
        </div>
    </div>

    </div>
</template>
<script>
    export default{
        emits:['payItem'],
        
        props:{
            item: {
                type: Object,
                required: true,
                default(){
                    return {
                        name: 'Bitcoin',
                        img: 'btclogso.png'
                    }
                },
            },
            loadCurrency:{
                type: Boolean,
                required: true,
                default: false,
            },
            currency:{
                type: Object,
                required: false,
                
            }
        },
        data(){
            return{
                tax: 0,
                shipping:0
            }
        },
        computed:{
            getItemCost(){
                return ( Number.parseFloat(this.item.price_usd) +  Number.parseInt(this.shipping) + Number.parseInt(this.tax));
            },
            geetItemCostCurrency(){
                return Number.parseFloat(this.getItemCost / this.currency.listing.price_usd).toFixed(5); 
            }
        },
        methods:{
            getImg(img){
                return require(`@/assets/products/${img}`)
            },
            getCoinImg(img){
                return require(`@/assets/coins/${img}.png`);
            },
            payItem(){
                this.$emit('payItem')
            },

        }

    }
</script>
<style scoped>
.item-checkout .item-desc .item-description{
    max-width: 260px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

</style>
<style>
.item-payment .main-btn{
    font-size: 16px !important;
}
.item-summary{
    background-color: #2B2E54;
    padding: 16px;
    border-radius: 12px;
}
.detail-item .detail-item-value{
    font-family: 'Quicksand', sans-serif;
}
.item-cost-title h2{ 
    font-family: 'Gilroy-medium', sans-serif;
    font-size: 24px !important;
    font-weight: normal;
}
.item-cost-value p{
    font-family: 'Gilroy-medium', sans-serif;
    font-size: 28px;
}
/* .item-summary button{
    letter-spacing: 0px !important;
    text-transform: none !important;
    font-family: 'Inter',sans-serif !important;
} */

</style>